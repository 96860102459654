export const updateSheetmethodput = (id, url, setIsSignedIn, setdowp, datamodels) =>{
  const headers = [
    "Numero economico",
    "Año",
    "Modelo",
  ];

  const values = [headers];
  const range = "Sheet";
  const spreadsheetId = id;
  window.gapi.client.sheets.spreadsheets.values.update({
    spreadsheetId: spreadsheetId,
    range: range,
    valueInputOption: "USER_ENTERED",
    resource: {
      values: values
    }
  }).then((response) => {
    console.log('Datos actualizados', response.result);
    addDropdownValidationPut(spreadsheetId, url, setIsSignedIn, setdowp, datamodels);
  }).catch((error) => {
    alert("No se logro actualizar la hoja de calculo comuniquese con soporte")
    console.error('Error al actualizar la hoja de cálculo', error);
    setdowp(false);
  });
}

const addDropdownValidationPut = (spreadsheetId, url, setIsSignedIn, setdowp, datamodels) =>{
    const modelsArray = datamodels.map(item =>item.name_model);
    const requests = [
      {
        repeatCell: {
          range: {
            sheetId: 0, // Primera hoja
            startRowIndex: 1, // Desde la fila 2
            endRowIndex: 100, // Hasta la fila 100
            startColumnIndex: 2, // Columna G (índice base 0)
            endColumnIndex: 3, // Columna G (no inclusivo)
          },
          cell: {
            dataValidation: {
              condition: {
                type: "ONE_OF_LIST",
                values: modelsArray.map(model => ({ userEnteredValue: model })), // Opciones para la columna G
              },
              strict: true,
              showCustomUi: true,
            },
          },
          fields: "dataValidation",
        },
      },
    ];
    window.gapi.client.sheets.spreadsheets.batchUpdate({
      spreadsheetId: spreadsheetId,
      resource: {
        requests: requests,
      },
    })
      .then(() => {
        window.open(url, "_blank");
        window.gapi.auth2.getAuthInstance().signOut().then(() => {
          setIsSignedIn(false);
         console.log("Sesión cerrada con éxito");
        });
      })
      .catch((error) => {
        alert("No se logró configurar el menú desplegable. Comuníquese con soporte.");
        console.error("Error al configurar el menú desplegable", error);
        setdowp(false);
      });
}

export const updateSheet = (id, url, databrand, datamodels, setIsSignedIn, setdowp) => {
    const headers = [
      "Numero economico",
      "Numero activo fijo",
      "Numero serie",
      "Comodato",
      "Año",
      "Marca",
      "Modelo",
      "Nota",
    ];
    
    const values = [headers];
    const range = "Sheet";
    const spreadsheetId = id;

    window.gapi.client.sheets.spreadsheets.values.update({
      spreadsheetId: spreadsheetId,
      range: range,
      valueInputOption: "USER_ENTERED",
      resource: {
        values: values
      }
    }).then((response) => {
      console.log('Datos actualizados', response.result);
      addDropdownValidation(spreadsheetId, url, databrand, datamodels, setIsSignedIn, setdowp);
    }).catch((error) => {
      alert("No se logro actualizar la hoja de calculo comuniquese con soporte")
      console.error('Error al actualizar la hoja de cálculo', error);
      setdowp(false);
    });
};

const addDropdownValidation = (spreadsheetId, url, databrand, datamodels, setIsSignedIn, setdowp) => {  
    const namesArray = databrand.map(item => item.name);
    const modelsArray = datamodels.map(item =>item.name_model);
    const requests = [
      {
        repeatCell: {
          range: {
            sheetId: 0, // Primera hoja
            startRowIndex: 1, // Desde la fila 2
            endRowIndex: 100, // Hasta la fila 100
            startColumnIndex: 3, // Columna F (índice base 0)
            endColumnIndex: 4, // Columna F (no inclusivo)
          },
          cell: {
            dataValidation: {
              condition: {
                type: "ONE_OF_LIST",
                values: ["SI", "NO"].map(name => ({ userEnteredValue: name })),
              },
              strict: true,
              showCustomUi: true,
            },
          },
          fields: "dataValidation",
        },
      },
      {
        repeatCell: {
          range: {
            sheetId: 0, // Primera hoja
            startRowIndex: 1, // Desde la fila 2
            endRowIndex: 100, // Hasta la fila 100
            startColumnIndex: 5, // Columna F (índice base 0)
            endColumnIndex: 6, // Columna F (no inclusivo)
          },
          cell: {
            dataValidation: {
              condition: {
                type: "ONE_OF_LIST",
                values: namesArray.map(name => ({ userEnteredValue: name })), // Opciones para la columna F
              },
              strict: true,
              showCustomUi: true,
            },
          },
          fields: "dataValidation",
        },
      },
      {
        repeatCell: {
          range: {
            sheetId: 0, // Primera hoja
            startRowIndex: 1, // Desde la fila 2
            endRowIndex: 100, // Hasta la fila 100
            startColumnIndex: 6, // Columna G (índice base 0)
            endColumnIndex: 7, // Columna G (no inclusivo)
          },
          cell: {
            dataValidation: {
              condition: {
                type: "ONE_OF_LIST",
                values: modelsArray.map(model => ({ userEnteredValue: model })), // Opciones para la columna G
              },
              strict: true,
              showCustomUi: true,
            },
          },
          fields: "dataValidation",
        },
      },
    ];


    window.gapi.client.sheets.spreadsheets.batchUpdate({
      spreadsheetId: spreadsheetId,
      resource: {
        requests: requests,
      },
    })
      .then((response) => {
        console.log("Menú desplegable configurado", response.result);
        window.open(url, "_blank");
        window.gapi.auth2.getAuthInstance().signOut().then(() => {
          setIsSignedIn(false);
          console.log("Sesión cerrada con éxito");
        });
      })
      .catch((error) => {
        alert("No se logró configurar el menú desplegable. Comuníquese con soporte.");
        console.error("Error al configurar el menú desplegable", error);
        setdowp(false);
      });
};
  