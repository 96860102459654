import { Confirm, CustomDialog } from "react-st-modal";
import { ListItemIcon, MenuItem } from '@mui/material';
import Edit from "../../../imgComponents/save.png";
import Delete from "../../../imgComponents/borrar.png";
import { refresh_token } from "../../../main_components/tokens/tokenrefresh";
import OPERATIONES_ROUTES from "./op_routes";
import { delete_route } from "../../../services/configuration/configuration";

const RENDER_ROW_ACTION_MENU_ITEMS = (props) => {

    const DeleteSector = async () => {
        props.setmodalT(true);
        props.setreconsult(true);
        try {
            var rf_token = await refresh_token();
            if (rf_token === true) {
                setTimeout(async () => {
                    try {
                        const dt = await delete_route(props?.row?.original?.id);
                        console.log(dt);
                        props.alertas("Ruta Eliminada", true);
                        props.setmodalT(false);
                        props.setreconsult(false);
                        props.handleSubmit();
                    } catch (err) {
                        console.log("error", err);
                        props.setmodalT(false);
                        props.setreconsult(false);
                        props.alertas("Error, no se pudo eliminar la ruta", false);
                    }
                }, 1000);
            } else { }
        } catch (err) {
            console.log(err);
        }
    }


    return (
        <div>
            <MenuItem
                key={1}
                onClick={async () => {
                    props.closeMenu();
                    const result = await Confirm('¿Esta usted seguro de eliminar la ruta?', 'Eliminar Ruta', 'Si', 'No');
                    if (result) {
                        DeleteSector()
                    }
                }
                }
                sx={{ m: 0 }}
            >
                <ListItemIcon>
                    <img
                        alt=""
                        src={Delete}
                        title="Eliminar Ruta"
                        className=""
                    ></img>
                </ListItemIcon>
                Eliminar Ruta
            </MenuItem>
            <MenuItem
                key={2}
                onClick={async () => {
                    props.closeMenu();
                    await CustomDialog(
                        <OPERATIONES_ROUTES
                            operation={"EDIT"}
                            row={props?.row}
                            handleSubmit={props?.handleSubmit}
                            alertas={props?.alertas}
                            setmodalT={props.setmodalT}
                        />,
                        {
                            className: "custom-modal-3",
                            title: "EDITAR RUTA",
                            showCloseIcon: true,
                        }
                    );
                }
                }
                sx={{ m: 0 }}
            >
                <ListItemIcon>
                    <img
                        alt=""
                        src={Edit}
                        title="Editar Ruta"
                        className=""
                    ></img>
                </ListItemIcon>
                Editar Ruta
            </MenuItem>
        </div >
    );
};

export default RENDER_ROW_ACTION_MENU_ITEMS;
