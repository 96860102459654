import { useEffect, useState } from "react";
import { Autocomplete, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useDialog } from "react-st-modal";
import { refresh_token } from "../../../main_components/tokens/tokenrefresh";
import { catchErrorModul } from "../../../main_components/catchErr/catchError";
import { get_route_users, get_route_warehouse, get_types_of_sales, post_route, put_route } from "../../../services/configuration/configuration";
import { get_employess } from "../../../services/humanResources/humanResources";

function OPERATIONES_ROUTES(props) {

  const dialog = useDialog();

  const [route, setroute] = useState(props.operation === "EDIT" ? props.row.original.route : null);
  const [desroute, setdesroute] = useState(props.operation === "EDIT" ? props.row.original.description : null);
  const [coderoute, setcoderoute] = useState(props.operation === "EDIT" ? props.row.original.code : null);
  const [firstuser, setfirstuser] = useState(props.operation === "EDIT" ? props.row.original.id_user_assigned_fk : null);
  const [seconduser, setseconduser] = useState(props.operation === "EDIT" ? props.row.original.id_second_user_assigned_fk : null);
  const [firstw, setfirstw] = useState(props.operation === "EDIT" ? props.row.original.id_warehouse_fk : null);
  const [secondw, setsecondw] = useState(props.operation === "EDIT" ? props.row.original.id_second_warehouse_fk : null);
  const [typesale, settypesale] = useState(props.operation === "EDIT" ? props.row.original.id_sale_type_fk : null);
  const [days, setdays] = useState(props.operation === "EDIT" ? props.row.original.days_for_delivery : 1);
  const [codeRouteDMS, setcodeRouteDMS] = useState(props.operation === "EDIT" ? props.row.original.code_dms : null);
  const [frec, setfrec] = useState(props.operation === "EDIT" ? props.row.original.frequently_update : true);
  const [sendms, setsendms] = useState(props.operation === "EDIT" ? props.row.original.send_to_dms : false);
  const [id_employee_assigned, setid_employee_assigned] = useState(props.operation === "EDIT" ? props.row.original.id_employee_assigned_fk : null);
  const [id_second_employee_assigned, setid_second_employee_assigned] = useState(props.operation === "EDIT" ? props.row.original.id_second_employee_assigned_fk : null);
  const [vroute, setvroute] = useState(false);
  const [vdesroute, setvdesroute] = useState(false);
  const [vcoderoute, setvcoderoute] = useState(false);
  const [vfuser, setvfuser] = useState(false);
  const [vsuser, setvsuser] = useState(false);
  const [vfw, setvfw] = useState(false);
  const [vsw, setvsw] = useState(false);
  const [vtype, setvtype] = useState(false);
  const [vdays, setvdays] = useState(false);
  const [vdms, setvdms] = useState(false);
  const [vemploy1, setvemploy1] = useState(false);
  const [vemploy2, setvemploy2] = useState(false);

  const [bandera, setbandera] = useState(true);
  const [userEdit, setuserEdit] = useState(false);



  const [datawarehouse, setdatawarehouse] = useState([]);
  const [datatypesale, setdatatypesale] = useState([]);
  const [datausers, setdatausers] = useState([]);
  const [dataemployes, setdataemployes] = useState([]);

  const options = [
    { label: "SI", value: true },
    { label: "NO", value: false }
  ];
  useEffect(() => {
    consultroute();
    consulttypesale();
    consultusers();
    consultempleados();
  }, []);



  const consultusers = async () => {
    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        setTimeout(async () => {
          try {
            var d = await get_route_users();
            setdatausers(d === null ? [] : d.data.sales_employess);
          } catch (err) {
            console.log(err);
          }
        }, 1000);
      } else { }
    } catch (error) {
      console.log(error);
    }
  }
  const consulttypesale = async () => {
    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        setTimeout(async () => {
          try {
            var d = await get_types_of_sales();
            setdatatypesale(d === null ? [] : d.data.sale_types);
          } catch (err) {
            console.log(err);
          }
        }, 1000);
      } else { }
    } catch (error) {
      console.log(error);
    }
  }
  const consultroute = async () => {
    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        setTimeout(async () => {
          try {
            var d = await get_route_warehouse();
            setdatawarehouse(d === null ? [] : d.data.warehouses);
          } catch (err) {
            console.log(err);
          }
        }, 1000);
      } else { }
    } catch (error) {
      console.log(error);
    }
  }
  const consultempleados = async () => {
    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        setTimeout(async () => {
          try {
            var d = await get_employess();
            setdataemployes(d === null ? [] : d.data);
          } catch (err) {
            console.log(err);
          }
        }, 1000);
      } else { }
    } catch (error) {
      console.log(error);
    }
  }


  //crear nueva ruta
  const createnewRuta = async () => {

    route == null ? setvroute(true) : setvroute(false);
    desroute == null ? setvdesroute(true) : setvdesroute(false);
    coderoute == null ? setvcoderoute(true) : setvcoderoute(false);
    firstuser == null ? setvfuser(true) : setvfuser(false);
    firstw == null ? setvfw(true) : setvfw(false);
    typesale == null ? setvtype(true) : setvtype(false);
    days == null ? setvdays(true) : setvdays(false);
    codeRouteDMS == null ? setvdms(true) : setvdms(false);
    id_employee_assigned === null ? setvemploy1(true) : setvemploy1(false);

    if (typesale === "" || typesale === null) {
      props?.alertas("No ha proporcionado el tipo de Venta", false);
      return false;
    }

    if (typesale === 2) {
      if (route == "" || desroute == "" || coderoute == "" || firstuser == null || id_employee_assigned == null ||
        firstw == null || typesale == null || days == "" || codeRouteDMS == "" || frec == null || sendms == null
      ) {
        props?.alertas("Cambos faltantes", false);
        return false;
      }
    } else {
      secondw == null ? setvsw(true) : setvsw(false);
      seconduser == null ? setvsuser(true) : setvsuser(false);
      id_second_employee_assigned === null ? setvemploy2(true) : setvemploy2(false);

      if (route == null || desroute == null || coderoute == null || firstuser == null || seconduser == null
        || firstw == null || secondw == null || typesale == null || days == null || codeRouteDMS == null
        || id_employee_assigned == null || id_second_employee_assigned == null
      ) {
        props.alertas("Parametros faltantes", false);
        return false;
      }
    }

    if (typesale === 2) {
      console.log("No aplica");
    } else {

      if (firstuser === seconduser) {
        props.alertas("No se puede tener el mismo usuario asignado en prevendedor y repartidor", false);
        setvfuser(true);
        setvsuser(true);
        return null;
      }
      if (firstw === secondw) {
        props.alertas("No se puede tener el mismo almacén para la Ruta y Repartidor ", false)
        setvfw(true);
        setvsw(true);
        return null;
      }
      if (id_employee_assigned === id_second_employee_assigned) {
        props.alertas("No se puede tener el mismo empleado asignado en prevendedor y repartidor", false)
        setvemploy1(true);
        setvemploy2(true);
        return null;
      }
    }


    props.setmodalT(true);
    setbandera(false);
    var data = {
      "route": route, //String = Número de ruta
      "id_warehouse": firstw,//int = ID del primer almacén asignado
      "id_sale_type": typesale,//int = ID del tipo de venta
      "description": desroute,//String = Descripción breve de la ruta
      "code": coderoute,//Sting = Código de la ruta
      "send_to_dms": sendms === true ? true : (sendms === "true" ? true : false),//Boolean | NULL  = Enviar a DMS
      "id_user_assigned": firstuser,//int = ID del primer usuario registrado
      "id_second_user_assigned": seconduser,//int = ID del segundo usuario registrado
      "id_second_warehouse": secondw, //int = ID del segundo almacén asignado
      "days_for_delivery": days, //int = Días para entregar
      "code_dms": codeRouteDMS,//String = Código DMS
      "frequently_update": frec === false ? false : (frec === "false" ? false : true),//Boolean = Actualización frecuente
      "id_employee_assigned": id_employee_assigned,
      "id_second_employee_assigned": id_second_employee_assigned,
    }
    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        setTimeout(async () => {
          try {
            const dt = await post_route(data);
            console.log(dt);
            props.alertas("Ruta registrada con éxito", true);
            props.setmodalT(false);
            dialog.close();
            props.handleSubmit();
          } catch (err) {
            console.log(err);
            catchErrorModul("Rutas", JSON.stringify(data), JSON.stringify(err?.response === undefined ? "Token expirado" : err?.response));
            props.alertas("No se pudo registrar la ruta", false);
            props.setmodalT(false);
            setbandera(true);
          }
        }, 1000);
      } else { }
    } catch (err) {
      console.log(err);
    };
  }



  var data_test_update = {
    "route": route, //String = Número de ruta
    "id_warehouse": firstw,//int = ID del primer almacén asignado
    "id_sale_type": typesale,//int = ID del tipo de venta
    "description": desroute,//String = Descripción breve de la ruta
    "code": coderoute,//Sting = Código de la ruta
    "send_to_dms": sendms === true ? true : (sendms === "true" ? true : false),//Boolean | NULL  = Enviar a DMS
    "id_user_assigned": firstuser,//int = ID del primer usuario registrado
    "id_second_user_assigned": seconduser,//int = ID del segundo usuario registrado
    "id_second_warehouse": secondw, //int = ID del segundo almacén asignado
    "days_for_delivery": days, //int = Días para entregar
    "code_dms": codeRouteDMS,//String = Código DMS
    "frequently_update": frec === false ? false : (frec === "false" ? false : true),//Boolean = Actualización frecuente
    "id_employee_assigned": id_employee_assigned,
    "id_second_employee_assigned": id_second_employee_assigned,
  }

  console.log(data_test_update)



  //actualizar una ruta
  const updateRuta = async () => {

    route == null ? setvroute(true) : setvroute(false);
    desroute == null ? setvdesroute(true) : setvdesroute(false);
    coderoute == null ? setvcoderoute(true) : setvcoderoute(false);
    firstuser == null ? setvfuser(true) : setvfuser(false);
    firstw == null ? setvfw(true) : setvfw(false);
    typesale == null ? setvtype(true) : setvtype(false);
    days == null ? setvdays(true) : setvdays(false);
    codeRouteDMS == null ? setvdms(true) : setvdms(false);
    id_employee_assigned === null ? setvemploy1(true) : setvemploy1(false);

    if (typesale === "" || typesale === null) {
      props?.alertas("No ha proporcionado el tipo de Venta", false);
      return false;
    }

    if (typesale === 2) {
      if (route == "" || desroute == "" || coderoute == "" || firstuser == null || id_employee_assigned == null ||
        firstw == null || typesale == null || days == "" || codeRouteDMS == "" || frec == null || sendms == null
      ) {
        props?.alertas("Cambos faltantes", false);
        return false;
      }
    } else {
      secondw == null ? setvsw(true) : setvsw(false);
      seconduser == null ? setvsuser(true) : setvsuser(false);
      id_second_employee_assigned === null ? setvemploy2(true) : setvemploy2(false);

      if (route == null || desroute == null || coderoute == null || firstuser == null || seconduser == null
        || firstw == null || secondw == null || typesale == null || days == null || codeRouteDMS == null
        || id_employee_assigned == null || id_second_employee_assigned == null
      ) {
        props.alertas("Parametros faltantes", false);
        return false;
      }
    }

    if (typesale === 2) {
      console.log("No aplica");
    } else {

      if (firstuser === seconduser) {
        props.alertas("No se puede tener el mismo usuario asignado en prevendedor y repartidor", false);
        setvfuser(true);
        setvsuser(true);
        return null;
      }
      if (firstw === secondw) {
        props.alertas("No se puede tener el mismo almacén para la Ruta y Repartidor ", false)
        setvfw(true);
        setvsw(true);
        return null;
      }
      if (id_employee_assigned === id_second_employee_assigned) {
        props.alertas("No se puede tener el mismo empleado asignado en prevendedor y repartidor", false)
        setvemploy1(true);
        setvemploy2(true);
        return null;
      }
    }

    setvfuser(false);
    setvsuser(false);
    setvfw(false);
    setvsw(false);

    props.setmodalT(true);
    setbandera(false);
    var data = {
      "route": route, //String = Número de ruta
      "id_warehouse": firstw,//int = ID del primer almacén asignado
      "id_sale_type": typesale,//int = ID del tipo de venta
      "description": desroute,//String = Descripción breve de la ruta
      "code": coderoute,//Sting = Código de la ruta
      "send_to_dms": sendms === true ? true : (sendms === "true" ? true : false),//Boolean | NULL  = Enviar a DMS
      "id_user_assigned": firstuser,//int = ID del primer usuario registrado
      "id_second_user_assigned": seconduser,//int = ID del segundo usuario registrado
      "id_second_warehouse": secondw, //int = ID del segundo almacén asignado
      "days_for_delivery": days, //int = Días para entregar
      "code_dms": codeRouteDMS,//String = Código DMS
      "frequently_update": frec === false ? false : (frec === "false" ? false : true),//Boolean = Actualización frecuente
      "id_employee_assigned": id_employee_assigned,
      "id_second_employee_assigned": id_second_employee_assigned,
    }

    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        setTimeout(async () => {
          try {
            const dt = await put_route(props.row.original.id, data);
            console.log(dt);
            props.alertas("Ruta actualizada con éxito", true);
            props.alertas("Recuerda que al cambiar la ruta de un usuario, es necesario actualizar sus permisos.", "warning");
            props.setmodalT(false);
            dialog.close();
            props.handleSubmit();
          } catch (err) {
            console.log(err);
            catchErrorModul("Rutas", JSON.stringify(data), JSON.stringify(err?.response === undefined ? "Token expirado" : err?.response));
            console.log("error post", err);
            props.alertas("No se pudo actualizar la ruta", false);
            props.setmodalT(false);
            setbandera(true);
          }
        }, 1000);
      } else { }
    } catch (err) {
      console.log(err);
    };
  }


  function Spinner() {
    return (
      <div className="spinner-border spinner-border-sm text-light" role="status">
        <span className="sr-only">Cargando...</span>
      </div>
    );
  }

  useEffect(() => {
    if (typesale === 2) {
      setvsw(false);
      setvsuser(false);
      setvemploy2(false);
    }
  }, [typesale])

  return (
    <div>
      <Box sx={{ display: 'flex', gap: '5px', p: '2px', zIndex: "10", alignItems: 'flex-start', margin: '0 auto', width: '95%', marginTop: '10px' }}>
        <TextField
          label="Ruta"
          required
          type="number"
          value={route}
          disabled={!bandera}
          inputProps={{ min: 0 }}
          sx={{ width: "20%" }}
          style={
            vroute === true ? { "border": "2px solid red", "borderRadius": "5px" } : {}
          }
          onSelect={() => setvroute(false)}
          onBlur={
            route == null ? () => setvroute(true) : () => setvroute(false)
          }
          onChange={(event) => {
            const value = event.target.value;
            if (value === "" || /^[0-9]*$/.test(value)) {
              setroute(value);
            }
          }
          }
          InputLabelProps={{ shrink: true }}
        ></TextField>

        <TextField
          label="Descripción de la Ruta ('Area de Influencia)"
          value={desroute}
          required
          disabled={!bandera}
          sx={{ width: "60%" }}
          style={
            vdesroute === true ? { "border": "2px solid red", "borderRadius": "5px" } : {}
          }
          onSelect={() => setvdesroute(false)}
          onBlur={
            desroute == null ? () => setvdesroute(true) : () => setvdesroute(false)
          }
          onChange={(event) => setdesroute(event.target.value)}
          InputLabelProps={{ shrink: true }}
        ></TextField>

        <TextField
          label="Código de la Ruta"
          required
          value={coderoute}
          disabled={!bandera}
          sx={{ width: "20%" }}
          style={
            vcoderoute === true ? { "border": "2px solid red", "borderRadius": "5px" } : {}
          }
          onSelect={() => setvcoderoute(false)}
          onBlur={
            coderoute == null ? () => setvcoderoute(true) : () => setvcoderoute(false)
          }
          onChange={(event) => setcoderoute(event.target.value.toUpperCase())}
          InputLabelProps={{ shrink: true }}
        ></TextField>

      </Box>


      <Box sx={{ display: 'flex', gap: '5px', p: '2px', zIndex: "10", alignItems: 'flex-start', margin: '0 auto', width: '95%', marginTop: '10px', }}>
        <FormControlLabel
          style={{ width: "20%" }}
          control={
            <Checkbox
              checked={userEdit}
              onChange={(e) => {
                setuserEdit(e.target.checked);
              }}
            />
          }
          label="Fozar Selección de Usuario"
          labelPlacement="bottom"
        />
        <Autocomplete
          id="user-autocomplete"
          options={datausers}
          getOptionLabel={(option) => option.username}
          style={{ width: "40%" }}
          disabled={!userEdit}
          value={datausers.find(user => user.id === firstuser) || null}
          onChange={(event, newValue) => {
            setfirstuser(newValue ? newValue.id : null);
            setvfuser(false);
          }}
          onBlur={() => {
            if (firstuser == null) setvfuser(true);
          }}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Usuario Asignado (Vendedor o Prevendedor)"
              required
              InputLabelProps={{ shrink: true }}
              size="small"
              style={vfuser ? { border: "2px solid red", borderRadius: "5px" } : {}}
            />
          )}
        />

        <Autocomplete
          id="second-user-autocomplete"
          options={datausers}
          disabled={!userEdit}
          getOptionLabel={(option) => option.username}
          value={datausers.find(user => user.id === seconduser) || null}
          style={{ width: "40%" }}
          onChange={(event, newValue) => {
            setseconduser(newValue ? newValue.id : null);
            setvsuser(false);
          }}
          onBlur={() => {
            if (typesale === 2) {

            } else {
              if (seconduser == null) setvsuser(true);
            }
          }}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Segundo Usuario Asignado (Repartidor)"
              required
              InputLabelProps={{ shrink: true }}
              size="small"
              style={vsuser ? { border: "2px solid red", borderRadius: "5px" } : {}}
            />
          )}
        />
      </Box>


      {/* <Box sx={{ display: 'flex', gap: '5px', p: '2px', zIndex: "10", alignItems: 'flex-start', margin: '0 auto', width: '95%', marginTop: '10px', }}>


        <TextField
          select
          required
          value={firstuser}
          label="Usuario Asignado (Vendedor o Prevendedor)"
          disabled={!bandera}
          InputLabelProps={{ shrink: true, }}
          SelectProps={{ native: true, }}
          size="small"
          sx={{ width: "50%", }}
          onChange={(event) => setfirstuser(event.target.value)}
          style={
            vfuser === true ? { "border": "2px solid red", "borderRadius": "5px" } : {}
          }
          onSelect={() => setvfuser(false)}
          onBlur={
            firstuser == null ? () => setvfuser(true) : () => setvfuser(false)
          }
        >
          <option key={0} value={0} selected >
            {"Seleccione Usuario"}
          </option>
          {
            datausers.map((item) => (
              <option key={item.id} value={item.id}>
                {item.username}
              </option>
            ))
          }
        </TextField>


        <TextField
          select
          required
          value={seconduser}
          label="Segundo Usuario Asignado (Repartidor)"
          disabled={!bandera}
          InputLabelProps={{ shrink: true, }}
          SelectProps={{ native: true, }}
          size="small"
          sx={{ width: "50%", }}
          onChange={(event) => setseconduser(event.target.value)}
          style={
            vsuser === true ? { "border": "2px solid red", "borderRadius": "5px" } : {}
          }
          onSelect={() => setvsuser(false)}
          onBlur={
            seconduser == null ? () => setvsuser(true) : () => setvsuser(false)
          }
        >
          <option key={0} value={0} selected >
            {"Seleccione Usuario"}
          </option>
          {
            datausers.map((item) => (
              <option key={item.id} value={item.id}>
                {item.username}
              </option>
            ))
          }
        </TextField>

      </Box> */}

      {/* 
      <Box sx={{ display: 'flex', gap: '5px', p: '2px', zIndex: "10", alignItems: 'flex-start', margin: '0 auto', width: '95%', marginTop: '10px', }}>
        <TextField
          select
          required
          label="Empleado Asignado (Vendedor o Prevendedor)"
          disabled={!bandera}
          value={id_employee_assigned}
          InputLabelProps={{ shrink: true, }}
          size="small"
          SelectProps={{ native: true, }}
          sx={{ width: "50%" }}
          onChange={(event) => setid_employee_assigned(event.target.value)}
          style={
            vemploy1 === true ? { "border": "2px solid red", "borderRadius": "5px" } : {}
          }
          onSelect={() => setvemploy1(false)}
          onBlur={
            id_employee_assigned == null ? () => setvemploy1(true) : () => setvemploy1(false)
          }

        >
          <option key={0} value={0} selected >
            {"Seleccione Empleado"}
          </option>
          {
            dataemployes.map((item) => (
              <option key={item.id} value={item.id}>
                {item.name + " " + item.first_last_name + " " + item.second_last_name}
              </option>
            ))
          }

        </TextField>
        <TextField
          select
          required
          value={id_second_employee_assigned}
          label="Segundo Empleado Asignado (Repartidor)"
          disabled={!bandera}
          InputLabelProps={{ shrink: true, }}
          SelectProps={{ native: true, }}
          size="small"
          sx={{ width: "50%" }}
          onChange={(event) => setid_second_employee_assigned(event.target.value)}
          style={
            vemploy2 === true ? { "border": "2px solid red", "borderRadius": "5px" } : {}
          }
          onSelect={() => setvemploy2(false)}
          onBlur={
            id_second_employee_assigned == null ? () => setvemploy2(true) : () => setvemploy2(false)
          }
        >
          <option key={0} value={0} selected >
            {"Seleccione Empleado"}
          </option>
          {
            dataemployes.map((item) => (
              <option key={item.id} value={item.id}>
                {item.name + " " + item.first_last_name + " " + item.second_last_name}
              </option>
            ))
          }
        </TextField>
      </Box> */}

      <Box sx={{ display: 'flex', gap: '5px', p: '2px', zIndex: "10", alignItems: 'flex-start', margin: '0 auto', width: '95%', marginTop: '10px', }}>

        <Autocomplete
          id="assigned-employee-autocomplete"
          options={dataemployes}
          getOptionLabel={(option) => `${option.name} ${option.first_last_name} ${option.second_last_name}`}
          value={dataemployes.find(emp => emp.id === id_employee_assigned) || null}
          onChange={(event, newValue) => {
            setid_employee_assigned(newValue ? newValue.id : null);
            setvemploy1(false);
            setfirstuser(newValue ? newValue.user_id : null);
            if (newValue.user_id === null) {
              props.alertas("Advertencia: El empleado seleccionado no está vinculado a un usuario de OD2. Para corregir esto, ve al módulo de empleados y asegúrate de que el campo 'Correo electrónico de la empresa' coincida con el del usuario de OD2 al que deseas asociarlo.Después de realizar el cambio, recarga el módulo de configuración para actualizar la información.", "warning");
            }

          }}
          style={{ width: "100%" }}
          onBlur={() => {
            if (id_employee_assigned == null) setvemploy1(true);
          }}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          disabled={!bandera}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Empleado Asignado (Vendedor o Prevendedor)"
              required
              InputLabelProps={{ shrink: true }}
              size="small"
              style={vemploy1 ? { border: "2px solid red", borderRadius: "5px" } : {}}
            />
          )}
        />


        <Autocomplete
          id="employee-autocomplete"
          options={dataemployes}
          getOptionLabel={(option) => `${option.name} ${option.first_last_name} ${option.second_last_name}`}
          value={dataemployes.find(emp => emp.id === id_second_employee_assigned) || null}
          onChange={(event, newValue) => {
            setid_second_employee_assigned(newValue ? newValue.id : null);
            setseconduser(newValue ? newValue.user_id : null);
            setvemploy2(false);
            if (newValue.user_id === null) {
              props.alertas("Advertencia: El empleado seleccionado no está vinculado a un usuario de OD2. Para corregir esto, ve al módulo de empleados y asegúrate de que el campo 'Correo electrónico de la empresa' coincida con el del usuario de OD2 al que deseas asociarlo.Después de realizar el cambio, recarga el módulo de configuración para actualizar la información.", "warning");
            }
          }}
          style={{ width: "100%" }}
          onBlur={() => {
            if (typesale === 2) {
            } else {
              if (id_second_employee_assigned == null) setvemploy2(true);
            }
          }}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          disabled={!bandera}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Segundo Empleado Asignado (Repartidor)"
              required
              InputLabelProps={{ shrink: true }}
              size="small"
              style={vemploy2 ? { border: "2px solid red", borderRadius: "5px" } : {}}
            />
          )}
        />

      </Box>
      <Box sx={{ display: 'flex', gap: '5px', p: '2px', zIndex: "10", alignItems: 'flex-start', margin: '0 auto', width: '95%', marginTop: '10px', }}>
        <Autocomplete
          id="first-warehouse-autocomplete"
          options={datawarehouse}
          getOptionLabel={(option) => option.warehouse_name}
          value={datawarehouse.find(wh => wh.id === firstw) || null}
          onChange={(event, newValue) => {
            setfirstw(newValue ? newValue.id : null);
            setvfw(false);
          }}
          style={{ width: "100%" }}
          onBlur={() => {
            if (firstw == null) setvfw(true);
          }}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          disabled={!bandera}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Almacén Asignado (El Almacén de la Ruta o Cámara)"
              required
              InputLabelProps={{ shrink: true }}
              size="small"
              style={vfw ? { border: "2px solid red", borderRadius: "5px" } : {}}
            />
          )}
        />

        <Autocomplete
          id="second-warehouse-autocomplete"
          options={datawarehouse}
          getOptionLabel={(option) => option.warehouse_name}
          value={datawarehouse.find(wh => wh.id === secondw) || null}
          onChange={(event, newValue) => {
            setsecondw(newValue ? newValue.id : null);
            setvsw(false);
          }}
          style={{ width: "100%" }}
          onBlur={() => {
            if (typesale === 2) {
            } else {
              if (secondw == null) setvsw(true);
            }
          }}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          disabled={!bandera}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Segundo Almacén Asignado (El Almacén del Repartidor)"
              required
              InputLabelProps={{ shrink: true }}
              size="small"
              style={vsw ? { border: "2px solid red", borderRadius: "5px" } : {}}
            />
          )}
        />
        <Autocomplete
          id="sale-type-autocomplete"
          options={datatypesale}
          getOptionLabel={(option) => option.type}
          value={datatypesale.find(type => type.id === typesale) || null}
          onChange={(event, newValue) => {
            settypesale(newValue ? newValue.id : null);
            setvtype(false);
          }}
          style={{ width: "100%" }}
          onBlur={() => {
            if (typesale == null) setvtype(true);
          }}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          disabled={!bandera}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Tipo de Venta"
              required
              InputLabelProps={{ shrink: true }}
              size="small"
              style={vtype ? { border: "2px solid red", borderRadius: "5px" } : {}}
            />
          )}
        />

      </Box>

      <Box sx={{ display: 'flex', gap: '5px', p: '2px', zIndex: "10", alignItems: 'flex-start', margin: '0 auto', width: '95%', marginTop: '10px', }}>
        <TextField
          label="Días para la Entrega"
          required
          value={days}
          type={"number"}
          disabled={!bandera}
          sx={{ width: "50%" }}
          inputProps={{ min: 1 }}
          onChange={(event) => {
            const value = event.target.value;
            if (/^[1-9][0-9]{0,2}$/.test(value) || value === "") {
              setdays(value);
            }
          }} InputLabelProps={{ shrink: true }}
          style={
            vdays === true ? { "border": "2px solid red", "borderRadius": "5px" } : {}
          }
          onSelect={() => setvdays(false)}
          onBlur={
            days == null ? () => setvdays(true) : () => setvdays(false)
          }
        ></TextField>


        <TextField
          label="Código de Ruta para DMS"
          required
          value={codeRouteDMS}
          disabled={!bandera}
          sx={{ width: "50%" }}
          onChange={(event) => setcodeRouteDMS(event.target.value.toUpperCase())}
          InputLabelProps={{ shrink: true }}
          style={
            vdms === true ? { "border": "2px solid red", "borderRadius": "5px" } : {}
          }
          onSelect={() => setvdms(false)}
          onBlur={
            codeRouteDMS == null ? () => setvdms(true) : () => setvdms(false)
          }
        ></TextField>
      </Box>

      {/* <Box sx={{ display: 'flex', gap: '5px', p: '2px', zIndex: "10", alignItems: 'flex-start', margin: '0 auto', width: '95%', marginTop: '10px', }}>

        <TextField
          select
          disabled={!bandera}
          value={frec}
          label="Actualización Frecuente"
          InputLabelProps={{ shrink: true, }}
          SelectProps={{ native: true, }}
          sx={{ width: "50%" }}
          onChange={(event) => setfrec(event.target.value)}
        >
          <option key={1} value={true} selected>
            {"SI"}
          </option>
          <option key={2} value={false}>
            {"NO"}
          </option>
        </TextField>
        <TextField
          select
          disabled={!bandera}
          value={sendms}
          label="Envíar a DMS"
          InputLabelProps={{ shrink: true, }}
          SelectProps={{ native: true, }}
          sx={{ width: "50%" }}
          onChange={(event) => setsendms(event.target.value)}
        >
          <option key={1} value={true} selected>
            {"SI"}
          </option>
          <option key={2} value={false}>
            {"NO"}
          </option>
        </TextField>
      </Box> */}


      <Box sx={{ display: 'flex', gap: '5px', p: '2px', zIndex: "10", alignItems: 'flex-start', margin: '0 auto', width: '95%', marginTop: '10px', }}>

        <Autocomplete
          id="frequent-update-autocomplete"
          options={options}
          getOptionLabel={(option) => option.label}
          value={options.find(option => option.value === frec)}
          onChange={(event, newValue) => {
            setfrec(newValue ? newValue.value : frec);
          }}
          style={{ width: "100%" }}

          disabled={!bandera}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Actualización Frecuente"
              InputLabelProps={{ shrink: true }}
            />
          )}
        />


        <Autocomplete
          id="send-dms-autocomplete"
          options={options} // Las opciones a mostrar
          getOptionLabel={(option) => option.label} // Cómo mostrar las etiquetas de las opciones
          value={options.find(option => option.value === sendms)} // Encuentra la opción que coincide con el valor actual
          onChange={(event, newValue) => {
            setsendms(newValue ? newValue.value : sendms); // Establece el nuevo valor seleccionado
          }}
          style={{ width: "100%" }}

          disabled={!bandera} // Desactiva el componente si la bandera está en falso
          renderInput={(params) => (
            <TextField
              {...params}
              label="Enviar a DMS"
              InputLabelProps={{ shrink: true }}
            />
          )}
        />
      </Box>

      <Box sx={{ display: 'flex', gap: '5px', p: '2px', zIndex: "10", alignItems: 'flex-start', margin: '0 auto', width: '95%', marginTop: '10px', }}>
        {
          bandera === true ?
            <Button
              variant="contained"
              style={{ "backgroundColor": "green" }}
              sx={{ width: "50%" }}
              onClick={() => { props.operation === "EDIT" ? updateRuta() : createnewRuta() }}
            >{props.operation === "EDIT" ? "Actualizar Ruta" : "Crear Ruta"}
            </Button> :
            <Button
              variant="contained"
              disabled
              style={{ "backgroundColor": "green", "height": "35px" }}
              sx={{ width: "50%" }}
            >
              <Spinner></Spinner>
            </Button>
        }
        <Button
          style={{ "backgroundColor": "red" }}
          variant="contained"
          sx={{ width: "50%" }}
          disabled={!bandera}
          onClick={() => {
            dialog.close();
          }
          }
        >
          cancelar
        </Button>
      </Box>
      <br></br>
    </div>
  );
}
export default OPERATIONES_ROUTES;