//PAQUETERIAS
import React, { useState, useEffect, useRef } from "react";
import { styled } from "@mui/material/styles";
import { useDialog } from "react-st-modal";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import PaidIcon from '@mui/icons-material/Paid';
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import ScaleIcon from '@mui/icons-material/Scale';
//COMPONENTES
import MODAL_TABLE from "../../../main_components/modal/modal_Table";
import { refresh_token } from "../../../main_components/tokens/tokenrefresh";
import { get_producst_line } from "../../../services/inventory/inventory";
import { get_products_brands } from "../../../services/inventory/inventory";
import uinits from "../../../dataComponets/inventory/unit.json";
import type_product from "../../../dataComponets/inventory/type.json";
import uinits2 from "../../../dataComponets/inventory/unit2.json";
import dms from "../../../dataComponets/inventory/dms.json";
import { create_products } from "../../../services/inventory/inventory";
import { update_products } from "../../../services/inventory/inventory";
import { get_products_tax } from "../../../services/inventory/inventory";


//IMAGENES

//ESTILOS
import "./products.css";
import { catchErrorModul } from "../../../main_components/catchErr/catchError";

const op1 = [
  { value: "CLIENTE", label: "CLIENTE" },
  { value: "PROVEEDOR", label: "PROVEEDOR" },
  { value: "AMBOS", label: "AMBOS" },
];

const ValidationTextField = styled(TextField)({
  "& input:valid + fieldset": { borderColor: "green", borderWidth: 2 },
  "& input:valid:focus + fieldset": {
    borderLeftWidth: 6,
    padding: "4px !important",
  },
});

const CREATE_UPDATE = (props) => {
  const [code, setcode] = useState(props.code);
  const [product_name, setproduct_name] = useState(props.product_name);
  const [description, setdescription] = useState(props.description);
  const [barcode, setbarcode] = useState(props.barcode);
  const [barcode_corrugado, setbarcode_corrugado] = useState(props.corrugado);
  const [boxed_parts, setboxed_parts]=useState(props?.boxed_parts)

  const [type, settype] = useState(props.type);
  const [product_line_data, setproduct_line_data] = useState("CLIENTE");
  const [enable, setenable] = useState(props.enable);
  const [only_promo, setonly_promo] = useState(props.only_promo);
  const [cost, setcost] = useState(props.cost);
  const [pieces, setpieces] = useState(props.pieces);
  const [clave_prod_serv, setclave_prod_serv] = useState(props.clave_prod_serv);
  const [clave_unidad, setclave_unidad] = useState(props.clave_unidad);
  const [clave_unidad_2, setclave_unidad_2] = useState(props.clave_unidad_2);
  const [weight, setweight] = useState(props.weight);
  const [iva0, setiva0] = useState(true);
  const [iva16, setiva16] = useState(false);
  const [ieps8, setieps8] = useState(false);
  const [minimum_sales_unit, setminimum_sales_unit] = useState(props.minimum_sales_unit);
  const [minimum_sales_unit2, setminimum_sales_unit2] = useState(props.minimum_sales_unit2);
  const [unit, setunit] = useState(props.unit);
  const [unit_dms, setunit_dms] = useState(props.unit_dms);
  const [unit_conversion, setunit_conversion] = useState(props.unit_conversion);
  const [validate_code, setvalidate_code] = useState(true);
  const [validate_product_name, setvalidate_product_name] =useState(true);
  const [validate_description, setvalidate_description] = useState(true);
  const [validate_barcode, setvalidate_barcode] = useState(true);
  const [validate_barcode_corrugado, setvalidate_barcode_corrugado] = useState(true);

  const [validate_type, setvalidate_type] = useState(true);
  const [validate_cost, setvalidate_cost] = useState(true);
  const [validate_weight, setvalidate_weight] = useState(true);
  const [validate_unit_conversion, setvalidate_unit_conversion] =useState(true);
  const [validate_pieces, setvalidate_pieces] = useState(true);
  const [validate_clave_prod_serv, setvalidate_clave_prod_serv] =useState(true);
  const [validate_clave_unidad, setvalidate_clave_unidad] =useState(true);
  const [validate_clave_unidad2, setvalidate_clave_unidad2] =useState(true);

  const [validate_boxed_parts, setvalidate_boxed_parts]=useState(true);

  const [product_line, setproduct_line] = useState([]);
  const [product_brands, setproduct_brands] = useState([]);
  const [brand, setbrand] = useState(props.brand===null?"MORDISKO":props.brand);
  const dialog = useDialog();
  const [modalT, setmodalT] = useState(false);
  const notificationSystemRef = useRef();
  const [value, setValue] = useState(0);
  const [detail_product, setdetail_product] = useState("");
  const [detailSale, setdetailSale] = useState("none");
  const [fiscal, setfiscal] = useState("none");
  const [product, setproduct] = useState("none");
  const [noSale, setnoSale] = useState("none");
  const [name_line, setname_line] = useState(props.name_line);
  const [product_line_data_json, setproduct_line_data_json] = useState([]);

  
  const handle_detail_product = () => {
    setdetail_product("");
    setdetailSale("none");
    setfiscal("none");
    setproduct("none");
    setnoSale("none");
  };

  const valid1=()=>{
    cost > 0 ? setvalidate_cost(true) : setvalidate_cost(false);
    code === "" ? setvalidate_code(false) : setvalidate_code(true);
    product_name === "" ? setvalidate_product_name(false) : setvalidate_product_name(true);
    description === "" ? setvalidate_description(false) : setvalidate_description(true);
    barcode === "" ? setvalidate_barcode(false) : setvalidate_barcode(true);
    boxed_parts === 0 ? setvalidate_boxed_parts(false) : setvalidate_boxed_parts(true);
  }
  const valid2=()=>{
    weight <= 0 ? setvalidate_weight(false) : setvalidate_weight(true);
    pieces === "" ? setvalidate_pieces(false) : setvalidate_pieces(true);
    unit_conversion <=0  ? setvalidate_unit_conversion(false) : setvalidate_unit_conversion(true);
  }
  const valid3=()=>{
    clave_unidad === "" ? setvalidate_clave_unidad(false) : setvalidate_clave_unidad(true);
    clave_prod_serv?.length <= 8 ? setvalidate_clave_prod_serv(true) : setvalidate_clave_prod_serv(false);
    clave_unidad_2 === "" ? setvalidate_clave_unidad2(false) : setvalidate_clave_unidad2(true);
  }

  const handle_sales_unit = () => {
    if (code !== "" && product_name !== "" && barcode !== "" && description !== "" && cost > 0 && boxed_parts !== 0 ) {
      setdetail_product("none");
      setdetailSale("none");
      setfiscal("none");
      setproduct("none");
      setnoSale("");
    } else {
      valid1();
    }
  };
 const handle_tax_product = async () => {
  try{
    var rf_token = await refresh_token();
    if(rf_token===true){
      setTimeout(async() => {
        try {
          const dt = await get_products_tax(props.id);
          console.log(dt);
          const ieps_data = dt?.data?.filter((d) => d.code === "ieps8");
          const iva0_data = dt?.data?.filter((d) => d.code === "iva0");
          const iva16_data = dt?.data?.filter((d) => d.code === "iva16");
          console.log(ieps_data);
          console.log(iva0_data);
          console.log(ieps_data[0]?.configured);
          console.log(iva0_data[0]?.configured);
          console.log(iva16_data[0]?.configured);
          if(iva0_data[0]?.configured === true){
            setiva0(true);
            setiva16(false);
          }
          if(iva16_data[0]?.configured === true){
            setiva0(false);
            setiva16(true);
          }
          if(ieps_data[0]?.configured === true){
            setieps8(true);
          }else{
            setieps8(false);
          }
        } catch (err) {}
      }, 1000);
    }else{}
  }catch(err){
    console.log(err);
  }
};

  const handle_fiscal = () => {
    if ( code !== "" && product_name !== "" && barcode !== "" && description !== "" && cost > 0 && weight > 0 && pieces > 0 && unit_conversion >= 0
    ) {
      setdetail_product("none");
      setdetailSale("none");
      setfiscal("");
      setproduct("none");
      setnoSale("none");
    } else {
      valid2();
    }
  };



  useEffect(() => {
    setdetail_product(detail_product);
    setdetailSale(detailSale);
    setfiscal(fiscal);
    setproduct(product);
    setnoSale(noSale);
  }, [setdetail_product, setdetailSale, setfiscal, setproduct, setnoSale]);



  const consult_get_producst_line = async (event) => {
    event?.preventDefault();
    try{
      var rf_token = await refresh_token();
      if (rf_token === true) {
        setTimeout(async() => {
          try{
            console.log("ejecuta 1.5 segundos despues");
            const dt = await get_producst_line();
            setproduct_line(dt.data);
          }catch(err){
            setproduct_line([]);
          }
        }, 1500);
      }else{}
    }catch(err){
      console.log(err);
    }
  };

  const consult_get_products_brands = async (event) => {
    event?.preventDefault();
    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        console.log("ejecuta");
        setTimeout(async () => {
          try{
            console.log("ejecuta 1.5 segundos despues");
            const dt = await get_products_brands();
            setproduct_brands(dt.data);
          }catch(err){
            setproduct_brands([]);
          }
        }, 1500);
      } else {
      }
    } catch (err) {
      console.log(err);
    }
  };
  var dta = {
    code: code,
    product_name: product_name,
    description: description,
    barcode: barcode,
    type: type,
    unit: unit,
    unit_conversion: unit_conversion,
    minimum_sales_unit: minimum_sales_unit,
    image: null,
    id_products_line_fk: name_line,
    pieces: pieces,
    min_sales_unit_dms: unit_dms,
    clave_prod_serv: clave_prod_serv,
    clave_unidad: clave_unidad,
    clave_unidad_2: clave_unidad_2,
    minimum_sales_unit_2: minimum_sales_unit2,
    enable: enable,
    only_promo: only_promo,
    cost: cost,
    brand: brand,
    weight: weight,
    iva0: iva0,
    iva16: iva16,
    ieps8: ieps8,
    corrugado:barcode_corrugado,
    boxed_parts: Number(boxed_parts)
  };
  const handle_create_product = async() => {
    var dta = {
      code: code,
      product_name: product_name,
      description: description,
      barcode: barcode,
      type: type,
      unit: unit,
      unit_conversion: unit_conversion,
      minimum_sales_unit: minimum_sales_unit,
      image: null,
      id_products_line_fk: name_line,
      pieces: pieces,
      min_sales_unit_dms: unit_dms,
      clave_prod_serv: clave_prod_serv,
      clave_unidad: clave_unidad,
      clave_unidad_2: clave_unidad_2,
      minimum_sales_unit_2: minimum_sales_unit2,
      enable: enable,
      only_promo: only_promo,
      cost: cost,
      brand: brand,
      weight: weight,
      iva0: iva0,
      iva16: iva16,
      ieps8: ieps8,
      corrugado:barcode_corrugado,
      boxed_parts: Number(boxed_parts),

    };

    if ( code !== "" && product_name !== "" && barcode !== "" && description !== "" && cost > 0 && weight > 0 && pieces > 0 && unit_conversion >= 0 && clave_unidad !=="" && clave_unidad_2 !=="" && clave_prod_serv.length <=8) {
      setmodalT(true);

      try{
        var rf_token = await refresh_token();
        if(rf_token===true){
          setTimeout(async() => {
            try {
              if(props.method==="CREATE"){
                await create_products(dta);
                dialog.close({validate:true,msj:"Producto creado correctamente"})
                props?.handleSubmit();
                setmodalT(false)

              }else{
                await update_products(props.id,dta);
                console.log("actualizado....")
                dialog.close({validate:true,msj:"Producto actualizado correctamente"})

                props?.handleSubmit();
                setmodalT(false)
              }
            } catch (err) { 
              catchErrorModul("Productos",JSON.stringify(dta),JSON.stringify(err?.response));
              dialog.close({validate:false,msj:JSON.stringify(err.response?.data)})
              setmodalT(false)
            }
          }, 1000);
        }else{}
      }catch(err){
        console.log(err);
      }      
    }else{
      valid3();
    }
  };

  useEffect(() => {
    consult_get_producst_line();
    consult_get_products_brands();
    
    if(props.method==="UPDATE"){
      handle_tax_product();
    }
  }, []);
  return (
    <div >
      <MODAL_TABLE
        open={true}
        message={""}
        modalGeneral={false}
        modalT={modalT}
      ></MODAL_TABLE>
      <BottomNavigation
        className="navitation_products_crud"
        showlabel
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
      >
        <div>
          <BottomNavigationAction
            className="btonwith"
            showlabel
            label="Datos generales"
            icon={<DriveFileRenameOutlineIcon onClick={handle_detail_product} />}
            onClick={handle_detail_product}
          />
        </div>

        <div>
          <BottomNavigationAction
            showlabel
            sx={{ width: "50%" }}
            label="Dirección"
            icon={<ScaleIcon onClick={handle_sales_unit} />}
            onClick={handle_sales_unit}
          />
        </div>

        <div>
          <BottomNavigationAction
            className="btonwith"
            showlabel
            label="Datos generales"
            icon={<PaidIcon onClick={handle_fiscal} />}
            onClick={handle_fiscal}
          />
        </div>
      </BottomNavigation>

      <div style={{ display: detail_product }}>
        <div className="containerProducts">
          <div className="bp-1">
            <ValidationTextField
              label="Código"
              required
              placeholder="Código"
              sx={{ m: 2, width: "100%" }}
              variant="outlined"
              defaultValue="Success"
              id="validation-outlined-input"
              name="code"
              value={code}
              onChange={(e) => setcode(e.target.value)}
              onBlur={() =>
                code === "" ? setvalidate_code(false) : setvalidate_code(true)
              }
              InputLabelProps={{
                shrink: true,
              }}
            />

            <br />
            <p className="validate_data">
              {validate_code === false ? "Completa este campo" : ""}
            </p>
          </div>

          <div className="bp-2">
            <ValidationTextField
              label="Nombre del Producto"
              required
              placeholder="Nombre del Producto"
              sx={{ m: 2, width: "100%" }}
              variant="outlined"
              defaultValue="Success"
              id="validation-outlined-input"
              value={product_name}
              onChange={(e) => {
                const value = e.target.value;
                if (typeof value === "string") {
                  setproduct_name(value.toUpperCase());
                } else {
                  setproduct_name(value);
                }
              }}
              onBlur={() =>
                code === ""
                  ? setvalidate_product_name(false)
                  : setvalidate_product_name(true)
              }
              InputLabelProps={{
                shrink: true,
              }}
            />
            <br />
            <p className="validate_data">
              {validate_product_name === false ? "Completa este campo" : ""}
            </p>
          </div>
          <div className="bp-3">
            <ValidationTextField
              label="Descripción"
              required
              placeholder="Descripción"
              sx={{ m: 2, width: "100%" }}
              variant="outlined"
              defaultValue="Success"
              value={description}
              onChange={(e) => {
                const value = e.target.value;
                if (typeof value === "string") {
                  setdescription(value.toUpperCase());
                } else {
                  setdescription(value);
                }
              }}
              onBlur={() =>
                code === ""
                  ? setvalidate_description(false)
                  : setvalidate_description(true)
              }
              InputLabelProps={{
                shrink: true,
              }}
            />
            <br />
            <p className="validate_data">
              {validate_description === false ? "Completa este campo" : ""}
            </p>
          </div>

          <div className="bp-4">
            <ValidationTextField
              label="Código de Barras"
              placeholder="Código de Barras"
              variant="outlined"
              required
              sx={{ m: 2, width: "100%" }}
              defaultValue="Success"
              name="capital_regime"
              value={barcode}
              onChange={(e) => {
                const value = e.target.value;
                if (typeof value === "string") {
                  setbarcode(value.toUpperCase());
                } else {
                  setbarcode(value);
                }
              }}
              onBlur={() =>
                code === ""
                  ? setvalidate_barcode(false)
                  : setvalidate_barcode(true)
              }
              InputLabelProps={{
                shrink: true,
              }}
            />
            <br />
            <p className="validate_data">
              {validate_barcode === false ? "Completa este campo" : ""}
            </p>
          </div>

          <div className="bp-4n">
            <ValidationTextField
              label="Código de Corrugado"
              placeholder="Código de Corrugado"
              variant="outlined"
              sx={{ m: 2, width: "100%" }}
              name="capital_regime"
              value={barcode_corrugado}
              onChange={(e) => setbarcode_corrugado(e.target.value)}
              onBlur={() =>
                barcode_corrugado === ""
                  ? setvalidate_barcode_corrugado(false)
                  : setvalidate_barcode_corrugado(true)
              }
              InputLabelProps={{
                shrink: true,
              }}
            />
            <br />
            <p className="validate_data">
              {validate_barcode_corrugado === false ? "Completa este campo" : ""}
            </p>
          </div>
          <div className="bp-5">
            <TextField
              id="tipo"
              select
              label="Tipo"
              value={type}
              sx={{ m: 1.5, width: "100%" }}
              onChange={(e) => settype(e.target.value)}
              size="small"
              name="Tipo"
              InputLabelProps={{
                shrink: true,
              }}
              SelectProps={{
                native: true,
              }}
            >
              {type_product.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </TextField>
          </div>

          <div className="bp-6">
            <TextField
              id="Lineadeproducto"
              select
              label="Linea de producto"
              value={props.subtabla===true?sessionStorage.getItem("id_product_line"):name_line}
              sx={{ m: 1.5, width: "100%" }}
              size="small"
              onChange={(e)=>setname_line(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
              SelectProps={{
                native: true,
              }}
            >
              {product_line.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.name_line}
                </option>
              ))}
            </TextField>
          </div>
          
          <div className="bp-10b">
          <TextField
              id="boxed_parts"
              label="Piezas por Caja"
              type="number"
              required
              value={boxed_parts}
              onChange={(e) => setboxed_parts(e.target.value)}
              sx={{ m: 1.5, width: "100%" }}
              size="small"
              name="Piezas por caja"
              InputLabelProps={{
                shrink: true,
              }}
            >
            </TextField>
            <p className="validate_data">
              {validate_boxed_parts === false ? "Completa este campo" : ""}
            </p>
          </div>

          <div className="bp-12">
            <TextField
              id="marca"
              select
              label="Marca"
              value={brand}
              onChange={(e) => setbrand(e.target.value)}
              sx={{ m: 1.5, width: "100%" }}
              size="small"
              name="Marca"
              InputLabelProps={{
                shrink: true,
              }}
              SelectProps={{
                native: true,
              }}
            >
              {product_brands.map((option) => (
                <option key={option.brand} value={option.brand}>
                  {option.brand}
                </option>
              ))}
            </TextField>
          </div>

          <div className="bp-7">
            <FormControlLabel
              value="bottom"
              control={
                <Checkbox
                  checked={enable}
                  onChange={(e) => setenable(e.target.checked)}
                />
              }
              label="Disponible"
              labelPlacement="bottom"
            />
          </div>

          <div className="bp-8">
            <FormControlLabel
              value="bottom"
              control={
                <Checkbox
                  checked={only_promo}
                  onChange={(e) => setonly_promo(e.target.checked)}
                />
              }
              label="Promo ONLY"
              labelPlacement="bottom"
            />
          </div>
          <div className="bp-9">
            <ValidationTextField
              label="Precio Costo "
              placeholder="Precio Costo"
              variant="outlined"
              defaultValue="Success"
              id="validation-outlined-input"
              sx={{ m: 2, width: "100%" }}
              name="first_last_name"
              InputLabelProps={{
                shrink: true,
              }}
              type="number"
              value={cost}
              onChange={(e) => setcost(e.target.value)}
              onBlur={(e) =>
                e.target.value <= 0
                  ? setvalidate_cost(false)
                  : setvalidate_cost(true)
              }
            />
            <br />
            <p className="validate_data">
              {validate_cost === false ? "El precio debe ser mayor a '0'" : ""}
            </p>
          </div>
          <div className="bp-10">
            <Button
              onClick={handle_sales_unit}
              sx={{ m: 2, width: "100%" }}
              variant="contained"
              style={{ background: "green" }}
            >
              SIGUIENTE
            </Button>
          </div>

          <div className="bp-11">
            <Button
              onClick={() => {
                dialog.close();
              }}
              sx={{ m: 2, width: "100%" }}
              variant="contained"
              style={{ background: "red" }}
            >
              CANCELAR
            </Button>
          </div>
        </div>
      </div>
      {/* sección 2 */}

      <div style={{ display: noSale }}>
        <div className="containerProducts02">
          <div className="bp-1">
            <TextField
              id="unidaddeventa"
              select
              label="Unidad de Venta"
              value={minimum_sales_unit}
              sx={{ m: 1.5, width: "100%" }}
              size="small"
              name="Unidad de Venta"
              InputLabelProps={{
                shrink: true,
              }}
              SelectProps={{
                native: true,
              }}
              onChange={(e) => {
                setminimum_sales_unit(e.target.value)
                console.log(e.target.value)

                setunit_dms(e.target.value==="PIEZA"?"ZCU":"CJ")
                setclave_unidad(e.target.value==="PIEZA"?"H87":"XBX")
                setclave_unidad_2(e.target.value==="PIEZA"?"H87":"XBX")
              
              }}
            >
              {uinits.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </TextField>
          </div>

          <div className="bp-2">
            <TextField
              id="Unidad Minima de Bonificación"
              select
              label="Unidad Minima de Bonificación"
              value={minimum_sales_unit2}
              sx={{ m: 1.5, width: "100%" }}
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              SelectProps={{
                native: true,
              }}
              onChange={(e) => setminimum_sales_unit2(e.target.value)}
            >
              {uinits.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </TextField>
          </div>
          <div className="bp-3">
            <ValidationTextField
              label="Piezas"
              required
              placeholder="Piezas	"
              sx={{ m: 2, width: "100%" }}
              variant="outlined"
              defaultValue="Success"
              value={pieces.toFixed(0)}
              type="number"
              onChange={(e) => setpieces(Number(e.target.value))}
              onBlur={(e) =>
                e.target.value <= 0
                  ? setvalidate_pieces(false)
                  : setvalidate_pieces(true)
              }
              InputLabelProps={{
                shrink: true,
              }}
            />
            <br />
            <p className="validate_data">
              {validate_pieces === false
                ? "El número de piezas debe ser mayor a '0'"
                : ""}
            </p>
          </div>

          <div className="bp-9">
            <ValidationTextField
              label="Peso"
              required
              placeholder="Peso	"
              sx={{ m: 2, width: "100%" }}
              variant="outlined"
              defaultValue="Success"
              value={weight}
              type="number"
              onChange={(e) => setweight(Number(e.target.value))}
              onBlur={(e) =>
                e.target.value <= 0
                  ? setvalidate_weight(false)
                  : setvalidate_weight(true)
              }
              InputLabelProps={{
                shrink: true,
              }}
            />
            <br />
            <p className="validate_data">
              {validate_weight === false ? "El peso debe ser mayor a '0'" : ""}
            </p>
          </div>

          <div className="bp-4">
            <TextField
              id="medicion"
              select
              label="Medida"
              value={unit}
              sx={{ m: 1.5, width: "100%" }}
              size="small"
              name="Medida"
              InputLabelProps={{
                shrink: true,
              }}
              SelectProps={{
                native: true,
              }}
              onChange={(e) => setunit(e.target.value)}
            >
              {uinits2.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </TextField>
          </div>

          <div className="bp-5">
            <TextField
              id="unidaddemedidadns"
              select
              label="Unidad de Medida DMS"
              value={unit_dms}
              sx={{ m: 1.5, width: "100%" }}
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              SelectProps={{
                native: true,
              }}
              onChange={(e) => setunit_dms(e.target.value)}
            >
              {dms.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </TextField>
          </div>

          <div className="bp-6">
            <ValidationTextField
              label="Conversión de Media"
              placeholder="Conversión de Medida"
              variant="outlined"
              defaultValue="Success"
              id="validation-outlined-input"
              sx={{ m: 2, width: "100%" }}
              name="Conversión"
              InputLabelProps={{
                shrink: true,
              }}
              type="number"
              value={unit_conversion}
              onChange={(e) => setunit_conversion(e.target.value)}
              onBlur={(e) =>
                e.target.value >= 0
                  ? setvalidate_unit_conversion(true)
                  : setvalidate_unit_conversion(false)
              }
            />
            <p className="validate_data">
              {validate_unit_conversion === false
                ? "La conversión debe ser mayor o igual a '0'"
                : ""}
            </p>
          </div>

          <div className="bp-7">
            <Button
              onClick={handle_fiscal}
              sx={{ m: 2, width: "100%" }}
              variant="contained"
              style={{ background: "green" }}
            >
              SIGUIENTE
            </Button>
          </div>

          <div className="bp-8">
            <Button
              onClick={() => {
                dialog.close();
              }}
              sx={{ m: 2, width: "100%" }}
              variant="contained"
              style={{ background: "red" }}
            >
              CANCELAR
            </Button>
          </div>
        </div>
      </div>

      {/* sección 3 */}

      <div style={{ display: fiscal }}>
        <div className="containerProducts03">
          <div className="bp-1">
            <ValidationTextField
              label="Clave Producto Servicio (SAT)"
              required
              placeholder="Clave Producto Servicio (SAT)"
              sx={{ m: 2, width: "100%" }}
              variant="outlined"
              defaultValue="Success"
              id="validation-outlined-input"
              name="code"
              inputProps={{ maxLength: 8, minLength: 8 }}
              value={clave_prod_serv}
              onChange={(e) => setclave_prod_serv(e.target.value)}
              onBlur={() =>
                clave_prod_serv.length !== 8
                  ? setvalidate_clave_prod_serv(false)
                  : setvalidate_clave_prod_serv(true)
              }
              InputLabelProps={{
                shrink: true,
              }}
            />

            <br />
            <p className="validate_data">
              {validate_clave_prod_serv === false
                ? "La longitud de la clave debe contener 8 caracteres"
                : ""}
            </p>
          </div>

          <div className="bp-2">
            <ValidationTextField
              label="Clave Unidad(SAT)"
              required
              placeholder="Clave Unidad(SAT)"
              sx={{ m: 2, width: "100%" }}
              variant="outlined"
              defaultValue="Success"
              id="validation-outlined-input"
              name="code"
              value={clave_unidad}
              inputProps={{ maxLength: 20 }}
              onChange={(e) => setclave_unidad(e.target.value)}
              onBlur={() =>
                clave_unidad === ""
                  ? setvalidate_clave_unidad(false)
                  : setvalidate_clave_unidad(true)
              }
              InputLabelProps={{
                shrink: true,
              }}
            />

            <br />
            <p className="validate_data">
              {validate_clave_unidad === false ? "Completa este campo" : ""}
            </p>
          </div>
          <div className="bp-3">
            <ValidationTextField
              label="Clave Unidad por Corrugado (SAT)"
              required
              placeholder="Clave Unidad por Corrugado (SAT)"
              sx={{ m: 2, width: "100%" }}
              variant="outlined"
              defaultValue="Success"
              id="validation-outlined-input"
              name="code"
              value={clave_unidad_2}
              inputProps={{ maxLength: 20 }}
              onChange={(e) => setclave_unidad_2(e.target.value)}
              onBlur={() =>
                clave_unidad_2 === ""
                  ? setvalidate_clave_unidad2(false)
                  : setvalidate_clave_unidad2(true)
              }
              InputLabelProps={{
                shrink: true,
              }}
            />

            <br />
            <p className="validate_data">
              {validate_clave_unidad2 === false ? "Completa este campo" : ""}
            </p>
          </div>

          <div className="bp-4">
            <FormControlLabel
              value="bottom"
              control={
                <Checkbox
                  checked={iva0}
                  onChange={(e) => {
                    setiva0(e.target.checked);
                    setiva16(!e.target.checked);
                  }}
                />
              }
              label="IVA 0%"
              labelPlacement="bottom"
            />
            <FormControlLabel
              value="bottom"
              control={
                <Checkbox
                  checked={ieps8}
                  onChange={(e) => setieps8(e.target.checked)}
                />
              }
              label="IEPS 8%"
              labelPlacement="bottom"
            />
            <FormControlLabel
              value="bottom"
              control={
                <Checkbox
                  checked={iva16}
                  onChange={(e) => {
                    setiva16(e.target.checked);
                    setiva0(!e.target.checked);
                  }}
                />
              }
              label="IVA 16%"
              labelPlacement="bottom"
            />
          </div>

          <div className="bp-7">
            <Button
              onClick={handle_create_product}
              sx={{ m: 2, width: "100%" }}
              variant="contained"
              style={{ background: "green" }}
            >
              {props.method==="UPDATE"?"ACTUALIZAR":"CREAR"}
            </Button>
          </div>

          <div className="bp-8">
            <Button
              onClick={() => {
                dialog.close();
              }}
              sx={{ m: 2, width: "100%" }}
              variant="contained"
              style={{ background: "red" }}
            >
              CANCELAR
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CREATE_UPDATE;
