import { Box } from "@mui/material";

export const getcolumns = ()=>{
    return [
        {
          id: "success",
          header: "Estado",
          accessorKey: "success",
          size: 80,
          muiTableBodyCellProps:{
            align: "center"
          },
          accessorFn:(d)=>{
            return d['brand_validate'] === false || d['model_validate'] === false ? "❌" : "✅"
          }
        },
        {
          id: "economic_number",
          header: "Número Económico",
          accessorKey: "economic_number",
          accessorFn:(d)=>{
            return d['Numero economico']
          },
          muiTableBodyCellProps: {
            align: 'center',
          }
        },
        {
          id: "fixed_asset",
          header: "Numero Activo Fijo",
          accessorKey: "fixed_asset",
          accessorFn: (d) =>{
            return d['Numero activo fijo'];
          },
          muiTableBodyCellProps:{
            align: "center"
          }
        },
        {
          id: "serial_number",
          header: "Numero de Serie",
          accessorKey: "serial_number",
          accessorFn: (d) =>{
            return d['Numero serie'];
          },
          muiTableBodyCellProps:{
            align: "center"
          }
        },
        {
          id: "Comodato",
          header: "Comodato",
          accessorKey: "Comodato",
          accessorFn: (d) =>{
            return d['Comodato'];
          },
          muiTableBodyCellProps:{
            align: "center"
          }
        },
        {
          id: "brand",
          header: "Marca",
          accessorKey: "brand",
          accessorFn: (d) =>{
            return d['Marca'];
          },
          muiTableBodyCellProps:{
            align: "center"
          },
          Cell: (d) => {
            console.log(d?.row?.original["Marca"]);
            return(
            <Box
              component="span"
              sx={(theme) => ({
                color: d?.row?.original['brand_validate'] === false ? 'red': 'black'
              })}
            >
            {
              d?.row?.original['Marca']
            }
            </Box>
          )}
        },
        {
          id: "model",
          header: "Modelo",
          accessorKey: "model",
          accessorFn: (d) =>{
            return d['Modelo'];
          },
          muiTableBodyCellProps:{
            align: "center"
          },
          Cell: (d) => {
            console.log(d?.row?.original["Modelo"]);
            return(
            <Box
              component="span"
              sx={(theme) => ({
                color: d?.row?.original['model_validate'] === false ? 'red': 'black'
              })}
            >
            {
              d?.row?.original['Modelo']
            }
            </Box>
          )},
        }
    ]
}

export const getcolumns_error = () =>{
    return [
        {
          id: "register",
          header: "Registro",
          filterVariant: 'select',
          accessorKey: "register",
          size: 80,
          muiTableBodyCellProps:{
            align: "center"
          },
          accessorFn:(d)=>{
            return d['status_register'] === true ? "✅" : "❌"; 
          }
        },
        {
          id: "economic_number",
          header: "Número Económico",
          accessorKey: "economic_number",
          accessorFn:(d)=>{
            return d['Numero economico']
          },
          muiTableBodyCellProps: {
            align: 'center',
          }
        },
        {
          id: "fixed_asset",
          header: "Numero Activo Fijo",
          accessorKey: "fixed_asset",
          accessorFn: (d) =>{
            return d['Numero activo fijo'];
          },
          muiTableBodyCellProps:{
            align: "center"
          }
        },
        {
          id: "serial_number",
          header: "Numero de Serie",
          accessorKey: "serial_number",
          accessorFn: (d) =>{
            return d['Numero serie'];
          },
          muiTableBodyCellProps:{
            align: "center"
          }
        },
        {
          id: "status",
          header: "Estado",
          accessorKey: "status",
          accessorFn: (d)=>{
            return d['response_error_register'] === "" ? "Congelador Registrado": d['response_error_register']
          }
        }
      ]
}

export const getcolumns_put = () =>{
    return [
        {
            id: "success",
            header: "Estado",
            accessorKey: "success",
            size: 80,
            muiTableBodyCellProps:{
              align: "center"
            },
            accessorFn:(d)=>{
              return d['model_validate'] === false || d['num_econ_validate'] === false ? "❌" : "✅"
            }
          },
          {
            id: "economic_number",
            header: "Número Económico",
            accessorKey: "economic_number",
            accessorFn:(d)=>{
              return d['Numero economico']
            },
            muiTableBodyCellProps: {
              align: 'center',
            },
            Cell: (d) => {
              return(
              <Box
                component="span"
                sx={() => ({
                  color: d?.row?.original['num_econ_validate'] === false ? 'red': 'black'
                })}
              >
              {
                d?.row?.original['Numero economico']
              }
              </Box>
            )},
          },
          {
            id: "model",
            header: "Modelo",
            accessorKey: "model",
            accessorFn: (d) =>{
              return d['Modelo'];
            },
            muiTableBodyCellProps:{
              align: "center"
            },
            Cell: (d) => {
              return(
              <Box
                component="span"
                sx={(theme) => ({
                  color: d?.row?.original['model_validate'] === false ? 'red': 'black'
                })}
              >
              {
                d?.row?.original['Modelo']
              }
              </Box>
            )},
          }
    ]
}

export const getcolumns_puterror = () =>{
  return [
    {
      id: "register",
      header: "Registro",
      filterVariant: 'select',
      accessorKey: "register",
      size: 80,
      muiTableBodyCellProps:{
        align: "center"
      },
      accessorFn:(d)=>{
        return d['status_register'] === true ? "✅" : "❌"; 
      }
    },
    {
      id: "economic_number",
      header: "Número Económico",
      accessorKey: "economic_number",
      accessorFn:(d)=>{
        return d['Numero economico']
      },
      muiTableBodyCellProps: {
        align: 'center',
      }
    },
    {
      id: "status",
      header: "Estado",
      accessorKey: "status",
      accessorFn: (d)=>{
        return d['response_error_register'] === "" ? "Congelador Actualizado": d['response_error_register']
      }
    }
  ]
}