import React, { useEffect, useState } from 'react'
import TextField from "@mui/material/TextField";
import { Box, Button } from '@mui/material';
import { useDialog } from 'react-st-modal';
import { refresh_token } from '../../../../main_components/tokens/tokenrefresh';
import { put_cabinets } from '../../../../services/assets/assets';


export const SHOW_PUT_CABINETS = (props) => {

    const dialog = useDialog(); 
    const [bandera, setbandera] = useState(props?.row?.original?.id === null ? true: false );
    const [economic_number, seteconomic_number]=useState(props?.row?.original['Numero economico']);
    const [id_cabinets_model, setid_cabinets_model]=useState(props?.row?.original['id_model']);
    const [production_year, setproduction_year]=useState(props?.row?.original['Año']);
    const [datamodels, setdatamodels]=useState(props?.datamodels);
    const [brand, setbrand] = useState(props?.row?.original?.brand);
    const [serial, setserial] = useState(props?.row?.original?.serial_number);
    const [id, setid] = useState(props?.row?.original?.id);
    
    const handle_economic = (e) =>{
        seteconomic_number(e);
        const datacabinets = props?.data;
        const found = datacabinets?.some(i => {
            if (i.economic_number === e) {
                setbandera(false);
                const dm = datamodels?.filter(j=>j.name_brand === i.brand);
                setbrand(i?.brand);
                setid(i?.id);
                setserial(i?.serial_number);
                setdatamodels(dm);
                return true;
            }
            return false;
        });
        if (!found) {
            setbandera(true);
        }
    }

const Update_Register = () =>{

    const dataoriginal = [...props?.file_cabinets];
    const item = { ...dataoriginal[props?.id] };     
    const model_new = props?.datamodels.filter(i => i.id === parseInt(id_cabinets_model))[0]?.name_model; 
  
    item["Numero economico"] = economic_number;
    item['num_econ_validate'] = true;
    item['Año'] = production_year;
    item['id_model'] = parseInt(id_cabinets_model);
    item['Modelo'] = model_new;
    item['brand'] = brand;
    item['id'] = id;
    item['serial_number'] = serial;
    
    datamodels?.map(model=>{
        if(model.name_model === item['Modelo']){
          item.model_validate = true;
          item.id_model = model.id;
        }
    })
    if(item.model_validate){
        console.log("data sucess");
    }else{
        item.model_validate = false;
        item.id_model = null;
    }
    dataoriginal[props?.id] = item;
    props?.update_data(dataoriginal);
    dialog.close();
  }

const retry_update = async() =>{
    
    props?.setmodalT(true);
    const dataoriginal = [...props?.file_cabinets_error];
    const item = { ...dataoriginal[props?.id] };
    const model_new = props?.datamodels.filter(i => i.id === parseInt(id_cabinets_model))[0]?.name_model;
    
    var data = {
        "economic_number": economic_number,
        "id_cabinets_model": parseInt(id_cabinets_model), 
        "serial_number": serial,
        "production_year": production_year,
    }
    item["Numero economico"] = economic_number;
    item['Año'] = production_year;
    item['id_model'] = parseInt(id_cabinets_model);
    item['Modelo'] = model_new;
    item['serial_number'] = serial;

    props?.setmodalT(false);
    try { 
        var rf_token = await refresh_token();
        if(rf_token===true){
          setTimeout(async() => {
            try { 
              var d = await put_cabinets(dataoriginal[0]?.id, data);
              item['status_register'] = true;
              item['response_error_register'] = "";
              dataoriginal[props?.id] = item;
              props?.update_data_error(dataoriginal);
              dialog.close();
              props?.setmodalT(false);
              props.alertas("Congelador actualizado con éxito", true);
            }catch (err) {          
              props?.setmodalT(false);
              props?.alertas("Error al registrar el congelador", false);
              if(err?.response?.data?.errorMessage){
                item['response_error_register'] = "Verifica los datos del congelador.";
              }else{
                item['response_error_register'] = "Error desconocido comuníquese con soporte.";
              }
              item['status_register'] = false;
              dataoriginal[props?.id] = item;
              props?.update_data_error(dataoriginal);
            } 
          }, 1000);
        }else{}
      }catch (err) {
        console.log("error put", err);
      } 
}

  

  return (
    <div style={{"textAlign":"center"}}>
         <div className="form-frezz">
            <div className="gridfrez1">
                <div className="frez1">
                <TextField
                    label="Número Económico"
                    required
                    sx={{ m: 1.5, width: "100%" }}
                    value={economic_number}
                    onChange={(event) => {
                        handle_economic(event.target.value);
                    }}  
                    InputLabelProps={{ shrink: true }}
                ></TextField>
                </div>
                <div className="frez2">
                   <TextField
                        select
                        label="Modelo Congelador"
                        disabled={bandera}
                        value={id_cabinets_model}
                        InputLabelProps={{shrink: true}}
                        SelectProps={{native: true}}
                        sx={{ m: 1.5, width: "100%"}}
                        onChange={(event) => setid_cabinets_model(event.target.value)}  
                    >
                        <option key={0} value={0}>
                            {"Selecciona Modelo"}
                        </option>
                        {
                        datamodels?.map(item=>(
                            <option key={item.id} value={item.id}>
                                {item.name_model}
                            </option>
                        ))
                    }
                </TextField>
                </div> 
                <div className="frez3">
                    <TextField
                        label="Año de producción"
                        required
                        disabled={bandera}
                        sx={{ m: 1.5, width: "100%" }}
                        value={production_year}
                        onChange={(event) => setproduction_year(event.target.value)}  
                        InputLabelProps={{ shrink: true }}
                    ></TextField>
                </div>
            </div>
            {
                bandera===true?
                <div className='text-error'>
                    <p style={{color:"red"}}>No existe el congelador porfavor proporciona uno correcto</p>
                </div>:<></>
            }
            <Box>
                <Button
                    variant="contained"
                    style={{"background-color":"green"}}
                    sx={{ m: 1.5, width: "40ch" }}
                    onClick={()=>{ props?.process === 1 ? Update_Register(): retry_update()}}
                >{props?.process === 1 ? "ACTUALIZAR":"REINTENTAR"}
              </Button>:
          </Box>
        </div> 
    </div>
  )
}
