
//ESTILOS CSS
import "./factura.css";
import DELETE from "../../../imgComponents/borrar.png"

const CONCEPT_INVOICE = (props) => {

  return (
    <div className="contenedorConcept">
            <table>
              <thead>
                <tr className="cabezeracolor">
                  <th className="unidadConcept">Unidad</th>
                  <th className="descripcionConcept">Descripción</th>
                  <th className="cantidadConcept">Cantidad</th>
                  <th className="precioConcept">Precio</th>
                  <th className="impuestosConcept">Impuestos</th>
                  <th className="impuestosConcept">Imp. Locales</th>
                  <th className="subtotalConcept">Subtotal</th>
                  <th className="totalConcept">Total</th>
                  <th className="accionesConcept">Acciones</th>
                </tr>
              </thead>
              <tbody>
                {props.invoice.map((row) => (
                  <>
                    <tr>
                      <td className="tdunidad">{row.unidad}</td>
                      <td className="tddescripcion">{row.descripcion}</td>
                      <td>{row.cantidad}</td>
                      <td className="tdprecio">
                        {row.precio
                          ? Intl.NumberFormat("es-MX", {
                              style: "currency",
                              currency: "MXN",
                            }).format(row.precio)
                          : Intl.NumberFormat("es-MX", {
                              style: "currency",
                              currency: "MXN",
                            }).format(row.precio)}{" "}
                      </td>
                      <td className="tdimpuestos">{row?.impuestos}</td>
                      <td className="tdimpuestos">{row?.implocales}</td>
                      <td className="tdsubtotal">
                        {row.subtotal
                          ? Intl.NumberFormat("es-MX", {
                              style: "currency",
                              currency: "MXN",
                            }).format(row.subtotal)
                          : Intl.NumberFormat("es-MX", {
                              style: "currency",
                              currency: "MXN",
                            }).format(row.subtotal)}
                      </td>
                      <td>
                        {row.total
                          ? Intl.NumberFormat("es-MX", {
                              style: "currency",
                              currency: "MXN",
                            }).format(row.total)
                          : Intl.NumberFormat("es-MX", {
                              style: "currency",
                              currency: "MXN",
                            }).format(row.total)}
                      </td>
                      <td className="borrarcolum">
                        <img
                          alt=""
                          src={DELETE}
                          name={row.id}
                          onClick={()=>props.handleRemoveItem(row.id)}
                          className="borrarcl"
                        ></img>
                      </td>
                    </tr>
                  </>
                ))}
              </tbody>
              <tbody>
                <tr className="totalesCalculados">
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td className="tdsubtotal">
                    {props.vSumTotalIm
                      ? Intl.NumberFormat("es-MX", {
                          style: "currency",
                          currency: "MXN",
                        }).format(props.vSumTotalIm)
                      : Intl.NumberFormat("es-MX", {
                          style: "currency",
                          currency: "MXN",
                        }).format(props.vSumTotalIm)}
                  </td>
                  <td className="tdsubtotal">
                    {props.vSumTotalImlocal
                      ? Intl.NumberFormat("es-MX", {
                          style: "currency",
                          currency: "MXN",
                        }).format(props.vSumTotalImlocal)
                      : Intl.NumberFormat("es-MX", {
                          style: "currency",
                          currency: "MXN",
                        }).format(props.vSumTotalImlocal)}
                  </td>
                  <td className="tdsubtotal">
                    {props.vSumTotalSub
                      ? Intl.NumberFormat("es-MX", {
                          style: "currency",
                          currency: "MXN",
                        }).format(props.vSumTotalSub)
                      : Intl.NumberFormat("es-MX", {
                          style: "currency",
                          currency: "MXN",
                        }).format(props.vSumTotalSub)}
                  </td>
                  <td>
                    {props.vSumTotal
                      ? Intl.NumberFormat("es-MX", {
                          style: "currency",
                          currency: "MXN",
                        }).format(props.vSumTotal)
                      : Intl.NumberFormat("es-MX", {
                          style: "currency",
                          currency: "MXN",
                        }).format(props.vSumTotal)}
                  </td>
                  <td className="tdsubtotal"></td>
                </tr>
              </tbody>
            </table>
          </div>
);
};

export default CONCEPT_INVOICE;



