import { useEffect, useState} from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {useDialog} from "react-st-modal";
import {withScriptjs,withGoogleMap,GoogleMap,Marker,} from "react-google-maps";
import { refresh_token  } from "../../main_components/tokens/tokenrefresh";
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import PersonIcon from '@mui/icons-material/Person';
import RoomIcon from '@mui/icons-material/Room';
import FeedIcon from '@mui/icons-material/Feed';
import { get_pointsofsale_address, get_pointsofsale_cabinets, get_pointsofsale_sectors } from "../../services/businesspartners/businesspartners";

function DetailsPointsOfSale(row) {

  const data = row.row.original;
  console.log(data);

const dialog = useDialog();
const [congelador, setcongelador] = useState([]);
const [direc, setdirec] = useState(" ");
const [sectores, setsectores]=useState([]);
const [comentary, setcomentary]=useState(null);
const [value, setValue]=useState(0);
useEffect(()=>{
  handleSubmit();
  handleconge();
  handlesectors();
// eslint-disable-next-line react-hooks/exhaustive-deps
},[]);

//consulta de la direccion de un punto de un punto de venta
const handleSubmit = async () => {
  try {
    var rf_token = await refresh_token();
    if(rf_token===true){
      setTimeout(async() => {
        try{
          var d = await get_pointsofsale_address(data.id); 
          setdirec(d===null ? [] : d.data);  
          comentarios(d === null ? [] : d.data.system_commentary_pos);
        }catch(err){
          console.log(err);
        }
      }, 1000);
    }else{}
  } catch (error) {
    console.log(error);
  }
};
function comentarios(com){
   const newText=com.replace(/\|\|/g,"\n");
   const newText2=newText.replace(/\|/g,"\n");
   const newText3 = newText2.replace("Importación manual.", "Importación manual.\n");
   const parrafos = newText3.split("\n\n");
   parrafos.reverse();
   const invertedtext = parrafos.join("\n\n");
   setcomentary(invertedtext?.trim()) 
}

//consulta de un congelador asignado a un punto de venta 
const handleconge = async () => {
  try {
    var rf_token = await refresh_token();
    if(rf_token===true){
      setTimeout(async() => {
        try{
          var d = await get_pointsofsale_cabinets(data.id); 
          setcongelador(d===null?[]:d);
          console.log(congelador);   
        }catch(err){
          console.log(err);
        }
      }, 1000);
    }else{}
  } catch (error) {
    console.log(error);
  }
};
const handlesectors = async () => {
 // console.log("entra a consultar los sectores a los que pertenece el punto de venta");
  try {
    var rf_token = await refresh_token();
    if(rf_token===true){
      setTimeout(async() => {
        try{
          var d = await get_pointsofsale_sectors(data.id); 
          setsectores(d===null?[]:d);
        }catch(err){
          console.log(err);
        }
      }, 1000);
    }else{}
  } catch (error) {
    console.log(error);
  }
};
//mapa del punto de venta 
const MapWithAMarker = withScriptjs(withGoogleMap(props =>
    <GoogleMap
      defaultZoom={17}
      defaultCenter={{ lat: data.lat, lng: data.lng}}
    >   
   <Marker
        position={{lat: data.lat, lng: data.lng}}
        icon = {{
          fillOpacity: 1,
          rotation: 0,
          strokeWeight: 1,
          strokeColor: "#ffffff",
          scale: 2,   
          animation: window.google.maps.Animation.DROP,
          draggable: true
         }}
    />
  </GoogleMap>
));


const [formper, setformper] = useState(true);
const [formdirec, setformdirec] = useState(false);
const [formextra, setformextra] = useState(false);

const Personales = () =>{
  setformper(true);
  setformdirec(false);
  setformextra(false);
}
const Direccion = () => {
  setformper(false);
  setformdirec(true); 
  setformextra(false); 
}
const extra =()=>{
  setformper(false);
  setformdirec(false);
  setformextra(true);
}


return (
    <div style={{"textAlign":"center"}}>
      <div className="formulariopointofsale">
      <Box>
  <br/>
  <div className="pestañas">
       
  <div className="navegacioncompany">
      <Box sx={{ margin: "0 auto" ,width: 400}}>
      <BottomNavigation
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
      >
        <BottomNavigationAction  label="Información General" icon={<PersonIcon onClick={Personales} />}  onClick={Personales}/>
        <BottomNavigationAction  label="Dirección" icon={<RoomIcon onClick={Direccion}/>} onClick={Direccion}/>
        <BottomNavigationAction  label="Información Extra" icon={<FeedIcon onClick={extra}/>} onClick={extra}/>
        
      </BottomNavigation>
    </Box>
    </div>
      
    </div>
    <br/>
      </Box> 

<div 
 style={formper===true?{"display":"block"}:{"display":"none"}}
>
  <div className="grid01edit">
      <div className="item1">
      <TextField
              label="Código"
              value={data.code===null?" ":data.code}
              sx={{ m: 1.5, width: "100%" }}
          ></TextField>
      </div>
      <div className="item2">
      <TextField
              label="Nombre"
              value={data.name===null?" ":data.name}
              sx={{ m: 1.5, width: "100%" }}    
            ></TextField>
      </div>
      <div className="item3">
      <TextField
              label="Código Unilever"
              value={data.outer_code===null?" ":data.outer_code}
              sx={{ m: 1.5, width: "100%" }}
          ></TextField>
      </div>
  </div>
  <div className="grid02">
    <div className="item4">
    <TextField
            label="Número de tienda"
            value={data.number_oxxo_store}
            sx={{ m: 1.5, width: "100%" }}
            ></TextField>
    </div>
    <div className="item5">
      <TextField
              label="Nota"
              value={data.note===null?" ":data.note}
              sx={{ m: 1.5, width: "100%" }}
      ></TextField>
    </div>
  </div>
  <div className="grid02">
    <div className="item6">
      <TextField
        label="Correo Electrónico"
        value={data.email===null?" ":data.email}
        sx={{ m: 1.5, width: "100%" }}
      ></TextField>
    </div>
    <div className="item7">
      <TextField
        label="Teléfono(s)"
        value={data.phone===null?" ":data.phone}
        sx={{ m: 1.5, width: "100%" }}
      ></TextField>
    </div>           
  </div>
  <div className="grid03">
    <div className="item8">
      <TextField
        label="Genera Deuda"
        value={data.generates_debt===true?"Si(Es Cliente Directo)":"No(Es Cliente Unilever)"}
        sx={{ m: 1.5, width: "100%" }}
      ></TextField>
    </div>
    <div className="item9">
      <TextField
        label="Límite de Crédito"
        value={data.credit_limit===0?0.00:data.credit_limit}
        sx={{ m: 1.5, width: "100%" }}
      ></TextField>
    </div>
    <div className="item10">
      <TextField
        label="Saldo"
        value={data.balance===0?0.00:data.balance}
        sx={{ m: 1.5, width: "100%" }}
      ></TextField>
    </div>
    <div className="item11">
      <TextField
        label="Condición de Pago"
        value={data.conditionPay}
        sx={{ m: 1.5, width: "100%" }}
      ></TextField>
    </div>
  </div>
<div className="grid03edit">
  <div className="item12a">
     <TextField
        label="Socio de Negocios"
        value={data.businessPartner}
        sx={{ m: 1.5, width: "100%" }}
     ></TextField>
  </div>
  <div className="item13">
      <TextField
        label="Días de Visita"
        value={data.visit_days}
        sx={{ m: 1.5, width: "100%" }}
      ></TextField>         
  </div>
  <div className="item14">
     <TextField
       label="Frecuencia de Visita"
       value={data.frequency}
       sx={{ m: 1.5, width: "100%" }}
     ></TextField>
  </div>
</div>
<div className="grid04b">
  <div className="item15">
    <TextField
      label="Lista de Precios"
      value={data.price_list===null?" ":data.price_list}
      sx={{ m: 1.5, width: "100%" }}
    ></TextField>  
  </div>
  <div className="item16">
    <TextField
      label="Folios Especiales"
      value={data.special_number_folio}
      sx={{ m: 1.5, width: "100%" }}
    ></TextField>
  </div>
  <div className="item17">
    <TextField
      label="Canal"
      value={data.channel}
      sx={{ m: 1.5, width: "100%" }}
    ></TextField>        
  </div>
  <div className="item18">
    <TextField
      label="Subcanal"
      value={data.subchannel}
      sx={{ m: 1.5, width: "100%" }}
    ></TextField>
</div>
</div>
<div className="grid05b">

<div className="item19">
  <TextField
  label="Ruta"
  value={data.route}
  sx={{ m: 1.5, width: "100%" }}
  ></TextField>
</div>
<div className="item21b">
  <TextField
  label="Clasificación"
  value={data.classification}
  sx={{ m: 1.5, width: "100%" }}
  ></TextField>
  </div>
</div>    

</div>  
<div
    style={formdirec===true?{"display":"block"}:{"display":"none"}}
>
<div className="gridd01vista">
  <div className="item22">
    <TextField
      label="Latitud"
      value={data.lat===null?" ":data.lat}
      sx={{ m: 1.5, width: "100%" }}
    ></TextField>        
  </div>
  <div className="item23">
    <TextField
      label="Longitud"
      value={data.lng===null?" ":data.lng}
      sx={{ m: 1.5, width: "100%" }}
    ></TextField>
  </div>
  <div className="item24">
    <TextField
      label="Calle"
      value={
       direc===" "?" ":direc?.address?.street
      }
      sx={{ m: 1.5, width: "100%" }}
    ></TextField>
  </div>
</div>
<div className="gridd02">
  <div className="item25">
    <TextField
      label="Número Exterior"
      value={
        direc===" "?" ":direc?.address?.outdoor_number
      }
      sx={{ m: 1.5, width: "100%" }}
    ></TextField>    
  </div>
  <div className="item26">
    <TextField
      label="Número Interior"
      value={
      direc===" "?" ": 
        (direc?.address?.inside_number===null?" ":direc?.address?.inside_number)
      }
      sx={{ m: 1.5, width: "100%" }}
    ></TextField>
  </div>
  <div className="item27">
     <TextField
      label="Colonia"
      value={
        direc===" "?" ":direc?.address?.neighborhood
      }
      sx={{ m: 1.5, width: "100%" }}
      ></TextField>
  </div>
  <div className="item28">
    <TextField
      label="Localidad"
      value={
        direc===" "?" ":direc?.address?.town
      }
      sx={{ m: 1.5, width: "100%" }}
    ></TextField>
  </div>
</div>
<div className="gridd03">
  <div className="item29">
    <TextField
      label="Municipio"
      value={
        direc===" "?" ":direc?.address?.city
      }
      sx={{ m: 1.5, width: "100%" }}
    ></TextField>
  </div>
  <div className="item30">
    <TextField
      label="Código Postal"
      value={
        direc===" "?" ":direc?.address?.zip_code
      }
      sx={{ m: 1.5, width: "100%" }}
    ></TextField>
  </div>
  <div className="item31">
    <TextField
      label="Estado"
      value={
        direc===" "?" ":direc?.address?.state
      }
      sx={{ m: 1.5, width: "100%" }}
    ></TextField>     
  </div>
  <div className="item32">
    <TextField
      label="País"
      value={
        direc===" "?" ":direc?.address?.country
      }
      sx={{ m: 1.5, width: "100%" }}
    ></TextField>
  </div>
</div>
<div className="gridd04">
<div className="item33">
<TextField
  label="Referencia"
  value={
    direc===" "?" ":
    (direc?.address?.reference===null?" ":direc?.address?.reference)
  }
  sx={{ m: 1.5, width: "100%" }}
></TextField>
</div>
<div className="item34">
  <TextField
    label="Sucursal"
    value={
      direc===" "?" ":direc?.address?.subsidiary
    }
    sx={{ m: 1.5, width: "100%" }}
  ></TextField> 
</div>
</div>
<div className="gridd04">

</div>
<Box>
        <div style={{"padding-left":"5%"}}>
        <MapWithAMarker                                             
       googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBTswKLvFOwWbnlFqwQMqpUgUEL3LQu7ls&v=3.exp&libraries=geometry,drawing,places"
       loadingElement={<div style={{ height: `100%` }} />}
       containerElement={<div style={{height: `300px`, width: "95%"}} />}
       mapElement={<div style={{ height: `100%` }} />}
         />
        </div>
     <br/>
       </Box>
</div>
<div 
  style={formextra===true?{"display":"block"}:{"display":"none"}}>

<div>
    {
    sectores?.data?.sectors==null?<h1 className="h1conge">Punto de venta sin sector</h1>
    :
    <div>
    <h1 className="h1conge">Sector(s) que pertenece el punto de venta: </h1>
    <table className="Tablesectorespos">
      <thead>
        <tr>
          <th>Número de Sector</th>
          <th>Nombre del Sector</th>
          <th>Orden de Sector</th>
          <th>Ruta</th>    
        </tr>
      </thead>
      <tbody>
      { 
      sectores.data.sectors.map( item=>(
        <tr>
        <td>{item.number_sector}</td>
        <td>{item.name_sector}</td>
        <td>{item.orderSector}</td>
        <td>{item.route}</td>
      </tr>
      ))
      }
    </tbody>
    </table>
    </div>
    }
  </div>
<div>
    {
         congelador?.data?.cabinets==null?<h1 className="h1conge">Sin congelador asignado</h1>
         :
         <div>
         <h1 className="h1conge">Congelador asignado: </h1>
         <br/>
         <table className="Tablecongelador">
           <thead>
             <tr>
               <th>Marca</th>
               <th>Modelo</th>
               <th>Económico</th>
               <th>Serie</th> 
               <th>Activo</th>   
             </tr>
           </thead>
           <tbody>
           { 
           congelador.data.cabinets.map( item=>(
             <tr>
             <td>{item.brand}</td>
             <td>{item.model}</td>
             <td>{item.economic_number}</td>
             <td>{item.serial_number}</td>
             <td>{item.fixed_asset} </td>
           </tr>
           ))
          }
         </tbody>
        </table>
        </div>
         }
       </div>
       <div>
       <h1 className="h1conge">Historial de actualizaciones: </h1>
         
        <textarea className="textareapuntosventa" value={comentary}>
        </textarea>
       </div>
</div>
        
          <br></br>
          <br></br>
          <Box>
            <Button
              style={{"backgroundColor":"red"}}
              variant="contained"
              sx={{ m: 1.5, width: "20ch" }}
              onClick={() => {
                  dialog.close();
                } 
              }
            >
            cerrar
          </Button>
          </Box>
      </div>
    </div>
  );
}
export default DetailsPointsOfSale;
