import { CustomDialog } from "react-st-modal";
import { ListItemIcon, MenuItem } from '@mui/material';
import Edit from "../../../../imgComponents/save.png";
import SHOW_EDIT_FREZZER from "./show_edit_freezer";
import { SHOW_PUT_CABINETS } from "./show_put_cabinets";

const RENDER_ROW_ACTION_MENU_ITEMS = (props) => {
    
    return (
        <div>
                <MenuItem
                    key={1}
                    onClick={ async () =>{
                    props.closeMenu();
                    await CustomDialog(
                        props?.method === 1 ?
                        <SHOW_EDIT_FREZZER
                            id={props?.row?.index}
                            row={props?.row}
                            file_cabinets={props?.file_cabinets}
                            setfile_cabinets={props?.setfile_cabinets}
                            file_cabinets_error={props?.file_cabinets_error}
                            setfile_cabinets_error={props?.setfile_cabinets_error}
                            databrand = {props?.databrand}
                            datamodels = {props?.datamodels}  
                            process={props?.process}
                            update_data={props?.update_data}
                            setmodalT={props?.setmodalT}
                            alertas={props?.alertas}
                            update_data_error={props?.update_data_error}
                        ></SHOW_EDIT_FREZZER>:
                        <SHOW_PUT_CABINETS
                            file_cabinets={props?.file_cabinets}
                            setfile_cabinets={props?.setfile_cabinets}
                            id={props?.row?.index}
                            row={props?.row}
                            databrand = {props?.databrand}
                            datamodels = {props?.datamodels}
                            process= {props?.process}
                            setmodalT={props?.setmodalT}
                            alertas={props?.alertas}
                            data={props?.data}
                            update_data={props?.update_data}
                            file_cabinets_error={props?.file_cabinets_error}
                            setfile_cabinets_error={props?.setfile_cabinets_error}
                            update_data_error={props?.update_data_error}
                           
                        >
                        </SHOW_PUT_CABINETS>
                    ,
                   {
                       className: "custom-modal-3",
                       title: "REGISTRAR LOTE DE CONGELADORES",
                       showCloseIcon: true,
                   }
                   );
                   } 
                }
                sx={{ m: 0 }}
                >
                <ListItemIcon>
                   <img 
                      alt="" 
                      src={Edit} 
                      title="Editar Congelador" 
                      className=""
                   ></img>
                </ListItemIcon>
                Editar Congelador
                </MenuItem>
        </div >
    );
};






export default RENDER_ROW_ACTION_MENU_ITEMS;
