import { useEffect, useState, useMemo, useRef } from "react";
import { MaterialReactTable, MRT_ColumnDef } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import AlertResponse from "../../main_components/alerts/alertResponse";
import { pagination } from "../../main_components/pagination/pagination";
import CREATE from "../../imgComponents/create.png";
import { update_null_identifiers } from "../../main_components/methods_v2/auxiliar_funtion";
import MATERIAL_TABLE_CONTAINER from "../../main_components/material_table/table_container";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import { create_object, processed_format, updated_form_data, validate_data } from "./tools/auxiliar_funtions";
import GENERAL_FORM from "../../main_components/material_table/general_form";
import { POINTS_OF_SALE, RESPONSE } from "./interface/interface";
import { UPDATE_NULL_IDENTIFIERS } from "../../main_components/methods_v2/auxiliary_functions_new_method";
import { refresh_token } from "../../main_components/tokens/tokenrefreshNewMethod";
import { get_requirements } from "../../services/businesspartners/businesspartners";
import { inf_points } from "./tools/inf_points";

export interface FormData {
  code: string;
  outerCode: string,
  special_number_folio: string,
  name: string,
  businessPartner: string,
  rfc: string,
  channel: string,
  subchannel: string, 
  route: string,
  sectors: string,
  conditionPay: string,
  generates_debt: boolean,
  frequency: string,
  date_register: string,
  visit_days: string,
  price_list: string,
  classification: string,
  number_oxxo_store: string,
  lat: Number,
  lng: Number,
  email: string,
  phone: string,
  street: string,
  outdoor_number: string,
  inside_number: string,
  neighborhood: string,
  zip_code: string,
  city: string,
  town: string,
  state: string,
  country: string,
  reference: string,
  address_type: string,
  count_unilever_cabinets: Number,
  unilever_cabinets: string,
  count_own_cabinets: Number,
  own_cabinets: string
}

const POINTS_OF_SALE_V2_TS: React.FC = () => {
  
  const [data, setData] = useState<POINTS_OF_SALE[]>([]);
  const [modalT, setModalT] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [methodDelete, setMethodDelete] = useState(false);
  const [identifier_data, setidentifier_data] = useState<any>(null);
  const [open_form, setopen_form] = useState(false);
  const [formData, setformData] = useState([]);
  const [loadingSkeleton, setLoadingSkeleton] = useState<boolean>(false);
 const [isNewEntry, setIsNewEntry] = useState(false);

  const update_identifiers = [
    { code: 'string' },
    { outer_code: 'string' },
    { special_number_folio: 'string' },
    { name: 'string' },
    { businessPartner: 'string' },
    { rfc: 'string' },
    { channel: 'string' },
    { subchannel: 'string' },
    { route: 'string' },
    { sectors: 'string' },
    { conditionPay: 'string' },
    { generates_debt: 'string' },
    { frequency: 'string' },
    { date_register: 'string' },
    { visit_days: 'string' },
    { price_list: 'string' },
    { classification: 'string' },
    { number_oxxo_store: 'string' },
    { note: 'string'},
    { lat: 'number' },
    { lng: 'number' },
    { email: 'string' },
    { phone: 'string' },
    { street: 'string' },
    { outdoor_number: 'string' },
    { inside_number: 'string' },
    { neighborhood: 'string' },
    { zip_code: 'string' },
    { city: 'string' },
    { town: 'string' },
    { state: 'string' },
    { country: 'string' },
    { reference: 'string' },
    { address_type: 'string' },
    { count_unilever_cabinets: 'number' },
    { unilever_cabinets: 'string' },
    { count_own_cabinets: 'number' },
    { own_cabinets: 'string' },
  ];

  const verifyToken = async (): Promise<boolean> => {
    try {
      return await refresh_token();
    } catch (error) {
      console.error("Error al verificar el token:", error);
      return false;
    }
  };
  const handleClose = () => { setopen_form(false); };

  const fetchBussinesPartner = async (): Promise<POINTS_OF_SALE[]> => {
      try {
        const rawResponse = await pagination(3, "pointofsales", null);
  
        const response: RESPONSE = Array.isArray(rawResponse)
          ? { data: rawResponse }
          : rawResponse;
  
        return UPDATE_NULL_IDENTIFIERS(
          response.data,
          update_identifiers
        ) as POINTS_OF_SALE[];
      } catch (error) {
        console.error("Error al obtener los datos de paginación:", error);
        return [];
      }
    };

  const handle_Points_Of_Sale = async (event?: React.SyntheticEvent): Promise<void>=>{
    event?.preventDefault();
    setIsLoading(true);
    setModalT(true);
    setData([]);
    const isTokenValid = await verifyToken();
    if (!isTokenValid) {
      setIsLoading(true);
      setModalT(true);
      console.error("Token inválido o expirado. Deteniendo el proceso...");
      console.log("Actualizando token...");
      setTimeout(() => {
        handle_Points_Of_Sale();
      }, 1000);
      return;
    }
    const data = await fetchBussinesPartner();
    setData(data);
    setIsLoading(false);
    setModalT(false);
  }

  const columns: MRT_ColumnDef<POINTS_OF_SALE>[] = useMemo(
    () => [
      {
        id: "AC.",
        header: "AC.",
        size: 55,
        enableFilters: false,
        enableColumnFilters: false,
        enableColumnFiltering: false,
        enableEditing: false,
        enableColumnFilterModes: false,
        enableRowOrdering: false,
        enableGrouping: false,
        enableColumnDragging: false,
        enableColumnActions: false,
        accessorFn: (d) => {
          return (
            <div>
              <span
                onClick={() => {
                  console.log(d)
                  setMethodDelete(true);
                  handleOpenForm(d, false);
                  setidentifier_data(d.id);
                }}
              >
                <SaveAsIcon className="icons_general_save"></SaveAsIcon>
              </span>
              <span
                onClick={() => {
                  setMethodDelete(true);
                  setopen_form(true);
                  setidentifier_data(d?.id);
                }}
              >
                <DeleteIcon className="icons_general_delete"></DeleteIcon>
              </span>
              {/*  */}
            </div>
          );
        },
        muiTableHeaderCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
      },
      {
        header: "Código",
        accessorKey: "code",
        id: "code",
        muiTableBodyCellProps:{
          align: "center"
        }
      },
      {
        header: "Código Unilever",
        accessorKey: "outer_code",
        id: "outer_code",
        muiTableBodyCellProps:{
          align: "center"
        }
      },
      {
        header: "Folio Especial",
        accessorKey: "special_number_folio",
        id: "special_number_folio"
      },
      {
        header: "Nombre",
        accessorKey: "name",
        id: "name",
      },
      {
        header: "Socio de Negocios",
        accessorKey: "businessPartner",
        id: "businessPartner",
      },
      {
        header: "RFC Socio Negocios",
        accessorKey: "rfc",
        id: "rfc",
      },
      {
        header: "Canal",
        accessorKey: "channel",
        id: "channel",
        filterVariant: 'select',
      },
      {
        header: "Subcanal",
        accessorKey: "subchannel",
        id: "subchannel",
        filterVariant: 'select',
      },
      {
        filterVariant: 'select',
        header: "Ruta",
        accessorKey: "route",
        id: "route",
      },
      {
        header: "Sectores",
        accessorKey: "sectors",
        id: "sectors",
      },
      {
        filterVariant: 'select',
        header: "Pago",
        accessorKey: "conditionPay",
        id: "conditionPay",
      },
      {
        header: "Deuda",
        accessorKey: "generates_debt",
        Cell: ({ cell }) => {
          return cell.getValue() === true ? "SI": "NO"
        },
        id: "generates_debt",
      },
      {
        filterVariant: 'select',
        header: "Frecuencia",
        accessorKey: "frequency",
        id: "frequency",
      },
      {
        header: "Alta",
        accessorKey: "date_register",
        id: "date_register",
      },

      {
        header: "Días de visita",
        accessorKey: "visit_days",
        id: "visit_days",
      },
      {
        header: "Lista de precios",
        accessorKey: "price_list",
        id: "price_list",
      },
      {
        header: "Clasificación",
        accessorKey: "classification",
        id: "classification",
      },
      {
        header: "Número de Tienda",
        accessorKey: "number_oxxo_store",
        id: "number_oxxo_store",
      },
      {
        header: "Latitud",
        accessorKey: "lat",
        id: "lat",
      },
      {
        header: "Longitud",
        accessorKey: "lng",
        id: "lng",
      },
      {
        header: "Correo Electrónico",
        accessorKey: "email",
        id: "email",
      },
      {
        header: "Número Telefónico",
        accessorKey: "phone",
        id: "phone",
      },
      // Agregar más columnas según sea necesario
    ],
    []
  );

  const handleOpenForm = (new_value: any, isNew: boolean) => {
    if (isNew) {
      setLoadingSkeleton(true)
      setopen_form(true);
      setMethodDelete(false);
      handleAddCode(new_value, isNew);
    } else {
      setopen_form(true);
      setLoadingSkeleton(true)
      setMethodDelete(false);
      // handleAddAddresPartner(new_value, isNew);
    }
  };

    const fetchCodePointofSale = async () => {
      try {
        const response = await get_requirements();
        return response.data.code;
      } catch (error) {
        console.error("Error al obtener los requerimentos del punto de venta:", error);
        return [];
      }
    };

  const handleAddCode = async (originalObj: any, isNew: boolean) => {
    try {
      const isTokenValid = await verifyToken();

      if (!isTokenValid) {
        console.error("Token inválido o expirado. Deteniendo el proceso...");
        console.log("Actualizando token...");
        setTimeout(() => {
          handleAddCode(originalObj, isNew);
        }, 1000);
        return;
      }
      
      const code = await fetchCodePointofSale();
      if (code.length === 0) {
        console.error("No se pudo obtener requires");
        setLoadingSkeleton(true)
        return;
      }

      originalObj.code = code;

      setformData(originalObj)
      setIsNewEntry(isNew);
      setLoadingSkeleton(false)
      setMethodDelete(false);

    } catch (error) {
      console.error("Error al manejar points requires:", error);
      return null;
    }
  };


  const handleSaveData = async (
    finalObject: any,
    method: any,
    identifier_data: any
  ) => {
    console.log("");
  }

  useEffect(() => {
    handle_Points_Of_Sale();
  }, []);

  return (
    <div style={{ width: "100%", margin: "0 auto" }} className="new_table_v2">
      
       <GENERAL_FORM
          open={open_form}
          onClose={handleClose}
          data_form={inf_points}
          onSave={handleSaveData}
          isNew={isNewEntry}
          title_create="CREAR PUNTO DE VENTA"
          title_update="ACTUALIZAR PUNTO DE VENTA"
          validateField={validate_data}
          handleProcessedFormat={processed_format}
          FormData={FormData}
          original_data={formData}
          size={methodDelete ? "30%" : "85%"}
          method_delete={methodDelete}
          message_delete="¿Esta seguro de eliminar este punto de venta?"
          updatedFormData={updated_form_data}
          identifier_data={identifier_data}
          loadingSkeleton={loadingSkeleton}
      /> 

      <MATERIAL_TABLE_CONTAINER
        columns={columns}
        data={data}
        breadcrumbs={[{ label: "PUNTOS DE VENTA", path: null }]}
        modalT={modalT}
        isLoading={isLoading}
        search_filters={<div></div>
        }
        component_header={
          <img
            style={{ cursor: "pointer" }}
            src={CREATE}
            onClick={() => handleOpenForm(create_object, true)}
          ></img>
        }
        file_name_export="PUNTOS DE VENTA"
        update_identifiers={update_identifiers}
      ></MATERIAL_TABLE_CONTAINER>
    </div>
  );
};

export default POINTS_OF_SALE_V2_TS;
