import { useEffect, useMemo, useRef, useState } from 'react'
import { get_cabinets_brands, get_cabinets_models, post_cabinets, put_cabinets } from '../../../../services/assets/assets';
import "./upload_file_frezz.css";
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import RENDER_ROW_ACTION_MENU_ITEMS from './render_row_action_upload';
import loadimg from "../../../../imgComponents/iconos/loading.gif";
import { useDialog } from 'react-st-modal';
import { updateSheet, updateSheetmethodput } from './spreedsheet';
import { headers_check, headers_check_put } from './method_update';
import { getcolumns, getcolumns_error, getcolumns_put, getcolumns_puterror } from './columns';
import { select_method, upload_file_div } from './plantillas';
import RENDER_TOOLBAR_INTERNAL_ACTIONS from '../../../../main_components/methods_v2/export';


export const UPLOAD_FILE_CABINETS = (props) => {

  const [file_cabinets, setfile_cabinets] = useState([]);
  const [file_cabinets_error, setfile_cabinets_error] = useState([]);
  const dialog = useDialog();
  const [highlight, setHighlight] = useState(false);
  const fileInputRef = useRef(null);
  const [file, setfile]=useState(null);
  const [errmsjf, seterrmsjf]=useState("");
  const [errfile, seterrfile] = useState(false);
  const [total_rows, settotal_rows] = useState("");
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [databrand, setdatabrand] = useState([]);
  const [datamodels, setdatamodels] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [rowSelection, setRowSelection] = useState({});
  const [method, setmethod] = useState(0);

  const [process_num, setprocess_num] = useState(1);
  const [progr, setProgr] = useState(0);
  var progress = 0;
  const [msj, setmsj] = useState("");
  const[dowp, setdowp] = useState(false);

  const columns = useMemo(()=>getcolumns(), []);
  const columns2 = useMemo(() => getcolumns_error(), []);
  const columns_cabinets_update = useMemo(()=>getcolumns_put(), []);
  const columns_cabinets_error = useMemo(()=>getcolumns_puterror(), []);

  const CLIENT_ID = '301722987834-pec5a9vlojn6redpcneblrgd2ckqdj05.apps.googleusercontent.com';
  const API_KEY = 'AIzaSyBTswKLvFOwWbnlFqwQMqpUgUEL3LQu7ls';
  const SCOPES = 'https://www.googleapis.com/auth/spreadsheets';
  
  useEffect(() => {
    handlesubmit();
  }, [])

  useEffect(() => {
    let script;

   if(dowp === true){
    const script = document.createElement('script');
    script.src = 'https://apis.google.com/js/api.js';
    script.onload = () => {
      window.gapi.load('client:auth2', initClient);
    };
    document.body.appendChild(script);
   }else{
      const existingScript = document.querySelector('script[src="https://apis.google.com/js/api.js"]');
      if (existingScript) {
        existingScript.remove();
      }
      if (window.gapi && window.gapi.auth2) {
        window.gapi.auth2.getAuthInstance()?.signOut();
        console.log("Google API desactivado y sesión cerrada.");
      }
    }
    return () => {
      if (script && document.body.contains(script)) {
        script.remove();
      }
    }
  }, [dowp]);


  const initClient = () => {
    window.gapi.client.init({
      apiKey: API_KEY,
      clientId: CLIENT_ID,
      discoveryDocs: ["https://sheets.googleapis.com/$discovery/rest?version=v4"],
      scope: SCOPES,
    }).then(() => {
      window.gapi.auth2.getAuthInstance().isSignedIn.listen(setIsSignedIn);
      setIsSignedIn(window.gapi.auth2.getAuthInstance().isSignedIn.get());
    });
  };
  const handleAuthClick = () => {
    window.gapi.auth2.getAuthInstance().signIn({ prompt: 'select_account' }).then(() => {
      handleCreateSheet();
    });
  };

  const handleCreateSheet = () => {
    window.gapi.client.sheets.spreadsheets.create({
      properties: {
        title: "Plantilla_Congeladores"
      }
    }).then((response) => {
      console.log('Hoja de Cálculo Creada', response.result);
      const newSheetName = "Sheet"; 
      renameFirstSheet(response.result.spreadsheetId, newSheetName,response.result.spreadsheetUrl);
    }).catch((error) => {
      alert("No se logro crear la hoja de calculo comuniquese con soporte")
      console.error('Error al crear la hoja de cálculo', error);
      setdowp(false);
    });
  };
  const renameFirstSheet = (spreadsheetId, newSheetName,urlSheet) => {
    window.gapi.client.sheets.spreadsheets.batchUpdate({
      spreadsheetId: spreadsheetId,
      requests: [{
        updateSheetProperties: {
          properties: {
            sheetId: 0,
            title: newSheetName
          },
          fields: 'title'
        }
      }]
    }).then((response) => {
      console.log('Nombre de la hoja cambiado', response.result);
      method === 1 || method === "1" ? 
        updateSheet(spreadsheetId, urlSheet, databrand, datamodels, setIsSignedIn, setdowp): 
        updateSheetmethodput(spreadsheetId, urlSheet, setIsSignedIn, setdowp, datamodels);
    }).catch((error) => {
      setdowp(false);
    });
  };  

  const handlesubmit = async() =>{

    try{
      props?.setmodalT(true);
      var d = await get_cabinets_models();
      var d2 = await get_cabinets_brands();
      setdatamodels(d === null ? [] : d.data.models);
      setdatabrand(d === null ? [] : d2.data.brands);
      props?.setmodalT(false);
    }catch(err){
      props?.setmodalT(false);
      console.log(err);
    }
  }
  

const handleFiles = (files) => {
  seterrmsjf("");
  for (const file of files) {
    if (file.type === 'text/csv') {
      const reader = new FileReader();
      reader.onload = (e) => {
        const contents = e.target.result;
        analisis(contents);
      };
      reader.readAsText(file);
    } else {
      alert('Por favor, selecciona un archivo CSV.');
      setfile(null);
    }
  } 
};

const analisis = (content) =>{
    const lines = content.trim().split('\n');
    const headers = lines[0].split(',');
    const result = [];
    for (let i = 1; i < lines.length; i++) {
        const obj = {};
        const currentLine = lines[i].split(',');
        headers.forEach((header, index) => {
            obj[header.trim().replace(/"/g, '')] = currentLine[index].trim().replace(/"/g, '');
        });
        result.push(obj);
    }
    
    method===1 || method === "1" ?
      headers_check(result, seterrmsjf, seterrfile, databrand, datamodels, setfile_cabinets, setprocess_num):
        headers_check_put(result, seterrmsjf, seterrfile, datamodels, setfile_cabinets, setprocess_num, props?.data)
}  

const handleStateChange = (newState) => {
  const filteredRowsCount = newState.filteredRowModel
  settotal_rows(filteredRowsCount);
};

const update_data = (dat) =>{
  setfile_cabinets([...dat]);
}

const update_data_error = (dat) =>{
  setfile_cabinets_error([...dat]);
}

const upload_lote = async() =>{
    const ef = file_cabinets.filter(item=>item?.brand_validate === false || item?.model_validate === false)
    if(ef.length > 0){
      const numerosEconomicos = ef.map(item => item['Numero economico']).join(', ');
      props?.alertas(`Verifica la información de los congeladores con número económico ${numerosEconomicos}`, false);
      return false;
    }
    setprocess_num(3);      
    try{
      const invoiceData = [];
      for (const item of file_cabinets) {
        const data = await processQueueItem(item);
        invoiceData.push(data);
      }
      console.log(invoiceData);
      setprocess_num(4);
      setfile_cabinets_error(invoiceData);
    }catch(err){
      console.log(err);
      setprocess_num(2);
    }
}

const processQueueItem = async(item)=>{
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0');
  const day = String(today.getDate()).padStart(2, '0');
  const formattedDate = `${year}-${month}-${day}`;
  var data = {
    "economic_number": item['Numero economico'],
    "fixed_asset": item['Numero activo fijo'],
    "serial_number": item['Numero serie'],
    "capitalization_date": formattedDate,
    "note": item['Nota'],
    "id_warehouse": 8,
    "id_cabinets_state": 3,   
    "id_cabinets_model": item['id_model'],
    "lending": item["Comodato"] === "SI" || item["Comodato"] === true ? true : false,   
    "production_year": item['Año']
  }
  progress = progress + 1 / file_cabinets?.length * 100;
  console.log(progress)
  setProgr(progress);
  setmsj("Registrando Congelador con Número Económico " + item['Numero economico']);
  try {
    await post_cabinets(data);
    item['status_register'] = true;
    item['response_error_register'] = "";
    return item;
  }
  catch(err){
    console.log(err);
    if(err?.response?.data?.errorMessage){
      item['response_error_register'] = 
        err?.response?.data?.errorMessage == "A cabinet already exists with the same serial number." ? 
          "Ya existe un congelador con el mismo número de serie.":
          "Verifica los datos del congelador.";
    }else{
      item['response_error_register'] = "Error desconocido comuníquese con soporte.";
    }
    item['status_register'] = false;
    return item;
  }
}
const put_lote = async() =>{  
  const ef = file_cabinets.filter(item=>item?.num_econ_validate === false || item?.model_validate === false)
  if(ef.length > 0){
    const numerosEconomicos = ef.map(item => item['Numero economico']).join(', ');
    props?.alertas(`Verifica la información de los congeladores con número económico ${numerosEconomicos}`, false);
    return false;
  }
  setprocess_num(3);      
  try{
    const invoiceData = [];
    for (const item of file_cabinets) {
      const data = await processQueueItemPut(item);
      invoiceData.push(data);
    }
    console.log(invoiceData);
    setprocess_num(4);
    setfile_cabinets_error(invoiceData);
  }catch(err){
    console.log(err);
    setprocess_num(2);
  }

}
const processQueueItemPut = async(item)=>{    

    console.log(item);

    var data = {
      "economic_number": item['Numero economico'],
      "serial_number": item['serial_number'],
      "id_cabinets_model": item['id_model'],
      "production_year": item['Año']
    }
    progress = progress + 1 / file_cabinets?.length * 100;
    console.log(progress)
    setProgr(progress);
    setmsj("Actualizando Congelador con Número Económico " + item['Numero economico']);
    try {
      await put_cabinets(item?.id, data);
      item['status_register'] = true;
      item['response_error_register'] = "";
      return item;
    }
    catch(err){
      console.log(err);
      if(err?.response?.data?.errorMessage){
        item['response_error_register'] = "Verifica los datos del congelador.";
      }else{
        item['response_error_register'] = "Error desconocido comuníquese con soporte.";
      }
      item['status_register'] = false;
      return item;
    }
}

useEffect(() => {
  seterrmsjf("");
}, [method])


const update_identifiers =
  [
    { register: 'string' },
    { economic_number: 'string' },
    { status: 'String' }
  ];


return (
    <div className='content-upload-file-csv-frezzer'>
      <div className=''>
        {
          process_num === 1?
          <div className='file-upload-container'>
            {
              select_method(method, setmethod)
            }
            {
              method === 0 || method === "0" ?
              <></>:
              upload_file_div(
                handleFiles, 
                setfile, 
                setdowp,
                fileInputRef,
                setHighlight,
                highlight,
                handleAuthClick,
                errmsjf
              )
            }
          </div>:
          process_num === 2 ?
          <div className='register-new-cabinets'>
            <div className='rnc-info'>
              <h2>{method===1 || method==="1" ? "Congeladores a Registrar": "Congeladores a Actualizar"}</h2>
              <div className='rnc-grid-button'>
                <button onClick={
                  ()=>{
                    method===1 || method === "1" ?
                      upload_lote(): 
                        method===2 || method === "2" ? 
                          put_lote(): 
                            props?.alertas("Error al iniciar el proceso")}}
                  >Empezar Proceso
                  </button>
                <button onClick={()=>{dialog.close()}} style={{backgroundColor: "red"}}>Cancelar</button>    
              </div>
            </div>
            <div className='new_table_v2 table-add-cabinets-file-csv-upload'>
              <MaterialReactTable
                    columns={method === 1 || method === "1" ? columns: columns_cabinets_update}
                    data={file_cabinets}
                    enableGrouping
                    enableFacetedValues
                    enableStickyHeader
                    onStateChange={handleStateChange}
                    enableStickyFooter
                    enableColumnFilterModes
                    enableRowActions
                    getRowId={(row) => row?.id}
                    onRowSelectionChange={setRowSelection}
                    enableTooltips={false}
                    localization={MRT_Localization_ES}
                    state={{ rowSelection, isLoading: isLoading }}
                    enableColumnResizing
                    enableColumnPinning
                    enableColumnOrdering
                    enableExpandAll={false}
                    enableColumnDragging={false}
                    initialState={{ showGlobalFilter: true, showColumnFilters: true }}
                    muiCircularProgressProps={{ color: 'secondary', thickness: 5, size: 55 }}
                    muiSkeletonProps={{ animation: 'pulse', height: 28 }}
                    rowVirtualizerInstanceRef
                    rowVirtualizerOptions={{ overscan: 5 }}
                    columnVirtualizerOptions={{ overscan: 2 }}
                    muiTableContainerProps={{ sx: { maxHeight: '69vh', '@media (max-height: 810px)': { maxHeight: '65vh' } } }}
                    positionToolbarAlertBanner="bottom"
                    paginationDisplayMode='pages'
                    rowPinningDisplayMode='sticky'
                    layoutMode="grid"
                    muiTableHeadCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}

                    muiTableBodyCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
                    muiPaginationProps={{ color: 'primary', shape: 'rounded', variant: 'variant', }}
                    displayColumnDefOptions={{
                      'mrt-row-pin': {
                        enableHiding: true,
                      },
                      'mrt-row-actions': {
                        enableHiding: true,
                        size: 80
                      },
                      'mrt-row-expand': {
                        enableHiding: true,
                      },
                      'mrt-row-select': {
                        enableHiding: true,
                      }
                    }}
                    muiTableBodyRowDragHandleProps={({ table }) => ({
                      onDragEnd: () => {
                        const { draggingRow, hoveredRow } = table.getState();
                        if (hoveredRow && draggingRow) {
                          file_cabinets.splice(
                            hoveredRow.index,
                            0,
                            file_cabinets.splice(draggingRow.index, 1)[0],
                          );
                          setfile_cabinets([...file_cabinets]);
                        }
                      },
                    })}
                    muiTableBodyRowProps={
                      ({ row, table }) => {
                        settotal_rows(table);
                        const { density } = table.getState();
                        return {
                          sx: {
                            height: row.getIsPinned()
                              ? `${density === 'compact' ? 30 : density === 'comfortable' ? 35 : 69
                              }px`
                              : undefined,
                          },
                        };
                      }
                    }
                    renderRowActionMenuItems={({ row, closeMenu }) => [
                      <RENDER_ROW_ACTION_MENU_ITEMS
                        closeMenu={closeMenu}
                        row={row}
                        file_cabinets={file_cabinets}
                        setfile_cabinets={setfile_cabinets}
                        update_data={update_data}
                        databrand = {databrand}
                        datamodels = {datamodels}  
                        process={1}
                        method={method === 1 || method === "1" ? 1 : 2}
                        setmodalT={props?.setmodalT}
                        alertas={props?.alertas}
                        data={props?.data}
                      />
                    ]}
                    />
            </div>
          </div>:
          process_num === 3 ?
          <div className='process-loadbar-lote-register'>
            <div className='process-content-bar-csv'>
              <div className='cargando-facturas'>
                <div className='loading-zip'>
                    <img src={loadimg} alt=''></img>
                    <p className='msj-zip'>{msj}</p>
                </div>
                <div className='load-progress'>
                    <div className='progress-bar' style={{ width: `${progr}%` }}></div>
                </div>
              </div>
            </div>
          </div>:
          process_num === 4 ?
          <div className='progress-finaly-new_table_upload_csv'>
            <div className='success-register-cabinets'>
              <h2 style={{fontSize: "20px"}}>Congeladores Registrados: {
                file_cabinets_error.filter(item=>item?.status_register === true)?.length
            } / {file_cabinets_error?.length}</h2>
              <div className='new_table_v2'>
              <MaterialReactTable
                columns={method === 1 || method==="1" ? columns2 : columns_cabinets_error }
                data={file_cabinets_error}
                enableGrouping
                enableFacetedValues
                enableStickyHeader
                onStateChange={handleStateChange}
                enableStickyFooter
                enableColumnFilterModes
                enableRowActions
                getRowId={(row) => row?.id}
                onRowSelectionChange={setRowSelection}
                enableTooltips={false}
                localization={MRT_Localization_ES}
                state={{ rowSelection, isLoading: isLoading }}
                enableColumnResizing
                enableColumnPinning
                enableColumnOrdering
                enableExpandAll={false}
                enableColumnDragging={false}
                initialState={{ showGlobalFilter: true, showColumnFilters: true }}
                muiCircularProgressProps={{ color: 'secondary', thickness: 5, size: 55 }}
                muiSkeletonProps={{ animation: 'pulse', height: 28 }}
                rowVirtualizerInstanceRef
                rowVirtualizerOptions={{ overscan: 5 }}
                columnVirtualizerOptions={{ overscan: 2 }}
                muiTableContainerProps={{ sx: { maxHeight: '69vh', '@media (max-height: 810px)': { maxHeight: '65vh' } } }}
                positionToolbarAlertBanner="bottom"
                paginationDisplayMode='pages'
                rowPinningDisplayMode='sticky'
                layoutMode="grid"
                muiTableHeadCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}

                muiTableBodyCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
                muiPaginationProps={{ color: 'primary', shape: 'rounded', variant: 'variant', }}
                displayColumnDefOptions={{
                  'mrt-row-pin': {
                    enableHiding: true,
                  },
                  'mrt-row-actions': {
                    enableHiding: true,
                    size: 80
                  },
                  'mrt-row-expand': {
                    enableHiding: true,
                  },
                  'mrt-row-select': {
                    enableHiding: true,
                  }
                }}
                muiTableBodyRowDragHandleProps={({ table }) => ({
                  onDragEnd: () => {
                    const { draggingRow, hoveredRow } = table.getState();
                    if (hoveredRow && draggingRow) {
                      file_cabinets.splice(
                        hoveredRow.index,
                        0,
                        file_cabinets.splice(draggingRow.index, 1)[0],
                      );
                      setfile_cabinets([...file_cabinets]);
                    }
                  },
                })}
                muiTableBodyRowProps={
                  ({ row, table }) => {
                    settotal_rows(table);
                    const { density } = table.getState();
                    return {
                      sx: {
                        height: row.getIsPinned()
                          ? `${density === 'compact' ? 30 : density === 'comfortable' ? 35 : 69
                          }px`
                          : undefined,
                      },
                    };
                  }
                }
                renderRowActionMenuItems={({ row, closeMenu }) => [
                  <RENDER_ROW_ACTION_MENU_ITEMS
                    closeMenu={closeMenu}
                    row={row}
                    file_cabinets={file_cabinets}
                    setfile_cabinets={setfile_cabinets}
                    file_cabinets_error={file_cabinets_error}
                    setfile_cabinets_error={setfile_cabinets_error}
                    update_data={update_data}
                    databrand = {databrand}
                    datamodels = {datamodels}  
                    process={2}
                    method={method === 1 || method === "1" ? 1 : 2}
                    setmodalT={props?.setmodalT}
                    alertas={props?.alertas}
                    update_data_error={update_data_error}
                  />
                ]}
                renderToolbarInternalActions={({ table, index }) => (
                  <RENDER_TOOLBAR_INTERNAL_ACTIONS
                    table={table}
                    file_name={"congeladores"}
                    update_identifiers={update_identifiers}
                    key={index}
                    settotal_rows={settotal_rows}
                  />
                )
                }
                />

            </div>
          </div>
          <div className='finish_process_uploadfile_cabinets'>
            <button
              onClick={()=>{
                props?.handleSubmit();
                dialog.close();
              }}
            >
              Finalizar
            </button>
          </div>
          </div>:
          <></>
        }
      </div>
    </div>
  )
}
