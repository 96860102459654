export const headers_check_put = (result, seterrmsjf, seterrfile, datamodels, setfile_cabinets, setprocess_num, data) =>{
  const requiredFields = [
    "Numero economico",
    "Año",
    "Modelo",
  ];
  const firstObject = result[0];
    const missingFields = requiredFields.filter(field => !firstObject.hasOwnProperty(field));
    if (missingFields.length > 0) {
        seterrmsjf("Faltan los siguientes campos: "+ missingFields.join(', '));
        seterrfile(true);
        return false;
    }

  const cleanedResult = result.map(item => {
    const cleanedItem = {};
    for (const key of Object.keys(item)) {
        if (requiredFields.includes(key) && item[key] != null && item[key] !== '') {
            cleanedItem[key] = item[key];
        }
    }
    return cleanedItem;
  }).filter(item => Object.keys(item).length > 0);
  
  if (cleanedResult.length > 0) {
    validate_data_put(cleanedResult, datamodels, setfile_cabinets, setprocess_num, data);
  } else {
      seterrmsjf("Todos los objetos están vacíos después de limpiar los campos.");
      seterrfile(true);
  }
}

const validate_data_put = (result, datamodels, setfile_cabinets, setprocess_num, data) =>{

    result.map(item=>{
      const datacabinets = data;
      datacabinets?.map(i=>{
        if(i.economic_number === item['Numero economico']){
          item.num_econ_validate = true;
          item.id = i.id;
          item.brand = i.brand; 
          item.serial_number = i.serial_number
        }
      });
      if(item.num_econ_validate){
        console.log("data sucess");
      }else{
        item.num_econ_validate = false;
        item.id = null;
        item.brand = null;
        item.serial_number = null;
      } 

      datamodels?.map(model=>{
        if(model.name_model === item['Modelo']){
          item.model_validate = true;
          item.id_model = model.id;
        }
      })
      if(item.model_validate){
        console.log("data sucess");
      }else{
        item.model_validate = false;
        item.id_model = null;
      } 
    })
    setfile_cabinets(result);
    setprocess_num(2); 
}

export const headers_check = (result, seterrmsjf, seterrfile, databrand,datamodels, setfile_cabinets, setprocess_num) =>{
  const requiredFields = [
    "Numero economico",
    "Numero activo fijo",
    "Numero serie",
    "Comodato",
    "Año",
    "Marca",
    "Modelo",
    "Nota",
  ];
  const firstObject = result[0];
    const missingFields = requiredFields.filter(field => !firstObject.hasOwnProperty(field));
    if (missingFields.length > 0) {
        seterrmsjf("Faltan los siguientes campos: "+ missingFields.join(', '));
        seterrfile(true);
        return false;
    }

  const cleanedResult = result.map(item => {
    const cleanedItem = {};
    for (const key of Object.keys(item)) {
        if (requiredFields.includes(key) && item[key] != null && item[key] !== '') {
            cleanedItem[key] = item[key];
        }
    }
    return cleanedItem;
  }).filter(item => Object.keys(item).length > 0);
  
  if (cleanedResult.length > 0) {
    validate_data(cleanedResult, databrand,datamodels, setfile_cabinets, setprocess_num);
  } else {
    seterrmsjf("Todos los objetos están vacíos después de limpiar los campos.");
    seterrfile(true);
  }
}

const validate_data = (result, databrand, datamodels, setfile_cabinets, setprocess_num) =>{
  result.map(item=>{
    databrand.map(brand=>{
      if(brand.name === item['Marca']){
        item.brand_validate = true
        item.id_brand = brand.id
      }
    })

    if(item.brand_validate || item.brand_validate == true){
        const modeld = datamodels;
        const modeldataaux = modeld.filter(i => i.id_brand_fk === item.id_brand);
        modeldataaux?.map(model=>{
          if(model.name_model === item['Modelo']){
            item.model_validate = true;
            item.id_model = model.id;
          }
        })
        if(item.model_validate){
          console.log("data sucess");
        }else{
          item.model_validate = false;
        }
      }
      else{
        item.brand_validate = false;
        item.model_validate = false;
      }
  })
  console.log(result);
  setfile_cabinets(result);
  setprocess_num(2);   
}