//PAQUETERIAS
import { useEffect, useState, useMemo, useRef } from "react";
import { CustomDialog, Confirm } from "react-st-modal"
import NotificationSystem from 'react-notification-system';
//COMPONENTES
import AlertResponse from "../../main_components/alerts/alertResponse";
import MODAL_TABLE from "../../main_components/modal/modal_Table";
import { refresh_token } from "../../main_components/tokens/tokenrefresh";
import { delete_route, get_route } from "../../services/configuration/configuration";
import { BREADCRUMBS } from "../../main_components/pagination/breadcrumbs";
//ESTILOS
import "./rutas.css";
import "../../components_cfdi/estilo.css";
import '../../componentSociosNegocios/componentSectors/bonuses.css';
import "../../components_cfdi/modal.css";
//IMAGENES/ICONOS
import CREATE from "../../imgComponents/create.png";
import OPERATIONES_ROUTES from "./tools/op_routes";
import WARNING from "../../main_components/alerts/warning";
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import RENDER_TOOLBAR_INTERNAL_ACTIONS from "../../main_components/methods_v2/export";
import RENDER_ROW_ACTION_MENU_ITEMS from "./tools/render_row_action_menuItems";
import { update_null_identifiers } from "../../main_components/methods_v2/auxiliar_funtion";

const ROUTES_V2 = () => {
  const notificationSystemRef = useRef();
  const [data, setData] = useState([]);
  const [open, setopen] = useState(false);
  var entrada = 0;
  const [modalT, setmodalT] = useState(false);
  const [menssage, setmenssage] = useState("");
  const [modalGeneral, setmodalGeneral] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [rowSelection, setRowSelection] = useState({});
  const [reconsult, setreconsult] = useState(false);

  const [total_rows, settotal_rows] = useState("");



  useEffect(() => {
    handleSubmit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function alertas(msj, status) {

    if (status === "warning") {
      notificationSystemRef.current.addNotification({
        message: <WARNING msj={msj} view={true}></WARNING>,
        level: "success",
        position: "br",
        onRemove:false,
        autoDismiss: 120,
      });
    } else if (status === true) {
      notificationSystemRef.current.addNotification({
        message: <AlertResponse msj={msj} view={true}></AlertResponse>,
        level: 'success',
        position: 'br',
        autoDismiss: 10,
      });
    } else {
      notificationSystemRef.current.addNotification({
        message: <AlertResponse msj={msj} view={false}></AlertResponse>,
        level: 'error',
        position: 'br',
        autoDismiss: 60,
      });
    }
  }

  const update_identifiers =
    [
      { route: 'string' },
      { name_warehouse: 'string' },
      { sale_type: 'String' },
      { description: 'string' },
      { code: 'string' },
      { name_user: 'string' },
      { name_second_user: 'string' },
      { days_for_delivery: 'string' },
      { code_dms: 'string' }
    ];




  const handleSubmit = async () => {

    setmodalT(true);
    setopen(true);
    setData([]);
    setIsLoading(false);

    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        setTimeout(async () => {
          try {
            var d = await get_route();
            var ndt = update_null_identifiers(d?.data?.routes, update_identifiers);
            setData(ndt === null ? [] : ndt);
            setopen(false);
            setIsLoading(false);
            setmodalT(false);
          } catch (err) {
            setData([]);
            setopen(false);
            setIsLoading(false);
            setmodalT(false);
          }
        }, 1000);
      } else { }
    } catch (err) {
      console.log(err);
    }
  };

  const columns = useMemo(
    () => [

      {
        id: "route",
        header: "Ruta",
        accessorKey: "route",
        muiTableBodyCellProps: {
          align: "center"
        }
      },
      {
        id: "name_warehouse",
        header: "Almacen",
        accessorKey: "name_warehouse",
        muiTableBodyCellProps: {
          align: "center"
        }
      },
      {
        id: "sale_type",
        header: "Tipo Venta",
        accessorKey: "sale_type",
        muiTableBodyCellProps: {
          align: "center"
        }
      },
      {
        id: "description",
        header: "Descripción",
        accessorKey: "description"
      },
      {
        id: "code",
        header: "Código",
        accessorKey: "code",
        muiTableBodyCellProps: {
          align: "center"
        }
      },
      {
        id: "name_user",
        header: "Vendedor",
        accessorKey: "name_user"
      },
      {
        id: "name_second_user",
        header: "Repartidor",
        accessorKey: "name_second_user"
      },
      {
        id: "days_for_delivery",
        header: "Días Entrega",
        accessorKey: "days_for_delivery",
        muiTableBodyCellProps: {
          align: "center"
        }
      },
      {
        id: "code_dms",
        header: "Código DMS",
        accessorKey: "code_dms",
        muiTableBodyCellProps: {
          align: "center"
        }
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );


  const handleStateChange = (newState) => {
    const filteredRowsCount = newState.filteredRowModel
    settotal_rows(filteredRowsCount);
  };


  return (
    <>
      <BREADCRUMBS niveles={
        [
          { label: "CONSULTAR RUTAS", path: null },
        ]
      }
        cards_dashboard={true}
        total_rows={total_rows !== "" ? total_rows : 0}
        filter_row={total_rows !== "" ? total_rows?.getFilteredRowModel()?.rows?.length : 0}
        visibility_row={total_rows !== "" ? total_rows?.getPaginationRowModel()?.rows?.length : 0}
        total={data?.length}
      ></BREADCRUMBS>

      <div className='new_table_v2'>
        <div className="">
          <NotificationSystem ref={notificationSystemRef} ></NotificationSystem>
          <MODAL_TABLE
            open={true}
            message={menssage}
            modalGeneral={modalGeneral}
            modalT={modalT}
          ></MODAL_TABLE>
          <MaterialReactTable
            columns={columns}
            data={data}
            enableGrouping
            enablePinning
            enableFacetedValues
            enableStickyHeader
            enableStickyFooter
            enableRowPinning
            enableColumnFilterModes
            onStateChange={handleStateChange}
            enableRowActions
            enableRowSelection
            getRowId={(row) => row?.id}
            onRowSelectionChange={setRowSelection}
            enableTooltips={false}
            localization={MRT_Localization_ES}
            state={{ rowSelection, isLoading: isLoading }}
            enableColumnResizing
            enableColumnPinning
            enableColumnOrdering
            enableExpandAll={false}
            initialState={{ showGlobalFilter: true }}
            muiCircularProgressProps={{ color: 'secondary', thickness: 5, size: 55 }}
            muiSkeletonProps={{ animation: 'pulse', height: 28 }}
            rowVirtualizerInstanceRef
            rowVirtualizerOptions={{ overscan: 5 }}
            columnVirtualizerOptions={{ overscan: 2 }}
            muiTableContainerProps={{ sx: { maxHeight: '69vh', '@media (max-height: 810px)': { maxHeight: '65vh' } } }}
            positionToolbarAlertBanner="bottom"
            paginationDisplayMode='pages'
            rowPinningDisplayMode='sticky'
            layoutMode="grid"
            muiTableHeadCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}

            muiTableBodyCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
            muiPaginationProps={{ color: 'primary', shape: 'rounded', variant: 'variant', }}
            displayColumnDefOptions={{
              'mrt-row-pin': {
                Header: () => (
                  <div>
                    <img
                      title="Registrar Ruta"
                      src={CREATE}
                      className=""
                      onClick={async () => {
                        const result = await CustomDialog(
                          <OPERATIONES_ROUTES
                            operation={"CREATE"}
                            handleSubmit={handleSubmit}
                            setmodalT={setmodalT}
                            alertas={alertas}
                          />,
                          {
                            className: "custom-modal-3",
                            title: "Registrar Ruta",
                            showCloseIcon: true,
                            isCanClose: false,
                          }
                        );
                      }}
                    ></img>
                  </div>
                ),
                enableHiding: true,
              },
              'mrt-row-actions': {
                enableHiding: true,
              },
              'mrt-row-expand': {
                enableHiding: true,
              },
              'mrt-row-select': {
                enableHiding: true,
              }
            }}
            muiTableBodyRowDragHandleProps={({ table }) => ({
              onDragEnd: () => {
                const { draggingRow, hoveredRow } = table.getState();
                if (hoveredRow && draggingRow) {
                  data.splice(
                    hoveredRow.index,
                    0,
                    data.splice(draggingRow.index, 1)[0],
                  );
                  setData([...data]);
                }
              },
            })}
            muiTableBodyRowProps={
              ({ row, table }) => {
                settotal_rows(table);
                const { density } = table.getState();
                return {
                  sx: {
                    height: row.getIsPinned()
                      ? `${density === 'compact' ? 30 : density === 'comfortable' ? 35 : 69
                      }px`
                      : undefined,
                  },
                };
              }
            }
            renderRowActionMenuItems={({ row, closeMenu }) => [
              <RENDER_ROW_ACTION_MENU_ITEMS
                closeMenu={closeMenu}
                setmodalT={setmodalT}
                setmenssage={setmenssage}
                setmodalGeneral={setmodalGeneral}
                row={row}
                handleSubmit={handleSubmit}
                setreconsult={setreconsult}
                refresh_token={refresh_token}
                alertas={alertas}
              />
            ]}
            renderToolbarInternalActions={({ table }) => (
              <RENDER_TOOLBAR_INTERNAL_ACTIONS
                table={table}
                file_name={"Consultar Rutas"}
                update_identifiers={update_identifiers}
                settotal_rows={settotal_rows}

              />
            )
            }
          />

        </div>
      </div>
    </>
  );
}

export default ROUTES_V2;
