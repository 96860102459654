import { Box, TextField } from '@mui/material';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import UploadIcon from '@mui/icons-material/Upload';

export const select_method = (method, setmethod) =>{
    return (
        <div className='' style={{"width": "90%", margin: "0 auto"}}>
             <TextField
                select
                label="Operación"
                defaultValue={""}
                value={method}
                InputLabelProps={{shrink: true}}
                SelectProps={{native: true}}
                sx={{ m: 1.5, width: "100%"}}
                onChange={(event) => {
                  console.log(event.target.value)
                  setmethod(event.target.value)
                }}
                >
                <option key={0} value={0}>
                    {"Selecciona"}
                </option>
                <option key={1} value={1}>
                    {"Registrar"}
                </option>
                <option key={2} value={2}>
                    {"Actualizar"}
                </option>
            </TextField>
            </div>
    )
}

export const upload_file_div = (
    handleFiles, 
    setfile, 
    setdowp,
    fileInputRef,
    setHighlight,
    highlight,
    handleAuthClick,
    errmsjf
) =>{
    
  const handleDrop = (e) => {
    e.preventDefault();
    setHighlight(false);
    const files = e.dataTransfer.files;
    setfile(files[0]);
    handleFiles(files);
  };
  
  const preventDefaults = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  
  const handleFileInputClick = () => {
    fileInputRef.current.click();
  };  
  
  const handleFileInputChange = (e) => {
    const files = e.target.files;
    setfile(files[0]);
    handleFiles(files);
  };

    return (
        <>
            <div className={`drop-area ${highlight ? 'highlight' : ''}`}
              onDrop={handleDrop}
              onDragOver={preventDefaults}
              onDragEnter={() => setHighlight(true)}
              onDragLeave={() => setHighlight(false)}
              onClick={handleFileInputClick}>
              <div className="icon-container_down">
                <UploadIcon sx={{ fontSize: 50 }}></UploadIcon>
              </div>
              <p>Arrastra y suelta un archivo CSV aquí o haz clic para seleccionarlo.</p>
              <input
                  type="file"
                  accept=".csv"
                  ref={fileInputRef}
                  onChange={handleFileInputChange}
                  style={{ display: 'none' }}
              />
              {errmsjf.length > 0 ? 
                <div className="info-box">
                  <NotificationImportantIcon></NotificationImportantIcon>
                  <p style={{ color: "red" }}>{errmsjf}</p>
                </div>
                :<div className="info-box">
                  <NotificationImportantIcon></NotificationImportantIcon>
                  <p>El proceso es exclusivamente para actualizar el año de producción y modelo de congeladores.</p>
                </div>
              }
            </div>
            <button onClick={()=>{
                setdowp(true);
                setTimeout(() => {
                  handleAuthClick()
                }, 1000);
              }} 
              className="download-template-button"
            >
              <FileDownloadIcon></FileDownloadIcon>
              Descargar Plantilla
            </button>
           
        </>
    )
}