// Importaciones de paquetes
import React, { useEffect, useMemo, useRef, useState } from "react";
import { MRT_ColumnDef } from "material-react-table";
// Importaciones de componentes
import { refresh_token } from "../../../main_components/tokens/tokenrefreshNewMethod";
import { pagination_v2 } from "../../../main_components/pagination/pagination_v2";
import { UPDATE_NULL_IDENTIFIERS } from "../../../main_components/methods_v2/auxiliary_functions_new_method";
import { BUSSINES_PARTNER, RESPONSE } from "./interface/interface";
import MATERIAL_TABLE_CONTAINER from "../../../main_components/material_table/table_container";
import GENERAL_FORM from "../../../main_components/material_table/general_form";
import {
  updated_form_data,
  validate_data,
  processed_format,
} from "./tools/auxiliar_funtions";
import { create_bp, update_bp } from "../../../services/businesspartners/businesspartners";
import { create_object } from "./tools/auxiliar_funtions";
//IMG / ICONS

import CREATE from "../../../imgComponents/create.png";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveAsIcon from "@mui/icons-material/SaveAs";

import { inf_partner } from "./tools/inf_partner";

import {
  get_address_businesspartners,
  get_data_businesspartners,
  get_code_businesspartners
} from "../../../services/businesspartners/businesspartners";

export interface FormData {
  code: string;
  business_partner: string;
  rfc: string;
  person_type: string;
  paymethod: string;
  cfdi_use: string;
  zip_code: string;
  tax_regime: string;
  email: string;
  num_reg_id_trib: string;
  tax_residence: string;
}


const BUSSINES_PARTNER_TS: React.FC = () => {
  const [data, setData] = useState<BUSSINES_PARTNER[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [modalT, setmodalT] = useState<boolean>(false);
  const [loadingSkeleton, setLoadingSkeleton] = useState<boolean>(false);
  const [open_form, setopen_form] = useState(false);
  const [isNewEntry, setIsNewEntry] = useState(false);
  const [formData, setformData] = useState([]);
  const [methodDelete, setMethodDelete] = useState(false);
  const [identifier_data, setidentifier_data] = useState<any>(null);

  const update_identifiers = [
    { code: "string" },
    { business_partner: "string" },
    { rfc: "string" },
    { person_type: "string" },
    { paymethod: "string" },
    { cfdi_use: "string" },
    { zip_code: "string" },
    { tax_regime: "string" },
    { email: "string" },
    { num_reg_id_trib: "string" },
    { tax_residence: "string" },
  ];

  const handleAddAddresPartner = async (originalObj: any, isNew: boolean) => {
    try {
      const isTokenValid = await verifyToken();

      if (!isTokenValid) {
        console.error("Token inválido o expirado. Deteniendo el proceso...");
        console.log("Actualizando token...");
        setTimeout(() => {
          handleAddAddresPartner(originalObj, isNew);
        }, 1000);
        return;
      }
      const address = await fetchAddress(originalObj?.id);
      const partner = await fetchDataPartner(originalObj?.id);
      if (address.length === 0 || partner.length === 0) {
        console.error("No se pudo obtener address o partner");
        setLoadingSkeleton(true)
        return;
      }
      const fieldsToUpdate = [
        "street",
        "zip_code",
        "address_type",
        "country",
        "city",
        "town",
        "outdoor_number",
        "inside_number",
        "reference",
        "subsidiary",
        "neighborhood",
      ];
      fieldsToUpdate.forEach((field) => {
        if (field in address) {
          originalObj[field] = address[field];
        }
      });
      const additionalFields = [
        "partner_type",
        "credit_days",
        "credit_limit_with_supplier",
        "business_partner_type",
        "system_commentary"
      ];

      const mergedObject = { ...originalObj };

      additionalFields.forEach((field) => {
        if (field in partner) {
          mergedObject[field] = partner[field];
        }
      });

      mergedObject.pointofsale = `partner=${mergedObject.business_partner}&rfc=${mergedObject.rfc}`;
      setformData(mergedObject)
      setIsNewEntry(isNew);
      setLoadingSkeleton(false)
      setMethodDelete(false);
    } catch (error) {
      console.error("Error al manejar add_address_bp:", error);
      return null;
    }
  };



  const handleAddCode = async (originalObj: any, isNew: boolean) => {
    try {
      const isTokenValid = await verifyToken();

      if (!isTokenValid) {
        console.error("Token inválido o expirado. Deteniendo el proceso...");
        console.log("Actualizando token...");
        setTimeout(() => {
          handleAddCode(originalObj, isNew);
        }, 1000);
        return;
      }

      const code = await fetchCodePartner();
      if (code.length === 0) {
        console.error("No se pudo obtener address o partner");
        setLoadingSkeleton(true)
        return;
      }

      originalObj.code = code;

      setformData(originalObj)
      setIsNewEntry(isNew);
      setLoadingSkeleton(false)
      setMethodDelete(false);

    } catch (error) {
      console.error("Error al manejar add_address_bp:", error);
      return null;
    }
  };

  const handleOpenForm = (new_value: any, isNew: boolean) => {
    if (isNew) {
      setLoadingSkeleton(true)
      setopen_form(true);
      setMethodDelete(false);
      handleAddCode(new_value, isNew);
    } else {
      setopen_form(true);
      setLoadingSkeleton(true)
      setMethodDelete(false);
      handleAddAddresPartner(new_value, isNew);
    }
  };

  const handleClose = () => { setopen_form(false); };


  const handleSaveData = async (
    finalObject: any,
    method: any,
    identifier_data: any
  ) => {
    console.log("Datos a procesar:", finalObject);
    console.log("True / Post, False / Put, Delete:", method);

    // setmodalT(true);

    try {
      switch (method) {
        case true:
          console.log("Creando objeto...");
          // await create_bp(finalObject);
          console.log("Objeto creado con éxito");
          // handleBussinesPartner();
          // handleClose();
          break;

        case false:
          console.log("Actualizando objeto...");
          // await update_bp(finalObject, identifier_data);
          console.log("Objeto actualizado con éxito");
          // handleBussinesPartner();
          // handleClose();
          break;

        default:
          console.error("Método no reconocido");
      }
    } catch (error: any) {
      alert(error.respon.data);
      console.error("Error al procesar los datos:", error);
    } finally {
      setmodalT(false);
      console.log("Operación finalizada.");
    }
  };

  const verifyToken = async (): Promise<boolean> => {
    try {
      return await refresh_token();
    } catch (error) {
      console.error("Error al verificar el token:", error);
      return false;
    }
  };

  const fetchAddress = async (id: any) => {
    try {

      const response = await get_address_businesspartners(id);
      return response.data;
    } catch (error) {
      console.error("Error al obtener los datos de dirección:", error);
      return [];
    }
  };

  const fetchDataPartner = async (id: any) => {
    try {
      const response = await get_data_businesspartners(id);
      return response.data;
    } catch (error) {
      console.error("Error al obtener los datos del socio:", error);
      return [];
    }
  };

  const fetchBussinesPartner = async (): Promise<BUSSINES_PARTNER[]> => {
    try {
      const rawResponse = await pagination_v2(5, "bp", null);

      const response: RESPONSE = Array.isArray(rawResponse)
        ? { data: rawResponse }
        : rawResponse;

      return UPDATE_NULL_IDENTIFIERS(
        response.data,
        update_identifiers
      ) as BUSSINES_PARTNER[];
    } catch (error) {
      console.error("Error al obtener los datos de paginación:", error);
      return [];
    }
  };

  const fetchCodePartner = async () => {
    try {
      const response = await get_code_businesspartners();
      return response.data.code;
    } catch (error) {
      console.error("Error al obtener el código del socio:", error);
      return [];
    }
  };


  const handleBussinesPartner = async (event?: React.SyntheticEvent): Promise<void> => {
    event?.preventDefault();
    setIsLoading(true);
    setmodalT(true);
    setData([]);
    const isTokenValid = await verifyToken();
    if (!isTokenValid) {
      setIsLoading(true);
      setmodalT(true);
      console.error("Token inválido o expirado. Deteniendo el proceso...");
      console.log("Actualizando token...");
      setTimeout(() => {
        handleBussinesPartner();
      }, 1000);
      return;
    }
    const data = await fetchBussinesPartner();
    setData(data);
    setIsLoading(false);
    setmodalT(false);
  };

  const columns: MRT_ColumnDef<BUSSINES_PARTNER>[] = useMemo(
    () => [
      {
        id: "AC.",
        header: "AC.",
        size: 55,
        enableFilters: false,
        enableColumnFilters: false,
        enableColumnFiltering: false,
        enableEditing: false,
        enableColumnFilterModes: false,
        enableRowOrdering: false,
        enableGrouping: false,
        enableColumnDragging: false,
        enableColumnActions: false,
        accessorFn: (d) => {
          return (
            <div>
              <span
                onClick={() => {
                  console.log(d)
                  setMethodDelete(true);
                  handleOpenForm(d, false);
                  setidentifier_data(d.id);
                }}
              >
                <SaveAsIcon className="icons_general_save"></SaveAsIcon>
              </span>
              <span
                onClick={() => {
                  setMethodDelete(true);
                  setopen_form(true);
                  setidentifier_data(d?.id);
                }}
              >
                <DeleteIcon className="icons_general_delete"></DeleteIcon>
              </span>
              {/*  */}
            </div>
          );
        },
        muiTableHeaderCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
      },

      {
        header: "Código",
        id: "code",
        accessorKey: "code",
        enableEditing: false,
        size: 130,
        muiTableBodyCellProps: {
          align: 'center',
        },
      },
      {
        header: "Socio de Negocios",
        id: "business_partner",
        accessorKey: "business_partner",
        size: 180,
      },
      {
        header: "RFC",
        accessorKey: "rfc",
        id: "rfc",
        size: 130,
      },
      {
        header: "Tipo de Persona",
        id: "person_type",
        accessorKey: "person_type",
        size: 165,
        editVariant: "select",
        editSelectOptions: ["MORAL", "FÍSICA"],
        filterVariant: "select",
        enableColumnFilterModes: false,
        muiTableBodyCellProps: {
          align: 'center',
        },
      },
      {
        header: "Método de Pago",
        id: "paymethod",
        size: 165,
        accessorKey: "paymethod",
        muiTableBodyCellProps: {
          align: 'center',
        },
      },
      {
        header: "Uso De CFDI",
        id: "cfdi_use",
        size: 145,
        accessorKey: "cfdi_use",
        accessorFn: (d) => {
          return d.cfdi_use;
        },
        editVariant: "select",
        editSelectOptions: ["S01", "G01", "G03"],
        filterVariant: "select",
        enableColumnFilterModes: false,
        muiTableBodyCellProps: {
          align: 'center',
        },
      },
      {
        header: "Código Postal",
        accessorKey: "zip_code",
        id: "zip_code",
        size: 150,
        muiTableBodyCellProps: {
          align: 'center',
        },
      },
      {
        header: "Régimen Fiscal",
        accessorKey: "tax_regime",
        id: "tax_regime",
        size: 155,
        accessorFn: (d) => {
          return d.tax_regime;
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
      },
      {
        header: "Correo Eléctronico",
        accessorKey: "email",
        id: "email",
      },
      {
        header: "Número de Registro de Identificación Tributaria",
        size: 350,
        accessorKey: "num_reg_id_trib",
        id: "num_reg_id_trib",
      },
      {
        header: "Residencia Fiscal",
        accessorKey: "tax_residence",
        id: "tax_residence",
      },
    ],
    []
  );

  useEffect(() => {
    handleBussinesPartner();
  }, []);



  return (
    <div style={{ width: "100%", margin: "0 auto" }} className="new_table_v2">
      <GENERAL_FORM
        open={open_form}
        onClose={handleClose}
        data_form={inf_partner}
        onSave={handleSaveData}
        isNew={isNewEntry}
        title_create="CREAR SOCIO DE NEGOCIOS"
        title_update="ACTUALIZAR SOCIO DE NEGOCIOS"
        validateField={validate_data}
        handleProcessedFormat={processed_format}
        FormData={FormData}
        original_data={formData}
        size={methodDelete ? "30%" : "85%"}
        method_delete={methodDelete}
        message_delete="¿Esta seguro de eliminar este socio de negocios?"
        updatedFormData={updated_form_data}
        identifier_data={identifier_data}
        loadingSkeleton={loadingSkeleton}
      />


      <MATERIAL_TABLE_CONTAINER
        columns={columns}
        data={data}
        breadcrumbs={[{ label: "SOCIO DE NEGOCIOS", path: null }]}
        modalT={modalT}
        isLoading={isLoading}
        search_filters={<div></div>
        }
        component_header={
          <img
            style={{ cursor: "pointer" }}
            src={CREATE}
            onClick={() => handleOpenForm(create_object, true)}
          ></img>
        }
        file_name_export="CONDICIONES DE PAGOS"
        update_identifiers={update_identifiers}
      ></MATERIAL_TABLE_CONTAINER>
    </div>
  );
};

export default BUSSINES_PARTNER_TS;
