//Content/index

//Content/config/routes

import Vppd_v2 from "../../components_cfdi/invoice/consult_invoice/consult_invoice_PPD_v2";
import GENERATE_INVOICE from "../../components_cfdi/invoice/generate_invoice/generate_invoice";
import CARTA_PORTE_V2 from "../../components_cfdi/invoice/cartaporte/cartaporte_v2";
// import WAYBILL from "../../components_cfdi/invoice/cartaporte/waybill";
import PAGO from "../../components_cfdi/invoice/payment/pago";
import INVOICE_V2 from "../../components_cfdi/invoice/consult_invoice/consult_invoice2";
import TableConfigureTaxes_v2 from "../../components_cfdi/invoice/componentsTaxes/Consult_Configure_Tax_v2";
import CONSUL_CONCEPT_V2 from "../../components_cfdi/invoice/concepts/consult_concept_v2";
import TableTaxes_V2 from "../../components_cfdi/invoice/componentsTaxes/Consult_Tax_v2";
import { MAP_RELATION_INVOICE } from "../../components_cfdi/invoice/map_relation/map_relation";
import { PAYMENT_REPORT_V2 } from "../../components_cfdi/invoice/payments_report/payment_report_v2";
//
import BUSSINES_PARTNER_TS from "../../component_businessPartner/business_partner/bussines_partner_ts/partner_ts";
import BUSINESS_PARTNERT_TABLE from "../../component_businessPartner/business_partner/consult_business_partner_v2";
import UpdateBusnes from "../../pages/business_partner/editBusnes";
import BUSINESS_PARTNER_QR from "../../component_businessPartner/business_partner/tools/business_partner_qr";
import DISCOUNTS_V2 from "../../componentSociosNegocios/componentDescuentos/Discounts_v2";
import SECTORS_V2 from "../../componentSociosNegocios/componentSectors/Sectors_v2";
import POINT_OF_SALE_SECTOR_V2 from "../../componentSociosNegocios/componentSectors/details/Point_of_Sale_sector_v2";
import PROMOTIONS_V2 from "../../componentSociosNegocios/componentpromotions/Promotions_v2";
import RECOVERY_PARTNER_V2 from "../../componentSociosNegocios/componentRecoverPartner/recovery_partner_v2";
import POINT_OF_SALE_WITHOUT_SECTOR_V2 from "../../componentSociosNegocios/Componentpointsofsalewithoutsector/point_of_sale_without_sector_v2";
import POINTS_OF_SALE_V2 from "../../componentSociosNegocios/componentOfSale/Point_of_sale_v2";
//
import CONSULT_POLICY_V2 from "../../components_Expenses/policy/consult_policy_v2";
import CATALOG_EXPENSE_CONCEPT_V2 from "../../components_Expenses/ComponentExpenseConcept/Catalog_Expense_Concept_v2";
import ASSIGNED_CONCEPTS_V2 from "../../components_Expenses/ComponentExpenseConcept/detail/assigned_concepts_v2";
import RULES_SE_V2 from "../../components_Expenses/componentRulesSendingExpenses/rules_se_v2";
import EXPENSES_NON_DEDUCTIBLE_V2 from "../../components_Expenses/expenses/non_deductible/non_deductible_v2";
import EXPENSES_FOREING_V2 from "../../components_Expenses/expenses/foreign/foreign_v2";
import ConsultarFacturasProvedores_v2 from "../../components_Expenses/supplier/consultarFacturasProvedores_v2";
import REPORT_EXPENSE_V2 from "../../components_Expenses/expenses/report/report_expense_v2";
import CONSULT_GROUP_EXPENSE_V2 from "../../components_Expenses/expenses/grup_expense/consult_group_expense_v2";
import EXPENSES_GROUP_DETAIL_V2 from "../../components_Expenses/expenses/grup_expense/detail/group_details_v2";
import SUPPLIER_v2 from "../../component_businessPartner/business_partner/supplier/supplier_v2";
import SetValidador from "../../pages/providers/Validador";
//
import TableBonificaciones_V2 from "../../componentBonificaciones/TableBonificaciones_v2";
import { ADD_SALES_BONIFICACIONES_V2 } from "../../componentBonificaciones/componentVentasBonificaciones/add_sales_bonificaciones_v2";
import DMS_V2 from "../../component_unilever/DMS_V2/DMS_V2";
import TableEditAddSales_V2 from "../../componentBonificaciones/EditVentasBonificaciones/TableEditAddSales_v2";
import { POINTS_OF_SALE_PARTICIPANTS } from "../../component_unilever/competition/tools/points_of_sale";
import { REPORT_BONICACIONES } from "../../component_unilever/report_bonificaciones/Report_Bonificaciones";
import DOMY_DATA from "../../componentAssets/domy/domy";
import { CENSO } from "../../censo/censo";
//
import TableRouteSalesReport from "../../componentsNewDashboard/componentSalefourRoute/TableRouteSalesReport";
import SUMMARY_ANNUAL_V2 from "../../componentsNewDashboard/componentSumaria/Consult_annual_summary_v2";
import SUMMARY_WEEKLY_V2 from "../../componentsNewDashboard/componentSumariaWeektly/Summary_weektly_v2";
import SalesReal_V2 from "../../componentsNewDashboard/componentVentasTReal/SalesReal_v2";
import REPORT_SALES from "../../componentsNewDashboard/report_sales/report_sales";
import DASHBOARD_CLIENTS_V2 from "../../componentsNewDashboard/newDashboard_v2";
import { ReporteGMaps_v2 } from "../../componentsNewDashboard/ComponentRoutes/ReporteGMaps_v2";
import { COMPARATIVE } from "../../componentsNewDashboard/componentComparativeReports/Comparative";
import { SALES_DELIVERIES } from "../../componentsNewDashboard/componentSalesDeliveries/SalesDeliveries";
import { COMPONENT_ROUTE } from "../../componentsNewDashboard/routes/component_route";
import { REAL_SALES_MAPBOX } from "../../componentsNewDashboard/componentVentasTReal/real_sales_mapbox/real_sales_mapbox";
import TableSemaphore from "../../componentsNewDashboard/componentSemaphore/TableSemaphore";
//
import CONSULT_CASHCLOSING_V2 from "../../component_bank/cashclosing/cashclosing/consult_cashclosing _v2";
import CONSULT_CASHCLOSING_SALE_V2 from "../../component_bank/cashclosing/cashclosing/cahsclosing_sale/consult_cashclosing_sale_v2";
import CONSULT_CASHCLOSING_SALE_REPORT_V2 from "../../component_bank/cashclosing/cashclosing/report/consult_cashclosing_sale_report_v2";
import DEBTS from "../../component_bank/debts_and_paid/debts";
import PAID from "../../component_bank/debts_and_paid/paid";
import OLD_BALANCES_REPORT from "../../component_bank/debts_and_paid/old_balances_report";
import PAY_CONDITION from "../../component_bank/pay_condition/pay_condition";
//
import ROUTES_V2 from "../../componentConfiguration/componentRutas/routes_v2";
import WAREHOUSES_V2 from "../../componentConfiguration/componentWarehouse/warehouse_v2";
import TableRoles from "../../componentConfiguration/componentRoles/TableRoles";
import EditCompany from "../../componentConfiguration/componentCompany/EditCompany";
import TableUsers from "../../componentConfiguration/componentUsers/TableUsers";
import { MANAGEMENT_ROUTE } from "../../componentConfiguration/componentSupervisorRoute/supervisionRoute";
import { PROFILE } from "../../component_profile/profile";
//
import COMMISSIONS_V2 from "../../componentsNewDashboard/daily_staff/commissions/commissions_v2";
import EMPLOYEES_V2 from "../../componentHumanResources/componentEmployees/ConsultEmployees_v2";
import DAILY_STAFF_V2 from "../../componentsNewDashboard/daily_staff/daily_staff_v2";
import { JOB_OPPORTUNITIES } from "../../componentHumanResources/JobOpportunities/JobOpportunities";
import { APPLICANTS } from "../../componentHumanResources/JobOpportunities/applicants/applicants";
import { COMPETITION } from "../../component_unilever/competition/competition";
//
import BRANDS_V2 from "../../componentAssets/componentMarcaF/brands_v2";
import VEHICLES_V2 from "../../componentAssets/componentVehicle/consult_vehicle_v2";
import Freezer_v2 from "../../componentAssets/componentFreezer/TableFreezer_v2";
import MovementWarehouseCabinets_v2 from "../../componentAssets/componentFreezer/MovementWarehouseCabinets_v2";
import { ASSETS_SAMSARA } from "../../component_samsara/assets_samsara";
//
import { ViewCases_v2 } from "../../componentsSupports/consultCases/Bucarcasos_v2";
import { Capacitacion } from "../../componentCapacitation/Videos";
import { USER_MANUAL } from "../../componentCapacitation/donwload_pdf_manual";
import { PATCH_NOTES_HOME } from "../../patch-notes/patch_notes_home";
import { VERSION_DESCRIPTION } from "../../patch-notes/versions/1/version_1.0.7";
//
import CONSULT_DETAIL_PRICE_LIST_V2 from "../../components_inventory/price_list/detail/consult_detail_price_list_v2";
import UPDATE_PRICE_LIST_V2 from "../../components_inventory/price_list/detail/tools/update_list_v2";
import ADD_PRODUCT_V2 from "../../components_inventory/price_list/detail/tools/add_product_v2";
import PRODUCTS_V2 from "../../components_inventory/products/products _v2";
import PRICE_LIST_REPORT from "../../components_inventory/price_list/report/price_list";
import STOCK_V2 from "../../components_inventory/stock/stock_v2";
import PRODUCT_LOAD_V2 from "../../components_inventory/product_load/product_load_v2";
import PRODUCT_LOAD_NEW_METHOD from "../../components_inventory/product_load/product_new_method";
import PRODUCTS_LINE_V2 from "../../components_inventory/producst_line/producst_line_v2";
import PRICE_LIST_v2 from "../../components_inventory/price_list/price_list_v2";
import MOVEMENTS_v2 from "../../components_inventory/movements/movements_v2";
import WAREHOUSE_MOVEMENTS_V2 from "../../components_inventory/movements/tools/warehouse_movement_v2";
//
import { DASHBOARD } from "../tools/dashboard";
//
import { Autorization } from "../../componentDrive/Autorization";
import { DATASYNC } from "../../datasync/datasync";
//
import ACCOUNTING_ACCOUNT_V2 from "../../componentaccount/Accounting_Account_v2";
import { PAYMENT_ACCOUNTS } from "../../component_bank/payment_accounts/payment_accounts";

import SetRegisterGeneral from "../../pages/business_partner/registerGeneral/setRegisterGeneral";
import POINTS_OF_SALE_V2_TS from "../../componentSociosNegocios/points_of_sale_ts/point_of_sale";
const routes = [
    //Invoices
    { path: "/FacturasPPD_V2", component: Vppd_v2 },
    { path: "/GenerarFactura", component: GENERATE_INVOICE },
    { path: "/cartaporte", component: CARTA_PORTE_V2 },
    // { path: "/WAYBILL", component: WAYBILL },
    { path: "/pago", component: PAGO },
    { path: "/invoice_v2", component: INVOICE_V2 },
    { path: "/consult_configure_taxes_v2", component: TableConfigureTaxes_v2 },
    { path: "/consult_concept_v2", component: CONSUL_CONCEPT_V2 },
    { path: "/consult_taxes_v2", component: TableTaxes_V2 },
    { path: "/consult_payment_report_v2", component: PAYMENT_REPORT_V2 },
    { path: "/map_relation", component: MAP_RELATION_INVOICE }, // Unica version

    // { path: "/consult_payment_report", component: PAYMENT_REPORT },//version 1
    // { path: "/consultaCFDI", component: CfdiTable }, //version 1
    // { path: "/FacturasPPD", component: SetfacturaPPD }, //version 1
    // { path: "/consult_taxes", component: SetConsultaTaxes }, //version 1
    // { path: "/consult_configure_taxes", component: SetConsulConfigureTaxes }, // version1
    // { path: "/consult_concept", component: CONSUL_CONCEPT },// version1

    //Business Partner

    { path: "/BUSSINES_PARTNER_TS", component: BUSSINES_PARTNER_TS },
    { path: "/bussines_partnert_v2", component: BUSINESS_PARTNERT_TABLE },
    { path: "/updateBP", component: UpdateBusnes },
    { path: "/createQr", component: BUSINESS_PARTNER_QR },
    { path: "/consult_discounts_v2", component: DISCOUNTS_V2 },
    { path: "/consult_sectors_v2", component: SECTORS_V2 },
    {
        path: "/consult_point_of_sale_sector_v2",
        component: POINT_OF_SALE_SECTOR_V2,
    },
    { path: "/consult_promotions_v2", component: PROMOTIONS_V2 },
    { path: "/consult_recovery_partner_v2", component: RECOVERY_PARTNER_V2 },
    {
        path: "/consult_point_of_sale_without_sector_v2",
        component: POINT_OF_SALE_WITHOUT_SECTOR_V2,
    },
    { path: "/consult_point_of_sale_v2", component: POINTS_OF_SALE_V2 },

    { path: "/consult_point_of_sale_ts", component: POINTS_OF_SALE_V2_TS}, 

    // { path: "/consult_sectors", component: SetSectors }, // version 1
    // { path: "/consult_point_sale_sector", component: POINTS_OF_SALE_SECTOR },// version 1
    // { path: "/consult_discounts", component: SetDiscounts },// version 1
    // { path: "/consult_promotions", component: SetPromotions },// version 1
    // { path: "/consult_point_of_sale", component: setPointsOfSale },// version 1
    // { path: "/consult_point_of_sale_without_sector", component: POINT_OF_SALE_WITHOUT_SECTOR },// version 1
    // { path: "/consult_recover_partner", component: RECOVERY_PARTNER },// version 1
    { path: "/register_general", component: SetRegisterGeneral }, 

    //Buys
    { path: "/policy_v2", component: CONSULT_POLICY_V2 },
    {
        path: "/catalog_expense_concept_v2",
        component: CATALOG_EXPENSE_CONCEPT_V2,
    },
    { path: "/consult_assigned_concepts_v2", component: ASSIGNED_CONCEPTS_V2 },
    { path: "/consult_rules_sending_expenses_v2", component: RULES_SE_V2 },
    { path: "/expenses_v2", component: EXPENSES_NON_DEDUCTIBLE_V2 },
    { path: "/expensesExtranjero_V2", component: EXPENSES_FOREING_V2 },
    {
        path: "/consultaFacturasProvedores_V2",
        component: ConsultarFacturasProvedores_v2,
    },
    { path: "/expensesReport_v2", component: REPORT_EXPENSE_V2 },
    { path: "/expensesGrup_v2", component: CONSULT_GROUP_EXPENSE_V2 },
    { path: "/expensesGrupDetail_v2", component: EXPENSES_GROUP_DETAIL_V2 },
    { path: "/supplier_v2", component: SUPPLIER_v2 },
    { path: "/validador", component: SetValidador }, //revisar version
    // { path: "/consultaFacturasProvedores", component: SetConsultarFacturasProveedores },
    // { path: "/consult_accounting_account", component: TableAccountingAccount }, //version1
    // { path: "/consult_rules_sending_expenses", component: RULES_SE }, //version1
    // { path: "/consult_expense_concept", component: TableExpenseConcept },//version1
    // { path: "/expenses", component: EXPENSES_NON_DEDUCTIBLE },//version1
    // { path: "/expensesExtranjero", component: EXPENSES_FOREING },//version1
    // { path: "/expensesReport", component: REPORT_EXPENSE },//version1
    // { path: "/expensesGrup", component: CONSULT_GROUP_EXPENSE },//version1
    // { path: "/expensesGrupDetail", component: EXPENSES_GROUP_DETAIL },//version1
    // { path: "/policy", component: CONSULT_POLICY },//version1
    // { path: "/consult_assigned_concepts", component: ASSIGNED_CONCEPTS },//version1
    // { path: "/supplier", component: SUPPLIER },//version1

    //Unilever
    { path: "/consult_report_bonuses_V2", component: TableBonificaciones_V2 },
    {
        path: "/add_sales_bonificaciones_v2",
        component: ADD_SALES_BONIFICACIONES_V2,
    },
    { path: "/DMS_V2", component: DMS_V2 },
    { path: "/edit_add_bonuses_v2", component: TableEditAddSales_V2 },
    { path: "/contests_points_of_sale", component: POINTS_OF_SALE_PARTICIPANTS },
    { path: "/consult_bonus", component: REPORT_BONICACIONES }, // Version actual revisar y quitar las acceskey
    { path: "/DOMY_DATA", component: DOMY_DATA },
    { path: "/censo", component: CENSO }, // Revisar caso

    // { path: "/consult_report_bonuses", component: SetTableBonuses }, //posiblemente v1 revisar
    // { path: "/edit_add_bonuses", component: SetEditAddBonuses }, //posiblemente v1 revisar
    // { path: "/add_sales_bonificaciones", component: ADD_SALES_BONIFICACIONES }, // version 1
    // { path: "/consult_DMS", component: CONSULT_DMS }, // version 1

    //Reports
    { path: "/consult_route_sales_report", component: TableRouteSalesReport },
    { path: "/summary_annual_v2", component: SUMMARY_ANNUAL_V2 },
    { path: "/summary_weekly_v2", component: SUMMARY_WEEKLY_V2 },
    { path: "/Consult_report_sale_real_V2", component: SalesReal_V2 },
    { path: "/report_sales", component: REPORT_SALES },
    { path: "/NewDashboard_v2", component: DASHBOARD_CLIENTS_V2 },
    { path: "/map_routes", component: ReporteGMaps_v2 },
    { path: "/comparative_reports", component: COMPARATIVE },
    { path: "/sales-deliveries", component: SALES_DELIVERIES },
    { path: "/component_route", component: COMPONENT_ROUTE },
    { path: "/Consult_report_sale_real_ultimate", component: REAL_SALES_MAPBOX },

    { path: "/consult_semaphore", component: TableSemaphore }, //Unica version, solo que esta oculta para los usuarios
    // { path: "/rutas", component: SetReportRoutes }, //posiblemente v1 revisar
    // { path: "/NewDashboard", component: NewDashboard }, //posiblemente v1 revisar
    // { path: "/consult_report_sumary", component: Consult_annual_summary }, //version1
    // { path: "/consult_sumary_weektly", component: ConsultSummaryWeektly }, //version1
    // { path: "/Consult_report_sale_real", component: SalesReal }, //version1

    //Bank
    { path: "/cashclosing_v2", component: CONSULT_CASHCLOSING_V2 },
    { path: "/cashclosing_sale_v2", component: CONSULT_CASHCLOSING_SALE_V2 },
    { path: "/cashclosing_sale_report_v2", component: CONSULT_CASHCLOSING_SALE_REPORT_V2},
    { path: "/DEBTS", component: DEBTS },
    { path: "/PAID", component: PAID },
    { path: "/old_balances", component: OLD_BALANCES_REPORT },
    { path: "/pay_condition", component: PAY_CONDITION },
    // { path: "/cashclosing", component: SetBanc }, // version 1
    // { path: "/Consult_cut_box_sale", component: Consult_cut_box_sale }, // version 1
    // { path: "/cut_box_report", component: Consult_cut_box_sale_report }, // version 1

    //Configure

    { path: "/consult_route_v2", component: ROUTES_V2 },
    { path: "/consult_warehouse_v2", component: WAREHOUSES_V2 },
    { path: "/consult_roles", component: TableRoles },
    { path: "/edit_company", component: EditCompany },
    { path: "/consult_users", component: TableUsers },
    { path: "/route_management", component: MANAGEMENT_ROUTE },
    { path: "/profile", component: PROFILE }, // Unica version

    // { path: "/TableWarehouse", component: WAREHOUSES }, //version 1
    // { path: "/Consult_route", component: ROUTES }, // version 1

    //Human Resources
    { path: "/consult_employees_v2", component: EMPLOYEES_V2 },
    { path: "/commissions_v2", component: COMMISSIONS_V2 },
    { path: "/daily_staff_v2", component: DAILY_STAFF_V2 },
    { path: "/job_opportunities", component: JOB_OPPORTUNITIES },
    { path: "/applicants", component: APPLICANTS },
    { path: "/contests", component: COMPETITION },
    // { path: "/consult_driver_v2", component: DRIVERS_v2 }, // Eliminar modulo descontinuado
    // { path: "/consult_driver", component: Consult_drivers }, // Eliminar modulo descontinuado
    // { path: "/consult_employees", component: ConsultEmployees }, // version 1
    // { path: "/daily_staff", component: DAILY_STAFF }, // version 1
    // { path: "/commissions", component: COMMISSIONS }, // version 1

    //Assets
    { path: "/consult_brands_v2", component: BRANDS_V2 },
    { path: "/consult_vehicle_v2", component: VEHICLES_V2 },
    { path: "/consult_frezzers_v2", component: Freezer_v2 },
    { path: "/consult_warehouse_movement_cabinets_v2", component: MovementWarehouseCabinets_v2,},
    { path: "/SAMSARA_ENERGY", component: ASSETS_SAMSARA }, //Unica version, solo que esta oculta para los usuarios
    // { path: "/consult_brands", component: BRANDS }, // version 1
    // { path: "/consult_vehicle", component: Consult_vehicle }, // version 1
    // { path: "/consult_frezzers", component: TableFreezer }, //version 1
    // { path: "/consult_warehouse_movement_cabinets", component: MovementWarehouseCabinets,}, // version 1

    //Suports
    { path: "/help_center_v2", component: ViewCases_v2 },
    { path: "/consult_capacitation", component: Capacitacion },
    { path: "/USER_MANUAL", component: USER_MANUAL },
    { path: "/patch_notes", component: PATCH_NOTES_HOME },
    { path: "/patch_notes/description_notes", component: VERSION_DESCRIPTION },

    // { path: "/help_center", component: ViewCases }, // version 1
    // { path: "/chat_smit", component: CHAT }, //version 1

    //Inventory
    { path: "/price_list_products_v2", component: CONSULT_DETAIL_PRICE_LIST_V2 },
    { path: "/update_price_list_v2", component: UPDATE_PRICE_LIST_V2 },
    { path: "/add_product_v2", component: ADD_PRODUCT_V2 },
    { path: "/products_v2", component: PRODUCTS_V2 },
    { path: "/PRICE_LIST_REPORT", component: PRICE_LIST_REPORT }, // Unica version
    { path: "/stock_v2", component: STOCK_V2 },
    { path: "/product_load_v2", component: PRODUCT_LOAD_V2 },
    { path: "/PRODUCT_LOAD_NEW_METHOD", component: PRODUCT_LOAD_NEW_METHOD },

    { path: "/products_line_v2", component: PRODUCTS_LINE_V2 },
    { path: "/price_list_v2", component: PRICE_LIST_v2 },
    { path: "/movements_v2", component: MOVEMENTS_v2 },
    { path: "/werehouse_movements_v2", component: WAREHOUSE_MOVEMENTS_V2 },
    // { path: "/warehouse_movements_list", component: WAREHOUSE_MOVEMENTS_LIST }, // viersion 1
    // { path: "/products_line", component: PRODUCTS_LINE }, // version 1
    // { path: "/products", component: PRODUCTS }, // version 1
    // { path: "/movements", component: MOVEMENTS }, // version 1
    // { path: "/werehouse_movements", component: WAREHOUSE_MOVEMENTS }, // version 1
    // { path: "/stock", component: STOCK }, // version 1
    // { path: "/price_list", component: PRICE_LIST }, // version 1
    // { path: "/price_list_products", component: CONSULT_DETAIL_PRICE_LIST }, // version 1
    // { path: "/update_price_list", component: UPDATE_PRICE_LIST }, // version 1
    // { path: "/add_product", component: ADD_PRODUCT }, // version 1
    // { path: "/product_load", component: PRODUCT_LOAD }, // version 1

    { path: "/", component: DASHBOARD }, // version unica

    //Respaldo en la nube
    { path: "/drive_to_drive", component: Autorization },
    { path: "/datasync", component: DATASYNC },

    //Finance

    { path: "/consult_accounting_account_v2", component: ACCOUNTING_ACCOUNT_V2 },
    { path: "/payment_accounts", component: PAYMENT_ACCOUNTS },
//     { path: "/electronic_acconting", component: ELECTRONIC_ACCOUNTING }, // Eliminar modulo descontinuado
//     { path: "/report_er", component: REPORT_ROUTE_8 }, //Eliminar
//     { path: "/report_er_v2", component: REPORT_ROUTE_FULL }, //Eliminar
];

export default routes;