import { useEffect, useState} from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {useDialog} from "react-st-modal";
import estados from "../../dataComponets/state.json";
import { refresh_token  } from "../../main_components/tokens/tokenrefresh";
import Dir from "../../imgComponents/direccion.png"
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import PersonIcon from '@mui/icons-material/Person';
import RoomIcon from '@mui/icons-material/Room';
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import { catchErrorModul } from "../../main_components/catchErr/catchError";
import { get_businesspartners_list, get_clasification, get_pointsofsale_address, get_requirements, get_route_list, put_points_of_sale } from "../../services/businesspartners/businesspartners";

const Generate_Debt_Post = [
  { value: true, label: "Si(Cliente Directo)" },
  { value: false, label: "No (Cliente Unilever)" },
];

function EditPointsOfSale(props) {
var permisosUsuario = sessionStorage.getItem("roles_user");
var permisoUsuarioReplace = permisosUsuario?.replace(/ /g, "");
var permisos = permisoUsuarioReplace?.split(",");
var permisoSocioNegocios = permisos?.filter((x) => x === "ROLE_BP_POS_CHANGE_PARTNER");

const data = props.row.original;

const[routedata, setroutedata] = useState([]);
const[businesdata, setbusinesdata] = useState([]);
const [clasificaciones, setclasificaciones]=useState([]);
const[datareq, setdatareq] = useState([]);
const [value, setValue] = useState(0);
const dialog = useDialog();

  const [codigo, setcodigo] = useState(data.code); //obligatorio
  const [nombre, setnombre] = useState(data.name); //obligatorio  //si
  const [codigoU, setcodigou] = useState(data.outer_code); //si
  const [gdeuda, setgdeuda] = useState(data.generates_debt); //si
  const [limcredito, setlimcredito] = useState(data.credit_limit); //si
  const [saldo, setsaldo] = useState(data.balance); //si
  const [nota, setnota] = useState(data.note);  //si
  const [lat, setlat] = useState(data.lat); //obligatorio //si
  const [lng, setlng] = useState(data.lng); //obligatorio //si
  const [numtienda, setnumtienda] = useState(data.number_oxxo_store); //obligatorio //si
  const [folioesp, setfolioesp] = useState(data.id_special_number_folio_fk); //obligatorio //si
  const [canal, setcanal] = useState(data.id_channel_fk); //obligatorio //si
  const [subcanal, setsubcanal] = useState(data.id_subchannel_fk); //obligatorio //si
  const [ruta, setruta] = useState(data.id_route_fk); //obligatorio //si

  const [socioneg, setsocioneg] = useState(data.id_business_partner_fk); //obligatorio 
  const [nombresocioneg, setnombresocioneg]=useState(data.businessPartner)
  const [condpago, setcondpago] = useState(data.id_condition_pay_fk); //obligatorio //si
  const [listprecios, setlistprecios] = useState(data.id_price_list_fk); //obligatorio//si
  const [correo, setcorreo] = useState(data.email); //si
  const [telefono, settelefono] = useState(data.phone); //si
  const [calle, setcalle] = useState(" "); //obligatorio //si
  const [numext, setnumext] = useState(" "); //obligatorio //si
  const [numint, setnumint] = useState(" ");//si
  const [colonia, setcolonia] = useState(" "); //obligatorio //si
  const [cp, setcp] = useState(" "); //obligatorio //si
  const [munic, setmunic] = useState(" "); //obligatorio //si
  const [estado, setestado] = useState(" "); //obligatorio//si
  const [local, setlocal] = useState(" "); //obligatorio//si
  const [pais, setpais] = useState(" "); //obligatorio//si
  const [refer, setrefer] = useState(" "); //si
  const [tipdir, settipdir] = useState("ENTREGA");//si 
  const [sucursal, setsucursal] = useState(null);//si
  const [visit, setvisit] = useState(data.visit_days); //obligatorio //si
  const [frecvisit, setfrecvisit] = useState(data.frequency); //obligatorio //si
  const [clasificacion, setclasificacion] = useState(data.classification); //si
  //para eliminar o actualizar la relacion con el punto de venta con el sector dirigirse al modulo de sectores

  const [bandera, setbandera]=useState(true);
  const [vcorreo, setvcorreo]=useState(false);
  const [vtelefono, setvtelefono]=useState(false);
  const [vunilever, setvunilever] = useState(false);


const tsucursal = [
  { id:1, tipo:"MATRIZ"},
  { id:2, tipo:"SUCURSAL"}
]
const diasvisita = [
 {id:1,value:"LUNES"},
 {id:2,value:"MARTES"},
 {id:3,value:"MIERCOLES"},
 {id:4,value:"JUEVES"},
 {id:5,value:"VIERNES"},
 {id:6,value:"SABADO"},
 {id:7,value:"DOMINGO"}
]
const frecuenciavisita = [
  {id:1, value:"SEMANAL"},
  {id:2, value:"QUICENAL(1-3)"},
  {id:3, value:"QUICENAL(2-4)"}
]

useEffect(()=>{
    handleSubmit();
    handledirecc();
    handleroute();
    handlebusines();
    handleclasificacion();    
},[]);

function Spinner() {
  return (
    <div className="spinner-border spinner-border-sm text-light" role="status">
      <span className="sr-only">Cargando...</span>
    </div>
  );
}

//obtener direccion mediante latitud y longitud
const handleClick = async()=>{

  if(bandera===true){
    if(lat===null||lng===null){
      alert("Error en campos de latitud y longitud");
    }else{
      const response=await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyBTswKLvFOwWbnlFqwQMqpUgUEL3LQu7ls`);
      const data = await response.json();
      //console.log(data)
      llenado(data?.results[0])
    }
  }else{
    //console.log("no entra")
  }
}
function llenado (m){

  if(m.address_components.length===7){

  setnumext((m.address_components[0].long_name).toUpperCase());
  setcalle((m.address_components[1].long_name).toUpperCase());
  setlocal((m.address_components[2].long_name).toUpperCase());
  setmunic((m.address_components[3].long_name).toUpperCase());
  setcp((m.address_components[6].long_name).toUpperCase());
  setestado((m.address_components[4].long_name).toUpperCase());
  }
  if(m.address_components.length===4){
    setmunic((m.address_components[1].long_name).toUpperCase());
    setestado((m.address_components[2].long_name).toUpperCase());
  }
}
const handleSubmit = async () => {
  //console.log("entra a consultar los requerimentos");
  try {
    var rf_token = await refresh_token();
    if(rf_token===true){
      setTimeout(async() => {
        try{
          var d = await get_requirements();
          setdatareq(d === null ? [] : d.data);
        }catch(err){
          console.log(err);
        }
      }, 1000);
    }else{}
  } catch (error) {
    console.log(error);
  }
};
const handleroute = async () => {
  try {
    var rf_token = await refresh_token();
    if(rf_token===true){
      setTimeout(async() => {
        try{
          var d = await get_route_list();
          setroutedata(d === null ? [] : d);
        }catch(err){
          console.log(err);
        }
      }, 1000);
    }else{} 
  } catch (error) {
    console.log(error);
  }
}
const handlebusines = async () => {
  try {
    var rf_token = await refresh_token();
    if(rf_token===true){
      setTimeout(async() => {
        try{
          var d = await get_businesspartners_list();
          const fil = d?.data?.businessPartnerList?.filter(item=>item.business_partner_type === "CLIENTE" || item.business_partner_type === "AMBOS");
          setbusinesdata(fil === null ? [] : fil); 
        }catch(err){
          console.log(err);
        }
      }, 1000);
    }else{}
  } catch (error) {
    console.log(error);
  }
}
//consulta de la direccion de un punto de un punto de venta
const handledirecc = async () => {
    //console.log("entra a consultar la direccion de un punto de venta ");
      try {
        props.setmodalT(true);
        var rf_token = await refresh_token();
        if(rf_token===true){
          setTimeout(async() => {
            try{
              props?.setmodalT(false);
              var d = await get_pointsofsale_address(data.id); 
              setcalle(d === null ? [] : d.data.address.street);
              setnumext(d === null ? [] : d.data.address.outdoor_number);
              setnumint(d === null ? [] : d.data.address.inside_number);
              setcolonia(d === null ? [] : d.data.address.neighborhood);
              setcp(d === null ? [] : d.data.address.zip_code);
              setmunic(d === null ? [] : d.data.address.city);
              setestado(d === null ? [] : d.data.address.state);
              setlocal(d === null ? [] : d.data.address.town);
              setpais(d === null ? [] : d.data.address.country);
              setrefer(d === null ? [] : d.data.address.reference);
              settipdir(d === null ? [] : d.data.address.address_type);
              setsucursal(d === null ? [] : d.data.address.subsidiary);
            }catch(err){
              console.log(err);
              props.alertas("Error al obtener información de dirección del punto de venta", false);
            }
          }, 1000);    
        }else{}  
      } catch (error) {
        console.log(error);
        props?.setmodalT(false);
      }
};
const handleclasificacion = async () => {
  try {
    var rf_token = await refresh_token();
    if(rf_token===true){
      setTimeout(async() => {
        try{
          var d = await get_clasification();
          setclasificaciones(d === null ? [] : d.data);         
        }catch(err){
          console.log(err);
        }
      }, 1000);
    }else{}
  } catch (error) {
    console.log(error);
  }
}

const updatepointsale = async () => {

  
  const filt = businesdata?.filter(item=>item.id === socioneg);
  if(filt.length === 0){
  }else{
    if(filt[0]?.partner_type === "EXTERNO" || filt[0]?.partner_type === "UNILEVER"){
      if(codigoU?.trim() === "" || codigoU === null){
        setvunilever(true);
        props?.alertas("Al ser un cliente Externo o Unilever debe llenar el campo de 'Código Unilever'", false);
        return null;
      }
    }
  }


  if(calle===" " || colonia===" " || cp===" " || munic === " " || estado === " " || pais === " " || local === " "){
    props?.alertas("Campos faltantes en dirección", false);
    return null;
  }


if(correo==null){
  props.alertas("Ingrese un correo electrónico válido", false);
  setvcorreo(true);
}else{
  if(telefono==null || telefono.length<10){
  props.alertas("Ingrese un número de teléfono válido", false);
  setvtelefono(true);
  }else{
  props.setmodalT(true);
  setbandera(false);
  
  var data = {  
    "name": nombre,
    "outerCode": codigoU,
    "generatesDebt": gdeuda,
    "creditLimit": limcredito, 
    "balance": saldo,
    "note": nota,
    "latitude": parseFloat(lat), 
    "longitude": parseFloat(lng),  
    "numberOxxo": numtienda,  
    "idSpecialNumberFolio": parseInt(folioesp),  
    "idChannel": parseInt(canal), 
    "idSubchannel": parseInt(subcanal), 
    "idRoute": parseInt(ruta),  
    "idConditionPay": parseInt(condpago),
    "idPriceList": parseInt(listprecios),
    //editar socio
    "id_partner": parseInt(socioneg),
    "email": correo,
    "phone": telefono,
    "frequency": frecvisit,
    "visitDays": visit,
    "classification": clasificacion,
    "calle": calle,
    "numExt": numext,
    "numInt": numint,
    "colonia": colonia,
    "codigoPostal": cp,
    "municipio": munic,
    "localidad": local,
    "estado": estado,
    "pais": pais,
    "referencia": refer,
    "sucursal": sucursal,
    "tipoEntrega": tipdir,
  }

      try {
        var rf_token = await refresh_token();
        if(rf_token===true){
          setTimeout(async() => {
            try{
              const dt = await put_points_of_sale(props.row.original.id, data);
              console.log(dt);
              props.alertas("Punto de Venta actualizado con éxito", true);
              props.setmodalT(false);
              dialog.close(); 
              props.handleSubmit();
            }catch(err){
              console.log(err);
              catchErrorModul("Puntos de Venta", JSON.stringify(data), JSON.stringify(err?.response===undefined?"Token expirado":err?.response));
              setbandera(true);
              props.setmodalT(false);
              props.alertas(err.response.data.errorMessage);

            }    
          }, 1000);
        }else{}
      } catch (err) {  
        console.log(err);
      }
    }
  }
}


function IsMail(event){
 
  const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9-]+)+$/;
  const newValue = (event.target.value).split(",");
  
  var isValid = true;
  for(var i=0;i<newValue.length;i++){
    if(!emailRegex.test(newValue[i]?.trim())){
      isValid=false;
      break;
    }
  }
  if(isValid){
    setcorreo(event.target.value);
    setvcorreo(false);
  }else{
    setvcorreo(true);
  }
}

const [formper, setformper] = useState(true);
const [formdirec, setformdirec] = useState(false);

const Personales = () =>{
  setformper(true);
  setformdirec(false);
}
const Direccion = () => {
  setformper(false);
  setformdirec(true);
}

const handleGenerateD =(event)=>{
  setgdeuda(event.target.value==="true"?true:false);
}

const handleOnSearch = (string, results) => {
  setsocioneg(data.id_business_partner_fk);
  setnombresocioneg(data.businessPartner);
};
const handleOnHover = (result) => {};
const handleOnSelect5 = (item) => {
    setsocioneg(item.id);
    setnombresocioneg(item.id);
};
const handleOnFocus5 = () => {
};
const handleOnClear = () => {
};

return (
    <div style={{"textAlign":"center"}}>
      <div className="formulariopointofsale">
<Box>
  <br/>
  <div className="pestañas">
      
  <div className="navegacioncompany">
      <Box sx={{ margin: "0 auto" ,width: 400}}>
      <BottomNavigation
        showLabels
        value={value}
        onChange={(newValue) => {
          setValue(newValue);
        }}
      >
        <BottomNavigationAction style={formper===false?{"backgroundColor":"#E7E7E7"}:{}} label="Información General" icon={<PersonIcon onClick={Personales} />}  onClick={Personales}/>
        <BottomNavigationAction style={formdirec===false?{"backgroundColor":"#E7E7E7"}:{}} label="Dirección" icon={<RoomIcon onClick={Direccion}/>} onClick={Direccion}/>
        
      </BottomNavigation>
    </Box>
    </div>
    </div>
    <br/>
      </Box> 
      <Box>
      <div 
      style={formper===true?{"display":"block"}:{"display":"none"}}
      >

<div className="grid01edit">
<div className="item1">
  <TextField
      label="Código"
      value={codigo}
      disabled
      sx={{ m: 1.5, width: "100%" }}
  ></TextField>
</div>
<div className="item2">
  <TextField
    label="Nombre"
    value={nombre}
    disabled={!bandera}
    onChange={(event) => setnombre(event.target.value.toUpperCase())}
    InputLabelProps={{ shrink: true }}
    sx={{ m: 1.5, width: "100%" }}    
  ></TextField>
</div>
<div className="item3">
  <TextField
    label="Código Unilever"
    value={codigoU}
    onChange={(event) => setcodigou(event.target.value.toUpperCase())}
    InputLabelProps={{ shrink: true }}
    sx={{ m: 1.5, width: "100%" }}
    style={vunilever === true ? {
        "border": "2px solid red", "borderRadius": "5px"
      } : {}
    }
    onSelect={() => setvunilever(false)}
  ></TextField>   
   <p className="p_alert" style={vunilever === true ? { "display": "block" } : { "display": "none" }}>Completa este campo </p>
               
</div>
</div>
<div className="grid02">
<div className="item4">
  <TextField
    label="Número de tienda"
    disabled={!bandera}
    value={numtienda}
    onChange={(event) => setnumtienda(event.target.value.toUpperCase())}
    InputLabelProps={{ shrink: true }}
    sx={{ m: 1.5, width: "100%" }}
  ></TextField>
</div>
<div className="item5">
  <TextField
    label="Nota"
    value={nota}
    disabled={!bandera}
    onChange={(event) => setnota(event.target.value.toUpperCase())}
    InputLabelProps={{ shrink: true }}
    sx={{ m: 1.5, width: "100%" }}
  ></TextField>
</div>
</div>
<div className="grid02">
<div className="item6">
  <TextField
    label="Correo Electrónico"
    value={correo}
    disabled={!bandera}
    onChange={(event) => setcorreo(event.target.value)}
    style={vcorreo===true?{
      "border":"2px solid red", "border-radius":"5px"}:{}
    }
    onSelect={()=>setvcorreo(false)}
    onBlur={IsMail}
    InputLabelProps={{ shrink: true }}
    sx={{ m: 1.5, width: "100%" }}
  ></TextField>
  <p className="p_alert" style={vcorreo===true?{"display":"block"}:{"display":"none"}}>Ingresa un correo electrónico válido </p> 
</div>
<div className="item7">
  <TextField
    label="Teléfono(s)"
    value={telefono}
    disabled={!bandera}
    type={"number"}
    onChange={(event) => settelefono(event.target.value.slice(0,10))}
    title="Ingrese su número de telefono sin guiones"
    InputLabelProps={{ shrink: true }}
    style={vtelefono===true?{
      "border":"2px solid red", "border-radius":"5px"}:{}
    }
    onBlur={
      telefono==null?()=>setvtelefono(true):(telefono.length<10?()=>setvtelefono(true):()=>setvtelefono(false))  
    }
    onSelect={()=>setvtelefono(false)}
    sx={{ m: 1.5, width: "100%" }}
  ></TextField>
  <p className="p_alert" style={vtelefono===true?{"display":"block"}:{"display":"none"}}>Ingresa un télefono válido </p> 
</div>
</div>
<div className="grid03">
<div className="item8">

  <TextField
                select
                label="Genera Deuda"
                value={gdeuda}
                disabled={!bandera}
                sx={{ m: 1.5, width: "100%" }}
                onChange={handleGenerateD}  
                InputLabelProps={{
                  shrink: true,
                }}
                SelectProps={{
                  native: true,
                }}
              >
                {Generate_Debt_Post.map((option) => (
                  <option key={option.value} value={option.value}>
                   {option.label}
                 </option> 
                ))}
              </TextField>
</div>
<div className="item9">
  <TextField
    label="Límite de Crédito"
    value={limcredito}
    disabled={!bandera}
    onChange={(event) => setlimcredito(event.target.value)}
    InputLabelProps={{ shrink: true }}
    sx={{ m: 1.5, width: "100%" }}
  ></TextField>
</div>
<div className="item10">
  <TextField
    label="Saldo"
    value={saldo}
    disabled={!bandera}
    onChange={(event) => setsaldo(event.target.value)}
    InputLabelProps={{ shrink: true }}
    sx={{ m: 1.5, width: "100%" }}
  ></TextField>
</div>
<div className="item11">
  <TextField
    select
    required
    disabled={!bandera}
    label="Condición de Pago"
    InputLabelProps={{shrink: true,}}
    SelectProps={{native: true,}}
    sx={{ m: 1.5, width: "100%" }}
    onChange={(event) => setcondpago(event.target.value)}  
  >
  {
    datareq?.payConditions?.map((item) => (
      condpago === item.id?
        <option key={item.id} value={item.id} selected>
          {item.description}
        </option>:
        <option key={item.id} value={item.id} >
        {item.description}
      </option>
    ))
    }
  </TextField>
</div>
</div>
<div className="grid03edit">

{
  permisoSocioNegocios.length===1?
<div className="item12">
<div className="inputbusinessearch">    
        <ReactSearchAutocomplete
                      items={ businesdata}
                      fuseOptions={{ keys: ["code","business_partner"]}}
                      resultStringKeyName="business_partner"
                      formatResult={(item) => (
                        <span>{item.code} - {item.business_partner}</span>
                      )}
                      onSearch={handleOnSearch}
                      onHover={handleOnHover}
                      onSelect={handleOnSelect5}
                      onFocus={handleOnFocus5}
                      onClear={handleOnClear}
                      styling={{
                        height: "40px",
                        border: "1px solid gray",
                        borderRadius: "4px",
                        color: "black",
                        iconColor: "black",
                        lineColor: "black",
                        placeholderColor: "black",
                        zIndex: 10,
                      }}
                      showIcon={true}
                      placeholder={nombresocioneg}      
                    />         
            </div>               
  </div>:
<div className="item12z">
  <TextField
    label="Socio de Negocios"
    disabled
    value={nombresocioneg}
    sx={{ m: 1.5, width: "100%" }}
  ></TextField>
</div> 

}




<div className="item13">
  <TextField
    select
    disabled={!bandera}
    value={visit}
    required
    label="Días de Visita"
    InputLabelProps={{shrink: true,}}
    SelectProps={{native: true,}}
    sx={{ m: 1.5, width: "100%" }}
    onChange={(event) => setvisit(event.target.value)}
    >
    {
    diasvisita.map(item=>(
      <option key={item.id} value={item.value}>
        {item.value}
      </option>
      ))
    }
  </TextField> 
</div>
<div className="item14">
  <TextField
    select
    required
    value={frecvisit}
    disabled={!bandera}
    defaultValue={''}
    label="Frecuencia de Visita"
    InputLabelProps={{shrink: true,}}
    SelectProps={{native: true,}}
    sx={{ m: 1.5, width: "100%" }}
    onChange={(event) => setfrecvisit(event.target.value)}  
  >{
    frecuenciavisita.map(item=>(
       <option key={item.id} value={item.value}>
        {item.value}
      </option>
    ))
  }</TextField>
</div>
</div>
<div className="grid04b">
<div className="item15">
  <TextField
    select
    required
    disabled={!bandera}
    label="Lista de Precios"
    defaultValue={''}
    InputLabelProps={{shrink: true,}}
    SelectProps={{native: true,}}
    sx={{ m: 1.5, width: "100%" }}
    onChange={(event) => setlistprecios(event.target.value)}  
  >
    {
      datareq?.priceLists?.map((item) => (
        listprecios === item.id?
        <option key={item.id} value={item.id} selected >
          {item.name}
        </option>:
        <option key={item.id} value={item.id} >
          {item.name}
        </option>
      ))
    }
  </TextField>
</div>
<div className="item16">
  <TextField
    select
    required
    disabled={!bandera}
    defaultValue={''}
    label="Folios Especiales"
    InputLabelProps={{shrink: true,}}
    SelectProps={{native: true,}}
    sx={{ m: 1.5, width: "100%" }}
    onChange={(event) => setfolioesp(event.target.value)}  
  >
    {
      datareq?.specialFolios?.map((item) => (
        folioesp===item.id?
        <option key={item.id} value={item.id} selected>
          {item.folio_name}
        </option>:
        <option key={item.id} value={item.id}>
        {item.folio_name}
      </option>
      ))
    }
  </TextField>
</div>
<div className="item17">
  <TextField
    select
    required
    disabled={!bandera}
    defaultValue={''}
    label="Canal"
    InputLabelProps={{shrink: true,}}
    SelectProps={{native: true,}}
    sx={{ m: 1.5, width: "100%" }}
    onChange={(event) => setcanal(event.target.value)}  
  >
    {
      datareq?.channels?.map((item) => (
      canal===item.id?
        <option key={item.id} value={item.id} selected>
          {item.description}
        </option>:
        <option key={item.id} value={item.id} >
          {item.description}
        </option>
    ))}
  </TextField>
</div>
<div className="item18">
  <TextField
    select
    required
    disabled={!bandera}
    label="Subcanal"
    InputLabelProps={{shrink: true,}}
    SelectProps={{native: true,}}
    sx={{ m: 1.5, width: "100%" }}
    onChange={(event) => setsubcanal(event.target.value)}  
  >
    {
      datareq?.subchannels?.map((item) => (
        subcanal===item.id?
        <option key={item.id} value={item.id} selected>
          {item.description}
        </option>:
        <option key={item.id} value={item.id} >
        {item.description}
      </option>
      ))
    }
  </TextField>
</div>
</div>
<div className="grid05b">

<div className="item19">
  <TextField
      select
      required
      label="Ruta"
      disabled={!bandera}
      value={ruta}
      InputLabelProps={{shrink: true,}}
      SelectProps={{native: true,}}
      sx={{ m: 1.5, width: "100%" }}
      onChange={(event) => setruta(event.target.value)}
    >
    {
      routedata?.data?.parametersList?.map((item)=>(
      <option key={item.idRoute} value={item.idRoute}>
        {item.route}
      </option>
      ))
    }
  </TextField>
</div>
<div className="item21b">
  <TextField
      select
      disabled={!bandera}
      label="Clasificacion"
      defaultValue={""}
      value={clasificacion}
      InputLabelProps={{shrink: true,}}
      SelectProps={{native: true,}}
      sx={{ m: 1.5, width: "100%" }}
      onChange={(event) => setclasificacion(event.target.value)}  
    >
      <option key={0} value={0} selected >
          {"Seleccione clasificación"}
      </option>
      {
        clasificaciones?.map((item)=>(
          <option key={item.id} value={item.clasification}>
            {item.clasification}
          </option>
        ))
      }
    
    </TextField>
  </div>
</div>                   
  <br></br>
    </div>
    <div
        style={formdirec===true?{"display":"block"}:{"display":"none"}}
      >
<div className="gridd01">
<div className="item22">
  <TextField
    label="Latitud"
    disabled={!bandera}
    value={lat}
    onChange={(event) => setlat(event.target.value)}
    InputLabelProps={{ shrink: true }}
    sx={{ m: 1.5, width: "100%" }}
  ></TextField>
</div>
<div className="item23">
  <TextField
    label="Longitud"
    disabled={!bandera}
    value={lng}
    onChange={(event) => setlng(event.target.value)}
    InputLabelProps={{ shrink: true }}
    sx={{ m: 1.5, width: "100%" }}
  ></TextField>
</div>
<div className="item23bb">
          <img id="crearsector" src={Dir} onClick={handleClick} title="Buscar Dirección" alt=""
    ></img>
          </div>
<div className="item24">
  <TextField
    label="Calle"
    disabled={!bandera}
    value={calle}
    sx={{ m: 1.5, width: "100%" }}
    onChange={(event) => setcalle(event.target.value.toUpperCase())}  
  ></TextField>
</div>
</div>
<div className="gridd02">
<div className="item25">
  <TextField
    label="Número Exterior"
    value={numext}
    disabled={!bandera}
    onChange={(event) => setnumext(event.target.value)}  
    sx={{ m: 1.5, width: "100%" }}
  ></TextField>
</div>
<div className="item26">
  <TextField
    label="Número Interior"
    disabled={!bandera}
    value={numint==null?" ":numint}
    onChange={(event) => setnumint(event.target.value)}  
    sx={{ m: 1.5, width: "100%" }}
  ></TextField>
</div>
<div className="item27">
  <TextField
    label="Colonia"
    value={colonia}
    disabled={!bandera}
    onChange={(event) => setcolonia(event.target.value.toUpperCase())}  
    sx={{ m: 1.5, width: "100%" }}
  ></TextField>
</div>
<div className="item28">
  <TextField
    label="Localidad"
    value={local}
    disabled={!bandera}
    onChange={(event) => setlocal(event.target.value.toUpperCase())}  
    sx={{ m: 1.5, width: "100%" }}
  ></TextField>
</div>
</div>
<div className="gridd03">
<div className="item29">
  <TextField
    label="Municipio"
    value={munic}
    disabled={!bandera}
    onChange={(event) => setmunic(event.target.value.toUpperCase())}  
    sx={{ m: 1.5, width: "100%" }}
  ></TextField>
</div>
<div className="item30">
  <TextField
    label="Código Postal"
    value={cp}
    disabled={!bandera}
    onChange={(event) => setcp(event.target.value)}  
    sx={{ m: 1.5, width: "100%" }}
  ></TextField>
</div>
<div className="item31">
<TextField
    select
    required
    disabled={!bandera}
    label="Estado"
    InputLabelProps={{shrink: true,}}
    SelectProps={{native: true,}}
    sx={{ m: 1.5, width: "100%" }}
    onChange={(event) => setestado(event.target.value)}  
  >
  {
    estados.map(item =>(
      estado===item.label?
        <option key={item.clave} value={item.clave} selected>
            {item.label}
        </option>:
        <option key={item.clave} value={item.clave}>
          {item.label}
        </option>
      ))
  }
    </TextField>
</div>
<div className="item32">
  <TextField
    label="País"
    disabled={!bandera}
    value={pais}
    onChange={(event) => setpais(event.target.value)}  
    sx={{ m: 1.5, width: "100%" }}
  ></TextField>
</div>
</div>  
<div className="gridd04">
<div className="item33">
  <TextField
    label="Referencia"
    value={refer}
    disabled={!bandera}
    onChange={(event) => setrefer(event.target.value.toUpperCase())}  
    sx={{ m: 1.5, width: "100%" }}
  ></TextField>
</div>  
<div className="item34">
<TextField
    select
    label="Sucursal"
    disabled={!bandera}
    defaultValue={""}
    InputLabelProps={{shrink: true,}}
    SelectProps={{native: true,}}
    sx={{ m: 1.5, width: "100%" }}
    onChange={(event) => setsucursal(event.target.value)}  
  >
  {
    tsucursal.map(item=>(
    sucursal===item.tipo?
    <option key={item.id} value={item.tipo} selected >
      {item.tipo}
    </option>:
    <option key={item.id} value={item.tipo}>
    {item.tipo}
  </option>
    ))
  }
  </TextField> 
</div>
</div>                                
      </div>
          </Box>
          <br></br>
          <br></br>
          <Box>
            {
              bandera?
          <Button
          variant="contained"
          style={{"backgroundColor":"green"}}
          sx={{ m: 1.5, width: "30ch" }}
          onClick={updatepointsale}
        >
          Guardar cambios
        </Button>:
        <Button
        disabled
        variant="contained"
        style={{"backgroundColor":"green", "height":"35px"}}
        sx={{ m: 1.5, width: "30ch" }}
        ><Spinner/></Button>
      }
            <Button
              style={{"backgroundColor":"red"}}
              variant="contained"
              disabled={!bandera}
              sx={{ m: 1.5, width: "20ch" }}
              onClick={() => {
                  dialog.close();
                } 
              }
            >
            cerrar
          </Button>
          </Box>
      </div>
    </div>
  );
}
export default EditPointsOfSale;