import dayjs from 'dayjs';
import TAX_REGIME from "../../../../dataComponets/regimen copy.json"
import CFDI_USE from "../../../../dataComponets/usoCFDI copy.json"
import { inf_partner } from './inf_partner';



const update_field_attributes = (
  accessor: string,
  attributes: {
    change?: boolean,
    required?: boolean,
    data_select?: any[],
    visible?: boolean
  }
) => {
  const field = inf_partner.find(item => item.accessor === accessor);
  if (field) {
    if (attributes.change !== undefined) {
      field.change = attributes.change;
    }
    if (attributes.required !== undefined) {
      field.required = attributes.required;
    }
    if (attributes.data_select !== undefined) {
      field.data_select = attributes.data_select;
    }
    if (attributes.visible !== undefined) {
      field.visible = attributes.visible;
    }
  } else {
    console.log(`No se encontró el campo con el accessor: ${accessor}`);
  }
  return inf_partner
};


export const updated_form_data = (key: any, value: any, formData: any) => {
  const update_form = {
    ...formData,
    [key]: value,
    person_type: key === 'rfc'
      ? value.length === 13 ? "FÍSICA" : "MORAL"
      : formData.person_type,
  };
  return update_form;
};

export const validate_data = (name: string, value: any, formData: any) => {
  var error = '';
  var regexRFC = new RegExp('^[A-ZÑ&]{3,4}[0-9]{2}(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[A-Z\\d]{3}$');
  var regexEmail = /^\s*([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})(\s*,\s*[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})*\s*$/;
  var regexPostalCode = /^\d{5}$/;
  var isValid = value !== undefined && value !== null && value !== "" && value !== "N/A";
  var infPartner;


  switch (name) {
    case 'code':
      if (!value) {
        error = 'El campo es "Código", es obligatorio';
      }
      break;

    case 'force_code':
      if (value) {
        infPartner = update_field_attributes("code", { change: true, required: true });
      } else {
        infPartner = update_field_attributes("code", { change: false, required: true });
      }
      break;

    case 'business_partner':
      if (!value?.trim()) {
        error = 'El campo es "Socio de Negocios", es obligatorio';
      }
      break;

    case 'rfc':
      if (value?.trim().length === 12 || value?.trim().length === 13) {
        if (!regexRFC.test(value?.trim())) {
          error = "El RFC proporcionado no está bien estructurado.";
        } else {
          if (value.length === 13) {
            infPartner = update_field_attributes("name", { change: true, required: true, visible: true });
            infPartner = update_field_attributes("first_last_name", { change: true, required: true, visible: true });
            infPartner = update_field_attributes("second_last_name", { change: true, required: false, visible: true });

          } else {
            infPartner = update_field_attributes("name", { change: true, required: true, visible: false });
            infPartner = update_field_attributes("first_last_name", { change: true, required: true, visible: false });
            infPartner = update_field_attributes("second_last_name", { change: true, required: false, visible: false });

          }
        }

        if (value?.trim() === "XEXX010101000") {
          infPartner = update_field_attributes("tax_residence", { change: true, required: true });
          infPartner = update_field_attributes("num_reg_id_trib", { change: true, required: true });
        } else {
          infPartner = update_field_attributes("tax_residence", { change: false, required: false });
          infPartner = update_field_attributes("num_reg_id_trib", { change: false, required: false });
        }
      } else {
        error = "El RFC debe tener 12 o 13 caracteres.";
      }
      break;


    case 'name':
      if (formData.rfc?.length === 13 && !value?.trim()) {
        error = 'El campo "Nombre" es obligatorio cuando el RFC tiene 13 caracteres.';
      }
      break;

    case 'first_last_name':
      if (formData.rfc?.length === 13 && !value?.trim()) {
        error = 'El campo "Apellido Paterno" es obligatorio cuando el RFC tiene 13 caracteres.';
      }
      break;
    case 'tax_regime':
      const exists_tax_regime = TAX_REGIME.some(regime => regime.value === value);

      if (!exists_tax_regime || value === "N/A") {
        if (isValid) {
          error = `El valor "${value}" contenido en el campo "Régimen Fiscal" no es válido. Favor de proporcionar uno del selector`;
        } else {
          error = `El valor contenido en el campo "Régimen Fiscal" se encuentra vacío. Favor de proporcionar uno del selector.`;
        }
      }
      break;

    case 'cfdi_use':
      const exists_cfdi = CFDI_USE.some(regime => regime.value === value);

      if (!exists_cfdi || value === "N/A") {
        if (isValid) {
          error = `El valor "${value}" contenido en el campo "Uso de CFDI" no es válido. Favor de proporcionar uno del selector`;
        } else {
          error = `El valor contenido en el campo "Uso de CFDI" se encuentra vacío. Favor de proporcionar uno del selector.`;
        }
      }
      break;

    case 'email':
      if (!regexEmail.test(value?.trim())) {
        error = "Uno o más correos electrónicos proporcionados no tienen un formato válido.";
      }
      break;

    case 'zip_code':
      console.log(value)
      if (formData.rfc === "XEXX010101000") {
        if (!value?.trim()) {
          error = 'El campo es "Código Postal", es obligatorio';
        }
      } else {
        if (value?.trim()?.length !== 5) {
          error = "El código postal proporcionado no es válido.";
        }
      }
      break;
  }


  return { error: error, objetUpdate: infPartner === undefined ? inf_partner : infPartner };
};


export const processed_format = (formData: any) => {
  const dataToSubmit = { ...formData };
  delete dataToSubmit.pointofsale;
  delete dataToSubmit.system_commentary;
  delete dataToSubmit.date_register;



  Object.keys(dataToSubmit).forEach((key) => {
    switch (key) {
      case 'date_paid':
        // Formatear la fecha a "YYYY-MM-DD"
        // dataToSubmit[key] = dayjs(dataToSubmit[key]).format('YYYY-MM-DD');
        break;

      case 'person_type':
        // Convertir la descripción a mayúsculas
        // dataToSubmit[key] = dataToSubmit[key].toUpperCase();
        break;

      // Agrega más casos según las necesidades de tu formulario
      default:
        // No hacer nada si no es un campo que necesita procesamiento especial
        break;
    }
  });

  console.log(dataToSubmit)

  return dataToSubmit;
};



export const create_object = {
  code: "",
  business_partner: null,
  rfc: null,
  paymethod: "01",
  allow_invoicing: false,
  name: null,
  force_code: false,
  first_last_name: null,
  second_last_name: null,
  capital_regime: null,
  cfdi_use: "G03",
  tax_residence: null,
  num_reg_id_trib: null,
  metodo_pago: "PUE",
  tax_regime: "616",
  email: null,
  enable: true,
  person_type: null,
  zip_code: null,
  street: null,
  city: null,
  outdoor_number: null,
  inside_number: null,
  state: null,
  reference: null,
  outer_code: null,
  account_number: null,
  id_accounting_account_fk: null,
  name_accounting_account: null,
  code_accounting_account: null,
  address_type: "FISCAL",
  country: "MÉXICO",
  town: null,
  subsidiary: "MATRIZ",
  neighborhood: null,
  partner_type: "PROPIO",
  credit_days: null,
  business_partner_type: "CLIENTE",
}